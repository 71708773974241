// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fill-content {
  margin: auto;
  width: 100%;
  height: 100%;
  min-width: 10rem;
}

.mat-select-width {
  width: 100%;
}

.checkbox-max-width {
  max-width: 3em;
}

.modal-title {
  color: #171C8F;
  font-size: 30px;
  font-family: "Trade Gothic Bold";
  text-align: center;
}

th {
  background-color: #FFF;
}

.fixTableHead thead th {
  position: sticky;
  top: 0;
}

.no-overflow {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.error-text {
  color: red;
}

.valid-text {
  color: green;
}`, "",{"version":3,"sources":["webpack://./src/app/core/hht/split-itinerary/split-itinerary.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,cAAA;EACA,eAAA;EACA,gCAAA;EACA,kBAAA;AACJ;;AAOA;EACI,sBAAA;AAJJ;;AAOA;EACI,gBAAA;EACA,MAAA;AAJJ;;AAOA;EACI,6BAAA;EACA,6BAAA;AAJJ;;AAOA;EACI,UAAA;AAJJ;;AAOA;EACI,YAAA;AAJJ","sourcesContent":[".fill-content {\r\n    margin: auto;\r\n    width: 100%;\r\n    height: 100%;\r\n    min-width: 10rem;\r\n}\r\n\r\n.mat-select-width {\r\n    width: 100%\r\n}\r\n\r\n.checkbox-max-width {\r\n    max-width: 3em;\r\n}\r\n\r\n.modal-title{\r\n    color: #171C8F;\r\n    font-size: 30px;\r\n    font-family: 'Trade Gothic Bold';\r\n    text-align: center;\r\n}\r\n\r\n// .fixTableHead {\r\n//     overflow-y: auto;\r\n//     height: 110px;\r\n// }\r\n\r\nth {\r\n    background-color: #FFF;\r\n}\r\n\r\n.fixTableHead thead th {\r\n    position: sticky;\r\n    top: 0;\r\n}\r\n\r\n.no-overflow {\r\n    overflow-x: hidden !important;\r\n    overflow-y: hidden !important;\r\n}\r\n\r\n.error-text {\r\n    color: red;\r\n}\r\n\r\n.valid-text {\r\n    color: green;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
