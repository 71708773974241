import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { AuthService } from '@shared/services/guards/auth.service';
import { Auth, Amplify } from 'aws-amplify';
import { environment } from '@environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

Amplify.configure({
  Auth_: {
    //identityPoolId: 'ap-southeast-1_OxfWFxzuX',
    region : 'ap-southeast-1',
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    //dev
    // userPoolId: 'ap-southeast-1_5eh4VbCF6',
    // userPoolWebClientId: 'hhtwebcab1a099_app_clientWeb',

    //uat
    userPoolId: 'ap-southeast-1_qJ2oRxPIX',
    userPoolWebClientId: 'hhtweb8c7ea94a_app_clientWeb',

    //sit:
    // userPoolId: 'ap-southeast-1_45J0OjYAC',
    // userPoolWebClientId: 'hhtwebb45beeda_app_clientWeb',

    //sqt
    // userPoolId: 'ap-southeast-1_BNGBVaJMK',
    // userPoolWebClientId: 'hhtwebb45beeda_app_clientWeb',

    //prod
    //userPoolId: '7rgikb252bpacfa0nrplrjcrve',
    //userPoolWebClientId: 'hhtwebf47570b1_app_clientWeb',

  }
});


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  cognitoUserName:string;
  cognitoEmail:string;
  cognitoDetails: any = [];
  isLoading: boolean = false;
  ChangePass: boolean = false;
  hideUserMaintenance: boolean = false;
  activeNav: string = 'ChangePass';

  max14Chars: boolean = false;
  hasUpperCase: boolean = false;
  hasLowerCase: boolean = false;
  hasSpecChar: boolean = false;
  hasNumChar: boolean = false;
  isNonRepeating: boolean = false;
  isNotEmail: boolean = false;
  passMatch: boolean = false;

  maskPassword0: boolean = true;
  maskPassword1: boolean = true;
  maskPassword2: boolean = true;

  upCaseRegex: RegExp = new RegExp('[A-Z]');
  lowCaseRegex: RegExp = new RegExp('[a-z]');
  numberRegex: RegExp = new RegExp('[\\d]');
  symbolsRegex: RegExp = new RegExp('[-+_!@#$%^&*.,?]');
  isNonRepeatRegex: RegExp = new RegExp('(.)\\1{2,}');

  email: string = '';
  Password1: string = '';
  Password2: string = ''; 
  ConfirmationCode: string = '';
  Password0: string = '';
  AccessToken: string = '';
  accountType: string = 'METER_READER';

  constructor(private activatedRoute: ActivatedRoute, 
              private hhtApiService: HhtApiService,
              private authService: AuthService,
              private modal: NgbModal,
              private toastr: ToastrService)  { }

  ngOnInit(): void {
    Auth.currentUserInfo().then(user =>{ //call assignCurrentUser to establish access restrictions.
      this.authService.assignCurrentUser(String(user['username']).replace(environment.adPrefix, '')).then(() =>{
        this.email = this.authService.getCurrentUser().email;
        this.accountType = this.authService.getCurrentUser().accountType;
      });
    })
    this.toHideChangePassword().then((flag: boolean) =>{
      if(!!flag){
        this.activeNav = 'MyAccount';
      }
      this.ChangePass = !flag;
    })
   
  }

  changePassword(){
    Auth.currentAuthenticatedUser()
      .then(user => {
          //return Auth_.changePassword(user, this.Password0, this.Password1);
          Auth.changePassword(user, this.Password0, this.Password1).then(/* istanbul ignore next: nested promise */users => {
            this.toastr.success('User password has been successfully saved.', 
                                'Updated Password', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
            console.log('password changed');                                
            this.isLoading = false;
            this.ChangePass = true;
            this.Password0 = '';
            this.Password1 = '';
            this.Password2 = '';
            this.resetPasswordBool();
            this.modal.dismissAll();
            this.authService.assignCurrentUser(String(user['username']).replace(environment.adPrefix, ''));
          })
      })
  }

  async toHideChangePassword(): Promise<boolean> {
   let currentUser = await Auth.currentUserInfo();
   return !!String(currentUser['username']).includes(environment.adPrefix);
  }

  onChangePasswordValidation(){
    this.isNonRepeating = !this.isNonRepeatRegex.test(this.Password1) && !!this.Password1;
    this.max14Chars = this.accountType == 'METER_READER' ? this.Password1.length >= 9 : /* istanbul ignore next */this.Password1.length >= 14;
    this.hasUpperCase = this.upCaseRegex.test(this.Password1);
    this.hasLowerCase = this.lowCaseRegex.test(this.Password1);
    this.hasNumChar = this.numberRegex.test(this.Password1);
    this.hasSpecChar = this.symbolsRegex.test(this.Password1);
    this.isNotEmail = this.Password1 != this.email && !!this.Password1; 
    this.passMatch = this.Password1 === this.Password2 && !!this.Password1;
  }

  cancelChangePass(){
    this.Password0 = '';
    this.Password1 = '';
    this.Password2 = '';
    this.resetPasswordBool();
    this.modal.dismissAll();
    this.toastr.success('Your password was not updated.', 
      'Unsaved Changes', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
  }

  confirmChangePass(modalContent){
    this.modal.dismissAll();
    this.modal.open(modalContent, {backdrop: 'static'});
  }

  updatePassword(){
    const body = {
      password: this.Password1,
      userName: this.email,
      email: this.email
    }
    const deleteSessionBody = {
      username: this.email
    }
    this.isLoading = true;
    this.hhtApiService.deleteSession(deleteSessionBody).subscribe(result =>{
      Auth.signIn(this.email,this.Password0).then(user =>{ //validated old password for signing in before making any password changes
        const bodyOldPassword = {
          password: this.Password0,
          userName: this.email,
          email: this.email
        }
        this.hhtApiService.validateInsertPassword(bodyOldPassword).subscribe(/* istanbul ignore next: nested observable */result =>{
          this.hhtApiService.validateInsertPassword(body).subscribe(result =>{
            if (result['body'].data[0]['ErrCode'] == 1) {
              this.toastr.error(result['body'].data[0]['ErrMsg'], 
                                'Error Updating Password', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
              this.modal.dismissAll();
              this.isLoading = false;
            } else {
                //if success in updating DB, execute AWS Cognito level change password.
                this.changePassword()
            }
          })
        })   
      })
      .catch(err => {
        console.log(err);
        console.log('err-message',err['message']);
        this.toastr.error('Incorrect Previous Password', 
                          'Error Updating Password', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
        this.isLoading = false;
        this.ChangePass = true;
        this.modal.dismissAll();
      });
    })
   
  }

  resetPasswordBool(){
    this.max14Chars = false;
    this.hasUpperCase = false;
    this.hasLowerCase = false;
    this.hasNumChar = false;
    this.hasSpecChar = false;
    this.isNotEmail = false 
    this.passMatch = false;
    this.isNonRepeating = false;
  }
}