import { Component, OnInit} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/shared/services/guards/auth.service';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { Auth, Amplify } from 'aws-amplify';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InformationComponent } from '@shared/modals/information/information.component';

import { request } from 'http';
import { environment } from '@environments/environment';
import { ToastrService } from 'ngx-toastr';

Amplify.configure({
  Auth_: {
    //identityPoolId: 'ap-southeast-1_OxfWFxzuX',
    region : 'ap-southeast-1',
    // dev -'6gbvhjbkn58j5scluj8p8999jk'
    // uat -'6u1nno2r4c049uq3mmalimnv7f'
    // prod - '7rgikb252bpacfa0nrplrjcrve'
    userPoolId: '6u1nno2r4c049uq3mmalimnv7f',
    
    userPoolWebClientId: 'hhtwebf47570b1_app_clientWeb',
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  }
});
@Component({
  selector: 'app-split-itinerary',
  templateUrl: './split-itinerary.component.html',
  styleUrls: ['./split-itinerary.component.scss']
})
export class SplitItineraryComponent implements OnInit {

  meters: Array<any> = [];
  metersDraft: Array<any> = [];

  subItineraryGroup: Array<any> = [];
  splitItineraryDetails: any = {
    id: 0,
    noOfSubItn: 0,
    itineraryCode: ''
  }

  isLoading: boolean = false;
  
  modalRef: NgbModalRef;
  unassigned: number = 0;
  assigned: number = 0;
  totalMetersPerCT: number = 0;

  assignToBulk: string = '';

  subItnFg: FormGroup;

  constructor(private router: Router, 
    private route: ActivatedRoute,
    private hhtApiService: HhtApiService,
    private toastr: ToastrService,
    private modal: NgbModal,
    private fb: FormBuilder
    ) { 
      this.subItnFg = this.fb.group({
        subItns: this.fb.array([])
      });

      this.route.params.subscribe({
        next: (result) => {
          if(!!result){
            this.splitItineraryDetails = result;
            console.log(result);
            this.prepSubItnFields();
          }
        },
        error: (error) =>{
          //error stack
        }
      })
    }
    

  ngOnInit() {
    this.loadMeters();
  }

  prepSubItnFields(){
    for(let i = 0; i < this.splitItineraryDetails.noOfSubItn; i++){
      this.subItineraryGroup.push(String.fromCharCode("A".charCodeAt(0)+i));
      this.subItnFgArray.push(this.fb.group({
        subItnName: String.fromCharCode("A".charCodeAt(0)+i),
        range: null,
        totalPerCT: 0
      }));
    }
  }

  get subItnFgArray(){
    return this.subItnFg.get('subItns') as FormArray;
  }

  loadMeters(){
    this.isLoading = true;
    this.hhtApiService.get_splitMeters(this.splitItineraryDetails.id).subscribe(
      {
        next: (result) => {
          this.meters = result['body']['results'].map(
            (data: any) => {
              data['assignedGroup'] = "";
              data['checked'] = false;
              return data;
            }
          );
          this.metersDraft = JSON.parse(JSON.stringify(this.meters)); //clone array
          this.isLoading = false;
          this.refreshMeterCount();
        },
        error: (stack) =>{
          this.isLoading = false;
        }
      }
    );
  }

  searchMeters(modalContent){
    this.modalRef = this.modal.open(modalContent, {size: 'lg', scrollable: true, backdrop: 'static'});
  }

  cancelModal(modalContent){
    this.modal.open(modalContent);
  }

  refreshMeterCount(){
    this.totalMetersPerCT = 0;
    this.subItnFgArray.controls.forEach(
      (fg: FormGroup) =>{
        fg.get('range').patchValue(this.meters.reduce((pv, cv) => cv.assignedGroup == fg.get('subItnName').value ? ++pv : pv, 0));
        fg.get('totalPerCT').patchValue(this.meters.reduce((pv, cv) => cv.assignedGroup == fg.get('subItnName').value ? pv + cv.total : pv, 0));
        this.totalMetersPerCT += fg.get('totalPerCT').value;
      }
    );
    this.assigned = this.meters.reduce((pv, cv) => cv.assignedGroup != "" ? ++pv : pv, 0);
    this.unassigned = this.meters.reduce((pv, cv) => cv.assignedGroup == "" ? ++pv : pv, 0);
    this.metersDraft = JSON.parse(JSON.stringify(this.meters));
  }

  searchMetersSave(){
    this.refreshMeterCount();
  }

  checkboxAssign(){
    this.metersDraft = this.metersDraft.map(
      (data) => {
        if(!!data['checked']){
          data['assignedGroup'] = this.assignToBulk;
          data['checked'] = false;
        }
        return data;
      }
    );

  }

  autoAssign(){
    //check if input exceeds the number of unassigned meters
    const totalMeters = this.meters.length;
    let total: number = 0;
    this.subItnFgArray.controls.forEach(
      (fg: FormGroup) => {
        total += parseInt(!!fg.get('range').value ? fg.get('range').value : 0);
      }
    );
    if(total <= totalMeters){ //don't allow change in range if there are no unassigned meters left and total range is greater than the unassigned meters
      let meterArrayIndex = 0; //index for meters aray
      let valAsOf = 0; //will be used to cut to the next iteration of formArray
      this.clearAssignedMeters(); //resets the assigned groups every range auto assign trigger
      this.subItnFgArray.controls.forEach(
        (fg: FormGroup) => {
          valAsOf += parseInt(!!fg.get('range').value ? fg.get('range').value : 0);
          console.log(valAsOf);
          while(meterArrayIndex < this.meters.length && meterArrayIndex < valAsOf){
            this.meters[meterArrayIndex].assignedGroup = fg.get('subItnName').value;
            meterArrayIndex += 1;
          }
        }
      );
    }else{
      this.toastr.error('You have exceeded the total number of assignable meters.', 'Assign Meters', 
        {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
    }
    this.refreshMeterCount();
  }

  clearAssignedMeters(){
    this.meters = this.meters.map((meter) => { 
      meter.assignedGroup = '';
      return meter;
    });
  }

  saveSetMeters(){
    this.meters = JSON.parse(JSON.stringify(this.metersDraft)); //clone array
  }

  cancelSetMeters(){
    this.metersDraft = JSON.parse(JSON.stringify(this.meters)); //reset the array
  }

  confirmSplit(modalRef){
    let valid = true;
    for(let i = 0; i < this.subItnFgArray.controls.length; i++){
      //valid = !!this.subItnFgArray.controls[i].get('range').value && parseInt(this.subItnFgArray.controls[i].get('range').value) > 0;
      if(!this.subItnFgArray.controls[i].get('range').value){
        valid = false;
        break;
      }
    }
    if(!valid){
      this.toastr.error('A sub-itinerary must not be empty.', 'Confirm Split', 
        {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
    }else{
      this.modalRef = this.modal.open(modalRef, {size: 'lg', scrollable: true, backdrop: 'static'});
    }
  }

  split(){
    this.isLoading = true;
    const body = {
      id: this.splitItineraryDetails.id,
      split: this.meters
    }
    this.hhtApiService.splitMeters(body).subscribe({
      next: (result) =>{
        this.isLoading = false;
        this.toastr.success('Split Itinerary is successful.', 'Success', 
        {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
        this.navigateToItnList();
      },
      error: (err) =>{
        console.log(err);
        
        this.toastr.success('Something went wrong. Please try again later.', 'Error', 
          {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
        this.isLoading = false;
      }
    })
  }

  navigateToItnList(){
    this.router.navigate(['hht/itinerary-list']);
  }
  
}
