import { Component, OnInit, Input } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  // images = [1, 2, 3, 4].map(() => `https://picsum.photos/900/500?random&t=${Math.random()}`);

  @Input() meter_number:string;
  @Input() consumption_type:string;
  @Input() actual_reading_date:string;


  // meter_number = '102AA142630'
  // consumption_type = 'KH'
  // actual_reading_date = '20201127'
  imageSrc = []
  noImage:boolean

  constructor(config: NgbCarouselConfig, 
    private hhtApiService: HhtApiService,
    private datePipe: DatePipe
    ) {
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
   }

  ngOnInit(): void {
    let formattedDate = this.datePipe.transform(this.actual_reading_date,"yyyyMMdd")
    let consumption_type_lenth = this.consumption_type.length
    var consumption_type = ""
    if (consumption_type_lenth >= 2) {
      consumption_type = this.consumption_type.split("-")[0]
    }

    this.hhtApiService.getImage(this.meter_number, consumption_type, formattedDate).subscribe({
      next: (resp) => {
        this.imageSrc = resp['body']['image_filenames'];
        if(this.imageSrc.length > 0){
          this.noImage = false;
          console.log(this.noImage)
        } else{
          this.noImage = true;
          console.log(this.noImage)
        }
      },
      error: /* istanbul ignore next: cannot replicate API errors in karma */ (err) => {
          console.log(err);
      },
    })
  }

}
