import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'; 
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { AuthService } from '@app/shared/services/guards/auth.service';
import { AesService } from '@shared/services/aes.service';
import { take } from 'rxjs/operators';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as AWS from 'aws-sdk';
import { InformationComponent } from '@shared/modals/information/information.component';
import { Auth } from 'aws-amplify';
import { environment } from '@environments/environment';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '@shared/services/util.service';
@Component({
  selector: 'app-user-maintenance',
  templateUrl: './user-maintenance.component.html',
  styleUrls: ['./user-maintenance.component.scss']
})
export class UserMaintenanceComponent implements OnInit {

  cognitoIdentityServiceProvider: AWS.CognitoIdentityServiceProvider;

  accountTypes: any = {
    METER_READER: 'METER READER',
    METER_COORDINATOR: 'METER COORDINATOR',
    NON_MECO_OCR_METER_COORDINATOR: 'NON-MECO OCR METER COORDINATOR',
    OCR_METER_READER: 'OCR METER READER',
    MDOA_ADMIN: 'MDOA ADMIN',
    GP_DATA_STATION_OFFICER: 'GP DATA STATION OFFICER / COORDINATOR',
    NONGP_DATA_STATION_OFFICER: 'NON-GP DATA STATION OFFICER',
    MDOA_STAFF: 'MDOA STAFF',
    DMSA: 'DMSA',
    SUPERUSER: 'SUPERUSER',
    OCR_METER_COORDINATOR: 'OCR METER COORDINATOR'
  }

  meterReaderAccountTypes: Array<any> = [
    {key: 'METER READER', value: 'METER_READER', visible: true},
    // {key: 'METER COORDINATOR', value: 'METER_COORDINATOR', visible: true},
    // {key: 'NON-MECO OCR METER COORDINATOR', value: 'NON_MECO_OCR_METER_COORDINATOR', visible: true},
    // {key: 'OCR METER READER', value: 'OCR_METER_READER', visible: true}
  ];

  mrOnlineUserAccountTypes: Array<any> = [
    {key: 'MDOA ADMIN', value: 'MDOA_ADMIN', visible: true},
    {key: 'GP DATA STATION OFFICER / COORDINATOR', value: 'GP_DATA_STATION_OFFICER', visible: true},
    {key: 'NON-GP DATA STATION OFFICER', value: 'NONGP_DATA_STATION_OFFICER', visible: true},
    {key: 'MDOA STAFF', value: 'MDOA_STAFF', visible: true},
    {key: 'DMSA', value: 'DMSA', visible: true},
    {key: 'SUPERUSER', value: 'SUPERUSER', visible: true},
    //{key: 'OCR METER COORDINATOR', value: 'OCR_METER_COORDINATOR', visible: true}
  ]

  cognitoUserModal: NgbModalRef;

  maxRow: number = 10; //number of rows to display before going to next page
  searchParams: any = {
    email: '',
    userType: ''
  }

  userArray: Array<any> = [];
  userDisplayArray: Array<any> = [];
  userPage: number = 1;
  userCollectionSize: number = 0;
  userIsLoading: boolean = false;

  highlightedRowIndex: number = null;

  cognitoUserList: Array<string> = [];
  cognitoUserDisplayList: Array<string> = [];
  cognitoUserPage: number = 1;
  cognitoUserMaxRow: number = 5;
  cognitoUserCollectionSize: number = 0;
  cognitoUserHighlightedRowIndex: number = null;

  readingCenterCodesList: Array<string> = [];
  readingCenterCodesListObj: Array<any> = [];
  readingCenterCodesIsLoading: boolean = false;

  currentAction: string = 'ADD'; //ADD/UPDATE

  isSaving: boolean = false;
  rccSelectAll: boolean = false;
  gpIsChecked: boolean = false;
  nonGpIsChecked: boolean = false;
  mrOnlineFlag: boolean = false;
  meterReaderFlag: boolean = false;
  maskPassword: boolean = true;
  maskConfirmPassword: boolean = true;

  //password compliance conditions:
  max14Chars: boolean = false;
  hasUpperCase: boolean = false;
  hasLowerCase: boolean = false;
  hasSpecChar: boolean = false;
  hasNumChar: boolean = false;
  isNonRepeating: boolean = false;
  isNotEmail: boolean = false;
  passMatch: boolean = false;

  upCaseRegex: RegExp = new RegExp('[A-Z]');
  lowCaseRegex: RegExp = new RegExp('[a-z]');
  numberRegex: RegExp = new RegExp('[\\d]');
  symbolsRegex: RegExp = new RegExp('[-+_!@#$%^&*.,?]');
  isNonRepeatRegex: RegExp = new RegExp('(.)\\1{2,}');

  password: string = '';
  confirmPassword: string = '';
  updateUserPassData: any;
  updatePasswordIsLoading: boolean = false;

  private region;
  private accessKeyId;
  private secretAccessKey;
  private userPoolId;

  confirmModalHeader: string;
  confirmModalBody: any;
  confirmModalFormData: any;
  confirmModalYesButton: any;
  confirmModalNoButton: any;

  searchRegisteredUser: string = '';
  currentSortedColumn: string = '';
  currentSort: string = '';
  currentUser: any;

  userFormGroup: UntypedFormGroup = new UntypedFormGroup({
    code: new UntypedFormControl({value:''}, [Validators.required, Validators.maxLength(6), Validators.minLength(6)]),
    remarks: new UntypedFormControl({value:''}, [ Validators.maxLength(320), Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$')]),
    lastName: new UntypedFormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$'), Validators.pattern(/[\S]/g)]),
    firstName: new UntypedFormControl('', [Validators.required,  Validators.maxLength(50), Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$'), Validators.pattern(/[\S]/g)]),
    middleName: new UntypedFormControl('', [Validators.maxLength(50), Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$')]),
    suffixName: new UntypedFormControl('', [ Validators.maxLength(5), Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$')]),
    email: new UntypedFormControl({value:''}, [Validators.required]),
    accountType: new UntypedFormControl({value:''}, [Validators.required]),
    gpNonGp: new UntypedFormControl({value:''}),
    readingCenterCodes: new UntypedFormControl({value:''}, [Validators.required]),
    userType: new UntypedFormControl(''),
    expirationDate: new UntypedFormControl({value: '', disabled: true}),
  })

  addNewCognitoUserFormGroup: UntypedFormGroup = new UntypedFormGroup({
    code: new UntypedFormControl('', [Validators.required, Validators.maxLength(6), Validators.minLength(6)]),
    remarks: new UntypedFormControl('', [ Validators.maxLength(320), Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$')]),
    lastName: new UntypedFormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$'), Validators.pattern(/[\S]/g)]),
    firstName: new UntypedFormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$'), Validators.pattern(/[\S]/g)]),
    middleName: new UntypedFormControl('', [Validators.maxLength(50), Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$')]),
    suffixName: new UntypedFormControl('', [ Validators.maxLength(5), Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$')]),
    email: new UntypedFormControl('', [Validators.required]),
    accountType: new UntypedFormControl('', [Validators.required]),
    gpNonGp: new UntypedFormControl(''),
    readingCenterCodes: new UntypedFormControl('', [Validators.required]),
    userType: new UntypedFormControl('', [Validators.required])
  })

  constructor(private hhtApiService: HhtApiService,
              public authService: AuthService,
              private modal: NgbModal,
              private toastr: ToastrService,
              public utilService: UtilService) { }

  ngOnInit(): void {
    console.log('ngOninit')
    this.hhtApiService.getAWSCredentials(environment.name).subscribe((res: any[])=>{
      const encrypt = new AesService("678BD7CF516503D72622796422F2E7B9");
      this.region = encrypt.decrypt(res['body']['region']);
      this.accessKeyId = encrypt.decrypt(res['body']['accessKeyId']);
      this.secretAccessKey = encrypt.decrypt(res['body']['secretAccessKey']);
      this.userPoolId = encrypt.decrypt(res['body']['userPoolId']);
      AWS.config.update({region: this.region, 
        accessKeyId: this.accessKeyId, 
        secretAccessKey: this.secretAccessKey}); //AWS is 'aws-sdk'
        this.cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();
    });

    this.getUsers();
  }

  searchUser(){
    this.userIsLoading = true;
    this.hhtApiService.getReaderFFCodes(this.searchParams) //reader code is the endpoint for users since users and reader codes merged as one
                      .subscribe({
                        next: (res) => {
                          this.userArray = res['body']['results'];
                          this.userCollectionSize = this.userArray.length;
                          this.pageChange('REGISTERED');
                          this.userIsLoading = false;
                          console.log('userCollectionSize')
                          console.log(this.userArray.length)
                        },
                        error: /* istanbul ignore next: cannot replicate errors in unit tests relating to api errors */(error) => {
                          console.error('Error fetching data', error);
                          this.userIsLoading = false;
                        }
                      });
  }

  getUsers(){
    this.highlightedRowIndex = null;
    this.userFormGroup.reset();
    this.userArray = [];
    this.userIsLoading = true;
    Auth.currentUserInfo().then(user=>{
      console.log('currentUserInfo');
      this.authService.assignCurrentUser(String(user['username']).replace(environment.adPrefix, '')).then(()=>{
        console.log('assignCurrentUser')
        this.currentUser = this.authService.getCurrentUser();
        /* istanbul ignore else */
        if(String(this.currentUser.accountType).toUpperCase() == 'SUPERUSER'){
          this.addNewCognitoUserFormGroup.get('userType').enable();
          this.userFormGroup.get('userType').enable();
        }else{
          this.addNewCognitoUserFormGroup.get('userType').disable();
          this.userFormGroup.get('userType').disable();
        }
        /* istanbul ignore else */
        if(['SUPERUSER', 'DMSA'].includes(String(this.currentUser.accountType).toUpperCase())){
          this.searchParams.userType = '';
        }else{
          this.searchParams.userType = '0';
        }
        this.mrOnlineFlag = ['DMSA', 'SUPERUSER'].includes(this.currentUser.accountType);
        this.meterReaderFlag = ['MDOA_ADMIN', 'GP_DATA_STATION_OFFICER', 'NONGP_DATA_STATION_OFFICER', 'SUPERUSER', 'METER_COORDINATOR'].includes(this.currentUser.accountType);
        this.hhtApiService.getReaderFFCodes(this.searchParams) //reader code is the endpoint for users since users and reader codes merged as one
                      .subscribe({
                        next: (res) => {
                          this.userArray = res['body']['results'];
                          this.userCollectionSize = this.userArray.length;
                          this.pageChange('REGISTERED');
                          this.userIsLoading = false;   
                        },
                        error:/* istanbul ignore next: cannot replicate errors in unit tests relating to api errors */(error) => {
                          console.error('Error fetching data', error);
                          this.userIsLoading = false;
                        }
                      });
      })
    });
  }

  pageChange(table){
    switch(table){
      case 'REGISTERED': //table for users already existing in DB
        if(this.highlightedRowIndex != null || this.highlightedRowIndex != undefined){
          this.userFormGroup.reset();
        }
        this.highlightedRowIndex = null;
        this.userDisplayArray = this.userArray
        .map((elements, index) => ({
          test: index + 1, ...elements
        }))
        .slice((this.userPage - 1) * this.maxRow, (this.userPage -1) * this.maxRow + this.maxRow);
        // this.currentSort = '';
        // this.currentSortedColumn = '';
      break;
      case 'COGNITO': //table for users in AWS Cognito
        if(this.cognitoUserHighlightedRowIndex != null || /* istanbul ignore next */this.cognitoUserHighlightedRowIndex != undefined){
          this.addNewCognitoUserFormGroup.get('email').reset();
        }
        this.cognitoUserHighlightedRowIndex = null;
        this.cognitoUserDisplayList = this.cognitoUserList
        .filter(/* istanbul ignore next */(data) => data.includes(this.searchRegisteredUser))
        .slice((this.cognitoUserPage - 1) * this.cognitoUserMaxRow, (this.cognitoUserPage -1) * 
                this.cognitoUserMaxRow + this.cognitoUserMaxRow);
      break;
    }
  }

  selectedRow(data, rowIndex) {
    if(this.highlightedRowIndex == rowIndex){
      this.currentAction = 'ADD';
      this.highlightedRowIndex = null;
      //this.userFormGroup.disable();
      this.userFormGroup.reset();
      this.resetGpNonGp();
    }else{
      this.currentAction = 'UPDATE';
      this.highlightedRowIndex = rowIndex;
      this.userFormGroup.patchValue({
        oldCode: data.code,
        code: data.code,
        oldRemarks: data.remarks,
        remarks: data.remarks,
        email: data.email,
        accountType: data.accountType,
        gpNonGp: data.gp_nongp,
        readingCenterCodes: String(data.readingCenterCodes).split('|'),
        firstName: data.first_name,
        middleName: data.middle_name,
        lastName: data.last_name,
        suffixName: data.suffix_name,
        readingCCSelect: data.readingCenterCodes.split('|'),
        userType: data.userType,
        expirationDate: data.expirationDate
      });
      this.accountTypeValidations(data.email);
      this.gpIsChecked = data.gp_nongp == 1 || data.gp_nongp == 2;
      this.nonGpIsChecked = data.gp_nongp == 0 || data.gp_nongp == 2;
    }
  }

  selectedCognitoUser(email, index){
    if(this.cognitoUserHighlightedRowIndex == index){
      this.cognitoUserHighlightedRowIndex = null;
      this.addNewCognitoUserFormGroup.patchValue({ //reset account type and reading center codes because they depend on the selected email (AD/Non-AD)
        accountType: '',
        readingCenterCodes: ''
      })
    }else{
      this.cognitoUserHighlightedRowIndex = index;
      this.accountTypeValidations(email);
      let emailDomain = String(String(email).split('@')[1]).toLowerCase();
      /* istanbul ignore if */
      if(emailDomain != 'meralco.com.ph') { //if not using meralco domain then the user is not registered via AD, ergo he/she cannot edit gp non gp field
        this.addNewCognitoUserFormGroup.get('gpNonGp').disable();
        this.addNewCognitoUserFormGroup.patchValue({
          email: email,
          gpNonGp: '0',
          accountType: ''
        });
      }else{
        this.addNewCognitoUserFormGroup.get('gpNonGp').enable();
        this.addNewCognitoUserFormGroup.patchValue({
          email: email,
          gpNonGp: '1',
          accountType: ''
        });
      }
      //temporary removed until I figure out the problem
      // if(this.addNewCognitoUserFormGroup.get('userType').valid && this.addNewCognitoUserFormGroup.get('email').valid){
      //   this.addNewCognitoUserFormGroup.get('accountType').enable();
      //   this.addNewCognitoUserFormGroup.get('readingCenterCodes').enable();
      // }else{
      //   this.addNewCognitoUserFormGroup.get('accountType').disable();
      //   this.addNewCognitoUserFormGroup.get('readingCenterCodes').disable();
      // }
    }
  }

  /* istanbul ignore next: to revisit */
  addUser(modalContent) {
    // addUser will not work if this.region, this.accessKeyId and this.secretAccessKey is not set
    if (!this.region && !this.accessKeyId && !this.secretAccessKey && !this.userPoolId) {
      return;
    }
    this.searchRegisteredUser = '';
    //this.highlightedRowIndex = null;
    //this.userFormGroup.disable();
    //this.userFormGroup.reset();
    //this.resetGpNonGp();
    //this.currentAction = 'ADD';
    if(this.currentAction == 'ADD'){
      if(!!this.mrOnlineFlag && !this.meterReaderFlag){
        this.addNewCognitoUserFormGroup.get('userType').setValue('1');
      }else if(!this.mrOnlineFlag && !!this.meterReaderFlag){
        this.addNewCognitoUserFormGroup.get('userType').setValue('0');
      }else{
        this.addNewCognitoUserFormGroup.get('userType').setValue('0');
      }
      
      this.changeUserType(true);
      this.cognitoUserList = [];
      let cognitoUsers: Array<any>;
      let params = {
        UserPoolId: this.userPoolId, //this should change depending on the current environment, consider setting this up in environment.ts
        // AttributesToGet: ['email', 'password'],
        // Filter: '',
        // PaginationToken: '1',
        // Limit: 60
      }
        this.cognitoIdentityServiceProvider.listUsers(params, (err, data)=>{
          this.hhtApiService.getReaderFFCodes({email: '', userType: ''})
                            .pipe(take(1))
                            .subscribe(res =>{
                              let users = res['body']['results'];
                              if(!!users && users.length > 0){
                                let usersInDb: Array<string> = users.map(users=>{
                                  return users.email;
                                })
                                cognitoUsers = data['Users'];
                                cognitoUsers = cognitoUsers.filter(data =>{
                                  //return only users that don't exist in DB, cognito user status is confirmed and enabled.
                                  return !usersInDb.includes(String(data['Username']).replace(environment.adPrefix, '')) && 
                                                            (data['UserStatus'] == 'CONFIRMED' || data['UserStatus'] == 'EXTERNAL_PROVIDER') 
                                                            && !!data['Enabled'];
                                })
                                for(let user of cognitoUsers){
                                  this.cognitoUserList.push(String(user['Username']).replace(environment.adPrefix, '')); //for users that came from AD, remove MeralcoDev_ prefix
                                }
                                this.cognitoUserCollectionSize = this.cognitoUserList.length;
                                this.pageChange('COGNITO');
                              }
                            })
        });
    }else if(this.currentAction == 'UPDATE'){
      this.loadReadingCCodes(this.currentAction);
      this.changeUserType(true);
    }
    
    //open modal
     this.cognitoUserModal = this.modal.open(modalContent, {size: 'lg', scrollable: true, backdrop: 'static'});
     this.cognitoUserModal.result.then(()=>{
       this.addNewCognitoUserFormGroup.reset();
       this.userFormGroup.reset();
       this.resetGpNonGp();
     })
  }

  /* istanbul ignore next: to revisit */
  openRCCModal(modalContent, from){ //from is either add or update user
    this.currentAction = from;
    this.readingCenterCodesList = [];
    this.readingCenterCodesListObj = [];
    this.readingCenterCodesIsLoading = true;
    this.hhtApiService.getReadingCenterCodes(
            from == 'ADD' ? this.addNewCognitoUserFormGroup.get('gpNonGp').value :
                            this.userFormGroup.get('gpNonGp').value
            )
            .subscribe({
              next: (result) => {
                this.readingCenterCodesList = result['body']['results'];
                if(from == 'UPDATE'){
                  let userRCCList: Array<string> = String(this.userFormGroup.get('readingCenterCodes').value).split('|');
                  this.readingCenterCodesListObj = this.readingCenterCodesList.map(data =>{
                    if(userRCCList.includes(data)){
                      return {checked: true, readingCenterCode: data['readingCenterCode']}
                    }else{
                      return {checked: false, readingCenterCode: data['readingCenterCode']}
                    }
                  });
                }else{
                  this.readingCenterCodesListObj = this.readingCenterCodesList.map(data =>{
                    return {checked: false, readingCenterCode: data['readingCenterCode']}
                  });
                }
                this.readingCenterCodesIsLoading = false;
              },
              error: (error) => {
                console.error('Error getting reading center codes', error);
                this.readingCenterCodesIsLoading = false;
              }
            });
    //open modal
    const readingCenterCodeMdl = this.modal.open(modalContent, {scrollable: true});
  }

  /* istanbul ignore next: to revisit */
  confirmSelectedRCC(){ //from is either add or update user
    let rccResult: string = '';
    for(let rcc of this.readingCenterCodesListObj){
      if(!!rcc.checked){
        rccResult = rccResult.length == 0 ? rccResult.concat(rcc.readingCenterCode) :
                                            rccResult.concat('|', rcc.readingCenterCode);
      }
    }
    console.log(rccResult);
    console.log(this.currentAction);
    switch(this.currentAction){
      case 'ADD':
        this.addNewCognitoUserFormGroup.patchValue({
          readingCenterCodes: rccResult
        });
      break;
      case 'UPDATE':
        this.userFormGroup.patchValue({
          readingCenterCodes: rccResult
        });
      break;
    }
    this.rccSelectAll = false;
  }
  
  
  /* istanbul ignore next: to revisit */
  crudUserMaintenance(action: string){
    this.isSaving = true;
    let params = {}
    let rccResult: string = '';
    let hasGpRcc: boolean = false;
    let hasNonGpRcc: boolean = false;
    let toastrHeader: string = action == 'ADD' ? 'Added User' : action == 'UPDATE' ? 'Update User' : 'Deactivated User';
    let toastrMessage: string = action == 'ADD' ? 'User has been added successfully' :
                                action == 'UPDATE' ? 'User has been updated successfully' : 'User has been deactivated successfully';
    if(action == 'ADD'){
      this.addNewCognitoUserFormGroup.get('readingCenterCodes').value.forEach(rcc=>{
        rccResult = rccResult.length == 0 ? rccResult.concat(rcc) :
                                            rccResult.concat('|', rcc);
        if(String(rcc).substring(2) == '00'){
          hasGpRcc = true;
        }else{
          hasNonGpRcc = true;
        }
      });
      params = {
        action: 'create',
        code: this.addNewCognitoUserFormGroup.get('code').value,
        remarks: this.addNewCognitoUserFormGroup.get('remarks').value,
        first_name: this.addNewCognitoUserFormGroup.get('firstName').value,
        middle_name: this.addNewCognitoUserFormGroup.get('middleName').value,
        last_name: this.addNewCognitoUserFormGroup.get('lastName').value,
        suffix_name: this.addNewCognitoUserFormGroup.get('suffixName').value,
        email: this.addNewCognitoUserFormGroup.get('email').value,
        accountType: this.addNewCognitoUserFormGroup.get('accountType').value,
        gp_nongp: !!hasGpRcc && !!hasNonGpRcc ? '2' : !!hasGpRcc && !hasNonGpRcc ? '1' : '0',
        readingCenterCodes: rccResult,
        userType: this.addNewCognitoUserFormGroup.get('userType').value
      }
    }else{
      this.userFormGroup.get('readingCenterCodes').value.forEach(rcc=>{
        rccResult = rccResult.length == 0 ? rccResult.concat(rcc) :
                                            rccResult.concat('|', rcc);
        if(String(rcc).substring(2) == '00'){
          hasGpRcc = true;
        }else{
          hasNonGpRcc = true;
        }
      });
      params = {
        action: action.toLowerCase(),
        code: this.userFormGroup.get('code').value,
        remarks: this.userFormGroup.get('remarks').value,
        first_name: this.userFormGroup.get('firstName').value,
        middle_name: this.userFormGroup.get('middleName').value,
        last_name: this.userFormGroup.get('lastName').value,
        suffix_name: this.userFormGroup.get('suffixName').value,
        email: this.userFormGroup.get('email').value,
        accountType: this.userFormGroup.get('accountType').value,
        gp_nongp: !!hasGpRcc && !!hasNonGpRcc ? '2' : !!hasGpRcc && !hasNonGpRcc ? '1' : '0',
        readingCenterCodes: rccResult,
        userType: this.userFormGroup.get('userType').value
      }
    }
    this.hhtApiService.userMaintenanceCrud(params)
                      .pipe(take(1))
                      .subscribe(result=>{
                        this.isSaving = false;
                        this.modal.dismissAll();
                        if(result['body']['results'] == 'code exist'){
                         toastrMessage = 'The inputted reader code is already in use.';
                         toastrHeader = action == 'ADD' ? 'Add User Failed' : 'Update User Failed';
                        }else if(result['body']['results'] == 'email exist'){
                         toastrMessage = 'The selected email is already in use.';
                         toastrHeader = action == 'ADD' ? 'Add User Failed' : 'Update User Failed';
                        }
                        else if(result['body']['results'] == 'Invalid Input'){
                         toastrMessage = 'Invalid Input Parameters';
                         toastrHeader = action == 'ADD' ? 'Add User Failed' : 'Update User Failed';
                        }
                        this.toastr.success(toastrMessage, 
                          toastrHeader, {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
                        //refresh and reset
                        this.getUsers();
                        this.userFormGroup.reset();
                        this.addNewCognitoUserFormGroup.reset();
                        this.highlightedRowIndex = null;
                        this.cognitoUserHighlightedRowIndex = null;
                        this.currentAction = 'ADD';
                        this.resetGpNonGp();
                        Auth.currentUserInfo().then(data =>{ //refresh the details of the current logged in user in case he/she changed his/her role.
                          this.authService.setUserIsFromAd(!!String(data['username']).includes(environment.adPrefix)); //sets the global service variable isAD to check if user logged is from AD.
                          this.authService.assignCurrentUser(String(data['username']).replace(environment.adPrefix, ''));
                        })
                      },
                      error=>{
                        console.error('Failed to save', error);
                        this.modal.dismissAll();
                        this.toastr.error('An error has occurred. Your changes were not saved.', 
                          'Error', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
                        this.isSaving = false;
                      })
  }

  resetGpNonGp(){
    this.gpIsChecked = false;
    this.nonGpIsChecked = false;
  }

  changeAccountType(from) {
    if(from == 'ADD'){
      this.addNewCognitoUserFormGroup.patchValue({
        //reset readingCenterCodes if user changed his/her account type to Non-GP DSO (to handle instances where he/she has GP RCCs but now transferring to a NonGP)
        readingCenterCodes: this.addNewCognitoUserFormGroup.get('accountType').value == this.mrOnlineUserAccountTypes[2].value ? '' : this.addNewCognitoUserFormGroup.get('readingCenterCodes').value,
        gpNonGp: this.addNewCognitoUserFormGroup.get('gpNonGp').enabled ? '' : '0' //non-AD users are always Non-GP as per business
      });
      this.resetGpNonGp();
      this.nonGpIsChecked = this.addNewCognitoUserFormGroup.get('gpNonGp').disabled;
    }else {
      this.userFormGroup.patchValue({
         //reset readingCenterCodes if user changed his/her account type to Non-GP DSO (to handle instances where he/she has GP RCCs but now transferring to a NonGP)
         readingCenterCodes: this.userFormGroup.get('accountType').value == this.mrOnlineUserAccountTypes[2].value ? '' : this.userFormGroup.get('readingCenterCodes').value,
        gpNonGp: this.userFormGroup.get('gpNonGp').enabled ? '' : '0' //non-AD users are always Non-GP as per business
      })
      this.resetGpNonGp();
      this.nonGpIsChecked = this.userFormGroup.get('gpNonGp').disabled;
    }
  }

  /* istanbul ignore next: to revisit */
  loadReadingCCodes(action){ 
    this.currentAction = action;
    this.readingCenterCodesList = [];
    this.readingCenterCodesListObj = [];
    this.readingCenterCodesIsLoading = true;
    let rccParam = '';
    if(action == 'ADD'){
      String(this.addNewCognitoUserFormGroup.get('email').value).toLowerCase().split('@')[1] == 'meralco.com.ph' && 
             this.addNewCognitoUserFormGroup.get('accountType').value != this.mrOnlineUserAccountTypes[2].value ? rccParam = '' : rccParam = '0';
    }else{
      String(this.userFormGroup.get('email').value).toLowerCase().split('@')[1] == 'meralco.com.ph'  && 
      this.userFormGroup.get('accountType').value != this.mrOnlineUserAccountTypes[2].value ? rccParam = '' : rccParam = '0';
    }
    this.hhtApiService.getReadingCenterCodes(rccParam)
              .subscribe({
                next: (result) => {
                  this.readingCenterCodesList = result['body']['results'];
                  if(action == 'UPDATE'){
                    let userRCCList: Array<string> = String(this.userFormGroup.get('readingCenterCodes').value).split('|');
                    this.readingCenterCodesListObj = this.readingCenterCodesList.map(data =>{
                      if(userRCCList.includes(data)){
                        return {checked: true, readingCenterCode: data['readingCenterCode']}
                      }else{
                        return {checked: false, readingCenterCode: data['readingCenterCode']}
                      }
                    });
                  }else{
                    this.readingCenterCodesListObj = this.readingCenterCodesList.map(data =>{
                      return {checked: false, readingCenterCode: data['readingCenterCode']}
                    });
                  }
                  this.readingCenterCodesIsLoading = false;
                },
                error: (error) => {
                  console.error('Error getting reading center codes', error);
                this.readingCenterCodesIsLoading = false;
                }
              })
    //open modal
  }

  /* istanbul ignore next: to revisit */
  changeUserType(firstVisit?){
    if(this.currentAction == 'ADD'){
      //this.addNewCognitoUserFormGroup.get('accountType').setValue('');
      if(this.addNewCognitoUserFormGroup.get('userType').value == 1){
        this.addNewCognitoUserFormGroup.get('code').removeValidators([Validators.required]);
      }else{
        this.addNewCognitoUserFormGroup.get('code').addValidators([Validators.required]);
      }
      if(!firstVisit){
        this.addNewCognitoUserFormGroup.get('code').setValue('');
        this.addNewCognitoUserFormGroup.get('accountType').setValue('');
        this.addNewCognitoUserFormGroup.get('readingCenterCodes').setValue('');
      }
      this.addNewCognitoUserFormGroup.updateValueAndValidity();
    }else{
      if(this.userFormGroup.get('userType').value == 1){
        this.userFormGroup.get('code').removeValidators([Validators.required]);
      }else{
        this.userFormGroup.get('code').addValidators([Validators.required]);
      }
      if(!firstVisit){
        this.userFormGroup.get('code').setValue('');
        this.userFormGroup.get('accountType').setValue('');
        this.userFormGroup.get('readingCenterCodes').setValue('');
      }
      this.userFormGroup.updateValueAndValidity();
    }
  }

  accountTypeValidations(email){
    if(String(email).toLowerCase().split('@')[1] == 'meralco.com.ph'){
      for(let meterReader of this.meterReaderAccountTypes){
        meterReader.visible = ['METER COORDINATOR', 'METER READER'].includes(meterReader.key);
      }
      for(let mrOnline of this.mrOnlineUserAccountTypes){
        mrOnline.visible = mrOnline.key != 'NON-GP DATA STATION OFFICER';
      }
    }else{
      for(let meterReader of this.meterReaderAccountTypes){
        meterReader.visible = meterReader.key != 'METER COORDINATOR';
      }
      for(let mrOnline of this.mrOnlineUserAccountTypes){
        mrOnline.visible = mrOnline.key == 'NON-GP DATA STATION OFFICER';
      }
    }
  }

  /* istanbul ignore next: to revisit */
  confirmModal(modalContent, action){
    //delete/deactivate
    if(action == 'DELETE'){
      this.confirmModalHeader = `Deactivate User`;
      this.confirmModalBody = `Are you sure you want to deactivate this user?`;
      this.confirmModalFormData = [
        { 'key': 'Name:', 'value': `<div class="font-weight-bold">
                                      ${this.userFormGroup.get('firstName').value} 
                                      ${this.userFormGroup.get('middleName').value || ''} 
                                      ${this.userFormGroup.get('lastName').value} 
                                      ${this.userFormGroup.get('suffixName').value || ''} 
                                  </div>` }
      ];
      if(this.userFormGroup.get('userType').value == '0'){ //if Meter Reader, then also show his/her reader code
        this.confirmModalFormData.push(
          { 'key': 'Reader Code:', 'value': this.userFormGroup.get('code').value }
        );
      }
      this.confirmModalYesButton = () => {
        this.crudUserMaintenance('DELETE');
      };
      this.confirmModalNoButton = () => {
        this.cancelAction('DELETE');
      }
    }else if(action == 'UPDATE') {
      this.confirmModalHeader = `Update User`;
      this.confirmModalBody = `Are you sure you want to update record for this user?`;
      this.confirmModalFormData = [
        { 'key': 'Name:', 'value': `<div class="font-weight-bold">
                                      ${this.userFormGroup.get('firstName').value} 
                                      ${this.userFormGroup.get('middleName').value || ''} 
                                      ${this.userFormGroup.get('lastName').value} 
                                      ${this.userFormGroup.get('suffixName').value || ''} 
                                  </div>` }
      ];
      if(this.userFormGroup.get('userType').value == '0'){ //if Meter Reader, then also show his/her reader code
        this.confirmModalFormData.push(
          { 'key': 'Reader Code:', 'value': this.userFormGroup.get('code').value }
        );
      }
      this.confirmModalYesButton = () => {
        this.crudUserMaintenance('UPDATE');
      };
      this.confirmModalNoButton = () => {
        this.cancelAction('UPDATE');
      }
    }
    this.modal.dismissAll();
    const config: NgbModalOptions = {
      windowClass: 'min-height-474',
      backdrop: 'static'
    };
    this.modal.open(modalContent, config);
  }

  openUpdatePassModal(modalContent, rowData){
    this.updateUserPassData = rowData;
    this.modal.open(modalContent, {backdrop: 'static'});
  }

  onChangePasswordValidation(){
    this.isNonRepeating = !this.isNonRepeatRegex.test(this.password) && !!this.password;
    this.max14Chars = this.updateUserPassData['accountType'] == 'METER_READER' ? /* istanbul ignore next */this.password.length >= 9 : this.password.length >= 14;
    this.hasUpperCase = this.upCaseRegex.test(this.password);
    this.hasLowerCase = this.lowCaseRegex.test(this.password);
    this.hasNumChar = this.numberRegex.test(this.password);
    this.hasSpecChar = this.symbolsRegex.test(this.password);
    this.isNotEmail = this.password != this.updateUserPassData['email'] && !!this.password; 
    this.passMatch = this.password === this.confirmPassword && !!this.password;
  }

  confirmChangePass(modalContent){
    this.modal.dismissAll();
    this.modal.open(modalContent, {backdrop: 'static'});
  }

  cancelChangePass(){
    this.password = '';
    this.confirmPassword = '';
    this.updateUserPassData = null;
    this.resetPasswordBool();
    this.modal.dismissAll();
    this.toastr.success('User password was not updated.', 
      'Unsaved Changes', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
  }

  cancelAction(action){
    let cancelledAction = {
      'ADD': 'added',
      'UPDATE': 'updated',
      'DELETE': 'deactivated'
    }
    this.modal.dismissAll();
    this.toastr.success('User was not ' + cancelledAction[action] + '. No changes were saved.', 
      'Unsaved Changes', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
    this.addNewCognitoUserFormGroup.reset();
    this.userFormGroup.reset();
    this.highlightedRowIndex = null;
    this.currentAction = 'ADD';
  }

  /* istanbul ignore next: to revisit */
  updatePassword(){
    const body = {
      password: this.password,
      userName: this.updateUserPassData['email'],
      email: this.updateUserPassData['email']
    }
    this.updatePasswordIsLoading = true;
    this.hhtApiService.validateInsertPassword(body).subscribe(result =>{
      if (result['body'].data[0]['ErrCode'] == 1) {
        this.toastr.error(result['body'].data[0]['ErrMsg'], 
                          'Error Updating Password', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
        this.password = '';
        this.confirmPassword = '';
        this.resetPasswordBool();
        this.modal.dismissAll();
        this.updatePasswordIsLoading = false;
      } else {
          //if success in updating DB, execute AWS Cognito level change password.
          let test = {
            "Password": this.password,
            "Permanent": true,
            "Username": this.updateUserPassData['email'],
            "UserPoolId": this.userPoolId
         }
          this.cognitoIdentityServiceProvider.adminSetUserPassword(test, (err, data) =>{
            this.toastr.success('User password has been successfully saved.', 
                          'Updated Password', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
            this.updatePasswordIsLoading = false;
            this.password = '';
            this.confirmPassword = '';
            this.resetPasswordBool();
            this.modal.dismissAll();
          })
      }
    })
  }
  
  /* istanbul ignore next: to revisit */
  onSearchRegisteredUser(event) {
    this.searchRegisteredUser = event.target.value;
    this.cognitoUserPage = 1;
    const filteredCognitoUserList = this.cognitoUserList.filter((data) => data.includes(this.searchRegisteredUser));
    this.cognitoUserCollectionSize = filteredCognitoUserList.length;
    this.pageChange('COGNITO');
  }

  resetPasswordBool(){
    this.max14Chars = false;
    this.hasUpperCase = false;
    this.hasLowerCase = false;
    this.hasNumChar = false;
    this.hasSpecChar = false;
    this.isNotEmail = false 
    this.passMatch = false;
    this.isNonRepeating = false;
  }

  checkForSpecialCharacters(action: string, formControl: string): boolean{
    if(action == 'ADD'){
      return this.utilService.hasSpecialCharacters(this.addNewCognitoUserFormGroup.get(formControl).value);
    }else{
      return this.utilService.hasSpecialCharacters(this.userFormGroup.get(formControl).value);
    }
  }

  checkIfEmptyField(action: string, formControl: string): boolean{
    if(action == 'ADD'){
      return this.addNewCognitoUserFormGroup.get(formControl).dirty && this.addNewCognitoUserFormGroup.get(formControl).value?.trim().length == 0;
    }else{
      return this.userFormGroup.get(formControl).dirty && this.userFormGroup.get(formControl).value?.trim().length == 0;
    }
  }

  /* istanbul ignore next: to revisit */
  generateMPIN(modalContent, rowData){
    let requestBody = {
      reader_code: rowData['code']
    }
    this.hhtApiService.generateMPIN(requestBody)
                      .subscribe({
                        next: (result) => {
                          this.confirmModalHeader = `Generate MPIN`;
                          this.confirmModalBody = `Successfully generated new MPIN for the following account. Please advise user to login using this MPIN on the HHT App.`;
                          this.confirmModalFormData = [
                            { 'key': 'New MPIN:', 'value': `<div class="font-weight-bold highlight-text"> ${result['body']['results'][0]['pinEnabledMPIN']} </div>` },
                            { 'key': 'Reader Code:', 'value': `<div class="font-weight-bold"> ${rowData['code']} </div>` },
                            { 'key': 'Name:', 'value': `<div class="font-weight-bold">  
                                                              ${rowData['first_name']} 
                                                              ${rowData['middle_name'] || ''} 
                                                              ${rowData['last_name']} 
                                                              ${rowData['suffix_name'] || ''}  
                                                        </div>` },
                          ];
                          const config: NgbModalOptions = {
                            windowClass: 'min-height-474',
                            backdrop: 'static'
                          };
                          this.modal.open(modalContent, config);
                        },
                        error: (error) => {
                          this.toastr.error('An error has occurred. MPIN was not generated.', 
                            'Error', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
                        }
                      })
  }

  /* istanbul ignore next: to revisit */
  sortColumn(column){
    if(column == this.currentSortedColumn){
      this.currentSort = this.currentSort == '' ? 'asc' : this.currentSort == 'asc' ? 'desc' : '';
    }else{
      this.currentSortedColumn = column;
      this.currentSort = 'asc';
    }
    //sorting proper
    if(this.currentSort == 'asc'){
      this.userArray = this.userArray.sort((a,b) => (a[column] < b[column] ? -1 : 1));
      this.pageChange('REGISTERED');
    }else if(this.currentSort == 'desc'){
      this.userArray = this.userArray.sort((a,b) => (a[column] > b[column] ? -1 : 1));
      this.pageChange('REGISTERED');
    }else{ //reset table sorting
      this.getUsers();
    }
  }

  readerCodeFieldCheck(currentAction): boolean{
    if(currentAction == "ADD"){
      return this.addNewCognitoUserFormGroup.get('code').value?.length > 0 && this.addNewCognitoUserFormGroup.get('code').value?.length < 6;
    }else{
      return this.userFormGroup.get('code').value?.length > 0 && this.userFormGroup.get('code').value?.length < 6;
    }
  }

}
