// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  border-bottom: 0;
  justify-content: center;
}

.modal-header .modal-title {
  font-family: "Trade Gothic Bold";
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #171C8F;
}

.modal-body {
  text-align: center;
}

.modal-body label {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.mat-form-field {
  margin-top: 20px;
  max-width: 329px;
  width: 100%;
}

.modal-footer {
  border-top: 0;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/modals/select-reading-center/select-reading-center.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,gCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;;AAEA;EACI,gBAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;AACJ","sourcesContent":[".modal-header {\r\n    border-bottom: 0;\r\n    justify-content: center;\r\n}\r\n\r\n.modal-header .modal-title {\r\n    font-family: 'Trade Gothic Bold';\r\n    font-weight: 700;\r\n    font-size: 30px;\r\n    line-height: 36px;\r\n    color: #171C8F;\r\n}\r\n\r\n.modal-body {\r\n    text-align: center;\r\n}\r\n\r\n.modal-body label {\r\n    font-weight: 400;\r\n    font-size: 18px;\r\n    line-height: 21px;\r\n    color: #000000;\r\n}\r\n\r\n.mat-form-field {\r\n    margin-top: 20px;\r\n    max-width: 329px;\r\n    width: 100%;\r\n}\r\n\r\n.modal-footer {\r\n    border-top: 0;\r\n    justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
