import { Component, OnInit, NgZone} from '@angular/core';
import { fromEvent, merge, interval, Observable, of, Subscription } from 'rxjs'
import { switchMap, take, skipWhile, takeLast, skipUntil, tap } from 'rxjs/operators'
import { Auth } from 'aws-amplify';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { StoreService } from '@app/shared/services/store.service';
import { environment } from '@environments/environment';
import IdleTimer from "./IdleTimer";
import { AuthService } from './shared/services/guards/auth.service';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  //seconds
  inactivityTime: number = 900;
  timer = '';
  timeLapsedSinceInactivity: number = 0;
  subscription: Subscription;
  observeable$: Observable<any>;
  mergedObservable$: Observable<any>;
  cognitoUserName:string;
  public inactivityTimerEvent: Array<any>[] = [[document, 'click'], [document, 'wheel'], [document, 'scroll'], [document, 'mousemove'], [document, 'keyup'], [window, 'resize'], [window, 'scroll'], [window, 'mousemove']];

  constructor(
    public _ngZone: NgZone,
    private hhtApiService: HhtApiService,
    private storeService: StoreService,
    private authService: AuthService,
    private router: Router
    ) {
      this.router.events.subscribe(/* istanbul ignore next: difficult to cover code called in constructor */(ev) => {
        if (ev instanceof NavigationEnd) { 
          //this.concurrentLoginHandler();
        }
      });
    }

  ngOnInit() {
    // let observableArray$: Observable<any>[] = [];
    // this.inactivityTimerEvent.forEach(x => {
    //   observableArray$.push(fromEvent(x[0], x[1]))
    // })
    // this.mergedObservable$ = merge(...observableArray$);

    // this.createObservable();
    console.log("START TIMER")
    this.timer = new IdleTimer({
      timeout: 900, //expired after 10 secs
      onTimeout: /* istanbul ignore next: cannot reproduce idle timeout in karma tests */() => {
        this.onLogOut();
      }
    });
  }

  /* istanbul ignore next: to revisit */
  createObservable(): void {
    this._ngZone.runOutsideAngular(() => {

      this.observeable$ = this.mergedObservable$
      .pipe(
        switchMap(ev => interval(1000).pipe(take(this.inactivityTime))),
        skipWhile((x) => {
          this.timeLapsedSinceInactivity = x;
          return x != this.inactivityTime - 1
        })
      );

      this.subscribeObservable();

    })

  }
  /* istanbul ignore next: to revisit */
  subscribeObservable() {
    this.subscription = this.observeable$.subscribe((x) => {
      console.log(`subscribed for ${x + 1} sec`);
      this.unsubscribeObservable()
    })
  }

  /* istanbul ignore next: to revisit */
  unsubscribeObservable() {
   //alert('Your session has expired. Press OK to reload the page and reauthenticate.')
   this.subscription.unsubscribe();
   localStorage.clear();
   location.reload();
  }

  /* istanbul ignore next: to revisit */
  onLogOut() {

    console.log('signOut')
    // Auth.signOut({ global: true })
    // // this.storeService.clear();
    // window.location.replace(environment.signOut);
    Auth.currentSession().then( data => {
        console.log('onLogOut()',data.getIdToken().payload['cognito:username'])
        this.cognitoUserName = data.getIdToken().payload['cognito:username'];
        const body = {
            username: this.cognitoUserName
          }
        this.hhtApiService.deleteSession(body).subscribe(res => {
            Auth.signOut({ global: true })
            localStorage.clear();
            this.storeService.clear();
            window.location.replace(environment.signOut);
        })
    })
    

}

/* istanbul ignore next: to revisit */
  concurrentLoginHandler(){
    this.authService.currentSession().subscribe(data => {

      console.log("concurrentLoginCheck")
      let username_ = data.getIdToken().payload['cognito:username']
      
      this.hhtApiService.get_websession_token(username_).subscribe((res: any[])=>{    
        //console.log("res1",sessionStorage.getItem('token'))
        //console.log("res2",res["body"]["results"][0]["jwttoken"]);
        //console.log("test2",res["body"]["results"].length);
        if (res["body"]["results"].length == 0){
          console.log("1")
          console.log("no token in db - closing session ")
          console.log("no token in db - closing session ")
          alert('Your account is logged in from different session. Press OK to reload the page and reauthenticate.')
          Auth.signOut()
          localStorage.clear();
          window.location.replace(environment.signOut);
        }
        else if (res["body"]["results"][0]["jwttoken"] != sessionStorage.getItem('token')){
          console.log("2")
          console.log("token not match - closing session")
          alert('Your account is logged in from different session. Press OK to reload the page and reauthenticate.')
          Auth.signOut()
          localStorage.clear();
          window.location.replace(environment.signOut);
        
          // const body = {
          //     username: username_
          //   }
          // this.hhtApiService.deleteSession(body).subscribe(res => {
          //     Auth.signOut()
          //     localStorage.clear();
          //     window.location.replace(environment.signOut);
          // })
        }
        else {
          console.log("3")
          console.log("token match")
        }
      })
    });
  }


}