import { Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/shared/services/guards/auth.service';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { PrintService } from '@shared/services/print.service';
import { Hub, Auth } from 'aws-amplify';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { InformationComponent } from '@shared/modals/information/information.component';
import { ToastrService } from 'ngx-toastr';
import { rejects } from 'assert';
import { SelectReadingCenterComponent } from '@app/shared/modals/select-reading-center/select-reading-center.component';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-itinerary-list',
  templateUrl: './itinerary-list.component.html',
  styleUrls: ['./itinerary-list.component.scss']
})
export class ItineraryListComponent implements OnInit, OnDestroy {

  @ViewChild('remoteUploadFailed') remoteUploadFailedMdl: any;

  isLoading: boolean = true;

  // sort
  page=1;
  prevPage=1;
  defPage=1;
  public toggleSort: boolean = true;
  public isSortedAgain: string;
  public selectedSort: string;

  public defaultMonth: string = "";
  public defaultYear: string = "";

  p: number = 1;
  public searchText : string;

  itineraryArray = [];

  userAccountInfo = [];

  searchString = '';
  limit = 10;
  offset= 0;
  totalCount: number;
  tempStatusFilter: string = '';
  itineraryStatusFilter: string = '';
  isFilterToggled: boolean = false;

  monthParse = {
    "01":"January",
    "02":"February",
    "03":"March",
    "04":"April",
    "05":"May",
    "06":"June",
    "07":"July",
    "08":"August",
    "09":"September",
    "10":"October",
    "11":"November",
    "12":"December"
  }

  monthOptions = [
    {
      month: '-',
      value: ''
    },
    {
      month: 'January',
      value: '01'
    },
    {
      month: 'February',
      value: '02'
    },
    {
      month: 'March',
      value: '03'
    },
    {
      month: 'April',
      value: '04'
    },
    {
      month: 'May',
      value: '05'
    },
    {
      month: 'June',
      value: '06'
    },
    {
      month: 'July',
      value: '07'
    },
    {
      month: 'August',
      value: '08'
    },
    {
      month: 'September',
      value: '09'
    },
    {
      month: 'October',
      value: '10'
    },
    {
      month: 'November',
      value: '11'
    },
    {
      month: 'December',
      value: '12'
    }
  ]
  convertMonth:number;
  selectedMonth:string;
  yearOptions = ['-'];
  selectedYear:string;
  userEmail:string;
  userName:string;

  cognitoUserName:string;
  Password0: string = '';
  selectedDevice: string = '';
  selectedMRFF: number = 1; // defau;t value of MRFF
  selectedConsumption: number = 1; //default value of Consumption
  noOfSubItn: number;


  assignToRoverFlag: boolean = false;
  assignToRoverSplitFlag: boolean = false;
  remoteFlag: boolean = false;
  uploadRemoteLoading: boolean = false;
  uploadRemoteIsError: boolean = false;
  uploadRemoteStatus: string = '';
  uploadRemoteErrorStack: Array<string> = [];
  isMultipleSelected: boolean = false;
  uploadToCISFlag: boolean = false;
  uploadToCISIsLoading: boolean = false;
  selectAllFlag: boolean = false;
  mergeFlag: boolean = false;
  assignToRoverList: Array<any> = [];
  deviceCodeList: Array<any> = [];
  deviceCode$: Subscription;
  assignToRover$: Subscription;
  assignToRoverModal: NgbModalRef;
  splitModal: NgbModalRef;
  previewOfItinerary$: Subscription;
  itineraryPreviewFlag: boolean = false;
  itineraryPreviewData: any;
  lotsDisplayData: Array<any> = [];
  lotsPage: number = 1;
  lotsCollectionSize: number = 0;
  itineraryPreviewIsLoading: boolean = false;
  uploadToCISItineraries: Array<any> = [];
  isUploadToCISButtonDisabled: boolean = true;

  sortKey: string = '';
  sortOrder: string = '';

  remoteItnFile: File = null;

  constructor(private router: Router, 
    private route: ActivatedRoute,
    private hhtApiService: HhtApiService,
    public authService: AuthService,
    private modal: NgbModal,
    public datePipe: DatePipe,
    private printService: PrintService,
    private toastr: ToastrService
    ) {
     }
    

  ngOnInit() {

    //async function onSignOutClick() {
    //  await Auth.signOut()
    //      .then(data => console.log(data))
    //      .catch(err => console.log(err));
    //  console.log(Auth.signOut({global:true}));
    //}
    //onSignOutClick()

    //async function signOut() {
    //  try{
    //    console.log('will signout')
    //    await Auth.signOut({ global: true })
    //  } catch (error){
    //    console.log('error signing out: ', error);
    //  }
    //}
    //signOut() 
    // console.log('-----')
    

    
    //sessionStorage.getItem('token')
    


    
    
    
    this.authService.isVerified().subscribe(data => {
      this.userEmail = String(data['username']).replace(environment.adPrefix, '');
      this.authService.assignCurrentUser(this.userEmail);
      var d = new Date();
      var currentMonth = d.getMonth();
      this.convertMonth = (currentMonth + 1);
      var month_ = "";
      /* istanbul ignore if */
      if (this.convertMonth.toString().length == 1){
        month_ = "0" + this.convertMonth.toString();
      }
      else {
        month_ = this.convertMonth.toString()
      }
 


      //this.selectedMonth =  month_;
      this.selectedMonth = '';
      var currentYear = d.getFullYear();
      var startYear = currentYear - 10;
      for(let i=0; i<11; i++){
        let yearOption = startYear + i;
        this.yearOptions.push(String(yearOption).toString())
      }

      //this.selectedYear = currentYear.toString();
      this.selectedYear = '-';

      this.hhtApiService.getReaderCodesbyEmail(this.userEmail).subscribe((res: any[])=>{
        const readingCenterCode = res['body']['results'][0]['readingCenterCode'].split('|');
        const defaultReadingCenterCode = res['body']['results'][0]['defaultReadingCenterCode'];
        /* istanbul ignore if: to revisit, cannot replicate empty defaultReadingCenterCode */
        if (readingCenterCode.length > 1 && /* istanbul ignore next */!defaultReadingCenterCode) {
          const config: NgbModalOptions = {
            backdrop: 'static',
            windowClass: 'min-height-474',
            keyboard: false
          };
          const modalRef = this.modal.open(SelectReadingCenterComponent, config);
          modalRef.componentInstance.readingCenters = readingCenterCode;
          modalRef.componentInstance.email = this.userEmail;
          modalRef.componentInstance.afterSaving = () => {
            // this.hhtApiService.getAllItineraries(this.searchString, this.limit, this.offset, this.selectedYear, this.selectedMonth, this.userEmail, this.itineraryStatusFilter, this.sortKey, this.sortOrder).subscribe((res: any[])=>{    
            //   this.itineraryArray = res['body']['itineraries'];
            //   this.totalCount = res['body']['details'][0]['total_count'];
            //   this.itineraryArray.forEach(item => {
            //     item.checked = this.assignToRoverList.some(list => list.itineraryNumber == item.itineraryNumber)
            //   });
            //   // console.log('totalCount',this.totalCount)
            //   // console.log('itineraryArray',this.itineraryArray)
            //   this.isLoading = false;
            // });
            this.isLoading = false;
          };
        } else {
          // this.hhtApiService.getAllItineraries(this.searchString, this.limit, this.offset, this.selectedYear, this.selectedMonth, this.userEmail, this.itineraryStatusFilter, this.sortKey, this.sortOrder).subscribe((res: any[])=>{    
          //   this.itineraryArray = res['body']['itineraries'];
          //   this.totalCount = res['body']['details'][0]['total_count'];
          //   this.itineraryArray.forEach(item => {
          //     item.checked = this.assignToRoverList.some(list => list.itineraryNumber == item.itineraryNumber)
          //   });
          //   // console.log('totalCount',this.totalCount)
          //   // console.log('itineraryArray',this.itineraryArray)
          //   this.isLoading = false;
          // });
          this.isLoading = false;
        }
      });
    
      
      
      // console.log(Auth.currentUserInfo());
      this.authService.currentSession().subscribe(data => {
        
        //console.log('TOKEN-->',data.getIdToken().getJwtToken())
        this.userName = String(data.getIdToken().payload['cognito:username']).replace(environment.adPrefix, '');
        //-------
        
        this.hhtApiService.getUserAccountInfo(this.userName).subscribe((res: any[])=>{  
          // console.log(res['body']['UserInfo'])
          this.userAccountInfo = res['body']['UserInfo']
          let currentUser = this.authService.getCurrentUser();
          // console.log(currentUser);
          /* istanbul ignore else */
          if(this.userAccountInfo.length > 0){       
            // console.log('Password Expiration Date:' + res['body']['UserInfo'][0]['expirationDate'])
            const d = new Date();
            let year = d.getFullYear();
            let month = d.getMonth() + 1;
            let day = d.getDate();
            // console.log(year + '-' + month + '-' + day)
            /* istanbul ignore else */
            if (new Date(res['body']['UserInfo'][0]['expirationDate']) >= d) {

            }
            else {
              if(!currentUser.isAD){ //temporary workaround so that AD user won't redirect to change password. Need more discussion on this.
                this.router.navigate(['hht/change-password', {mustChangePass: true}], 
                { 
                });
              }
            }
            
          }
          else {
            console.log('Credential does not exist in db')
            if(!currentUser.isAD){ //temporary workaround so that AD user won't redirect to change password. Need more discussion on this.
              this.router.navigate(['hht/change-password', {mustChangePass: true}], 
              { 
              });
            }
            
          }
        })
      });

     
    });

    

    
  }

  onKeyupEnterSearch() {
    if(typeof this.searchText !== "undefined" && this.searchString !== this.searchText){
      this.resetFlags();
      this.isLoading = true;
      this.searchText = this.searchText.trim();
      this.searchString = this.searchText;
      this.itineraryArray = [];
      this.totalCount = 0;
      console.log('test onKeyupEnterSearch')
      this.hhtApiService.getAllItineraries(this.searchString, this.limit, this.offset, this.selectedYear == '-' ? '' : this.selectedYear, this.selectedMonth, this.userEmail, this.itineraryStatusFilter, this.sortKey, this.sortOrder).subscribe((res: any[])=>{      
        if(typeof res['body']['itineraries'] === "object"){
          this.itineraryArray = res['body']['itineraries'];
          this.totalCount = res['body']['details'].length>0 ? res['body']['details'][0]['total_count']: /* istanbul ignore next */0;
        }
        this.isLoading = false;        
      })
    }
  }

  /* istanbul ignore next */
  ngOnDestroy(): void {
    if(!!this.deviceCode$){
      this.deviceCode$.unsubscribe();
    }
    if(!!this.assignToRover$){
      this.assignToRover$.unsubscribe();
    }
    if(!!this.previewOfItinerary$){
      this.previewOfItinerary$.unsubscribe();
    }
  }

  monthChanged(){
    if(this.selectedMonth != '' && this.selectedYear != '-'){
      this.isLoading = true;
      this.resetFlags();
      this.hhtApiService.getAllItineraries(this.searchString, this.limit, this.offset, this.selectedYear == '-' ? '' : this.selectedYear, this.selectedMonth, this.userEmail, this.itineraryStatusFilter, this.sortKey, this.sortOrder).subscribe((res: any[])=>{    
        // console.log(res['body']) 
        this.itineraryArray = res['body']['itineraries'];
        this.totalCount = res['body']['details'][0]['total_count'];
        // console.log(this.totalCount)
        // console.log(this.itineraryArray)
        this.isLoading = false;
      }) 
    }
  }

  yearChanged(){
    if(this.selectedMonth != '' && this.selectedYear != '-'){
      this.isLoading = true;
      this.resetFlags();
      this.hhtApiService.getAllItineraries(this.searchString, this.limit, this.offset, this.selectedYear == '-' ? '' : this.selectedYear, this.selectedMonth, this.userEmail, this.itineraryStatusFilter, this.sortKey, this.sortOrder).subscribe((res: any[])=>{    
        // console.log(res['body']) 
        this.itineraryArray = res['body']['itineraries'];
        this.totalCount = res['body']['details'][0]['total_count'];
        // console.log(this.totalCount)
        // console.log(this.itineraryArray)
        this.isLoading = false;
      }) 
    }
  }

  onIteneraryRowClick(item, action, modalRef?) {
    switch(action){
      case 'view':
        this.router.navigate(['hht/itinerary-list/selected-itinerary'], 
          { 
            //relativeTo: this.route,
            queryParams: {
            'itineraryCode': item.itineraryNumber,
            'readings': item.readings,
            'deviceCode':item.device_code,
            'readerCode':item.reader_code,
            'month':item.last_update_date.substring(0, 2),
            //'month':item.received_Month,
            'year':item.received_Year,
            'completionPercent': item.completionPercent,
            'email': this.authService.getCurrentUser().email,
            'accountType': this.authService.getCurrentUser().accountType,
            'isSplitted': item.isSplitted,
            'isRemote': item.isRemote,
            'split': item.split,
            'id': item.id
            },
            skipLocationChange: true //workaround so that the queryparams won't display in browser URL (security purpose)
          });
        break;
      case 'split':
          this.splitModal = this.modal.open(modalRef);
        break;
        /* istanbul ignore next */
      default:
        //nothing
    }
  }

  /* istanbul ignore next */
  onSort(param: string) {
    let currentIdx = (this.defPage - 1) * this.limit
    let currentList = this.itineraryArray.slice(currentIdx, currentIdx + this.limit)



    if (this.prevPage !== this.page){
      this.isSortedAgain = ''
      this.prevPage = this.page
      this.toggleSort = true;
    }
    
    if (param === this.isSortedAgain) {
      this.isSortedAgain = param

      if (this.selectedSort === 'asc'){
        this.selectedSort = 'desc'
        this.toggleSort = !this.toggleSort
      } else if (this.selectedSort === 'desc'){
        this.selectedSort = ''
        this.toggleSort = !this.toggleSort
      } else {
        this.selectedSort = 'asc'
      }
    } else {
      this.isSortedAgain = param
      this.selectedSort = 'asc'
      this.toggleSort = true
    }

    
    if (this.selectedSort === 'asc' || this.selectedSort === 'desc'){
      if (this.toggleSort) {
        currentList.sort((a: any, b: any) => {          
          let textA = a[param] === null ? null : a[param].toString().toUpperCase();
          let textB = b[param] === null ? null : b[param].toString().toUpperCase();
          if (textA < textB) { return -1; }
          if (textA > textB) { return 1; }
          if (textA === null) { return 1; }
          if (textB === null) { return -1; }
          return 0;
        })
      } else {
        currentList.sort((a: any, b: any) => { 
          let textA = a[param] === null ? null : a[param].toString().toUpperCase();
          let textB = b[param] === null ? null : b[param].toString().toUpperCase();
          if (textA < textB) { return 1; }
          if (textA > textB) { return -1; }
          return 0;
        })
      }    
      Array.prototype.splice.apply(this.itineraryArray, [currentIdx, currentList.length].concat(currentList));
    }
  }

  onPageChange(event){
    this.prevPage = this.page;
    this.page = event;
    this.selectedSort = '';
    this.offset = (event - 1) * this.limit;
    this.isLoading = true;
    this.selectAllFlag = false;
    // this.assignToRoverFlag = false;
    // this.uploadToCISFlag = false;
    // this.itineraryPreviewFlag = false;
    this.hhtApiService.getAllItineraries(this.searchString, this.limit, this.offset, this.selectedYear == '-' ? '' : this.selectedYear, this.selectedMonth, this.userEmail, this.itineraryStatusFilter, this.sortKey, this.sortOrder).subscribe((res: any[])=>{      
      this.itineraryArray= res['body']['itineraries'];
      this.itineraryArray.forEach(item => {
        item.checked = this.assignToRoverList.some(/* istanbul ignore next */list => list.itineraryNumber == item.itineraryNumber)
      });
      this.selectAllFlag = this.isCheckedAll();
      this.isLoading = false;
    }) 
  }
  
  /* istanbul ignore next: for validation only */
  omit_special_char(event)
  {   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
  }

  /* istanbul ignore next: to revisit */
  assignToRoverFlagFn(){
    //let arrayTest = this.itineraryArray.filter(data => { return !!data.checked });
    for(let item of this.itineraryArray){
      if(!!item.checked && !this.assignToRoverList.some(list => list.itineraryNumber == item.itineraryNumber && list.split == item.split)){
        item.imageHIGHLOW_0Consumption_prev = item.imageHIGHLOW_0Consumption; //added this property for assignToRoverList to remember the original imageHIGHLOW_0Consumption value in case user cancels assigning a rover
        item.imageMRFF_prev = item.imageMRFF; //added this property for assignToRoverList to remember the original imageHIGHLOW_0Consumption value in case user cancels assigning a rover
        this.assignToRoverList.push(item);
      }else if(!item.checked && this.assignToRoverList.some(list => list.itineraryNumber == item.itineraryNumber && list.split == item.split)){
        this.assignToRoverList = this.assignToRoverList.filter(data => {return data.itineraryNumber+data.split != item.itineraryNumber+item.split});
      }
    }
    this.isMultipleSelected = this.assignToRoverList.length > 1;
    if(!!this.isMultipleSelected){
      this.itineraryPreviewData = null;
      this.lotsDisplayData = [];
    }else{
      if(this.assignToRoverList.length == 1 && ['RECEIVED', 'SEPARATED HHT', 'HANDLED', 'PARTIALLY UPLOADED'].includes(String(this.assignToRoverList[0].status))){
        this.showItineraryPreview(this.assignToRoverList[0]);
      }else{
        this.lotsPage = 1;
      }
    }
    this.itineraryPreviewFlag = this.assignToRoverList.length == 1 && 
                                ((['RECEIVED', 'SEPARATED HHT', 'PARTIALLY UPLOADED'].includes(String(this.assignToRoverList[0].status).toUpperCase())) ||
                                 ('HANDLED' == String(this.assignToRoverList[0].status).toUpperCase() && !Number.isNaN(parseInt(this.assignToRoverList[0].completionPercent)) &&
                                 parseInt(this.assignToRoverList[0].completionPercent) != 100)
                                );
    const statusSet = new Set(this.assignToRoverList.map(selected => String(selected.status).toUpperCase()));
    const percent = new Set(this.assignToRoverList.map(selected => selected.completionPercent));
    const isMergeSet = new Set(this.assignToRoverList.map(selected => selected.isMerged))
    console.log(percent.has('100.00'));
    //const isAllCompleted = percent.every(item => item === '100.00');
    const assignToRoverSet = new Set(this.assignToRoverList.map(selected => selected.itineraryNumber)); // Set to compare set size to array length. To use for validation in assigning sub itineraries (must be with unique parents)
    const hasSelectedSubItn = this.assignToRoverList.filter(selected => selected.isSplitted == '1').length > 0; // To be used in Upload to CIS condition. Sub itineraries cannot be uploaded.
    //selected itineraries must only be with status RECEIVED, HANDLED or PARTIALLY UPLOADED to enable assign to rover
    this.assignToRoverFlag = (((!!statusSet.has('RECEIVED') || !!statusSet.has('HANDLED') || !!statusSet.has('PARTIALLY UPLOADED')) && !percent.has('100.00')) && !isMergeSet.has("1") && (!statusSet.has('SEPARATED HHT') && !statusSet.has('LOADED') && !statusSet.has('TRANSMITTED')));
    //sub itineraries cannot be assigned in bulk
    this.assignToRoverSplitFlag = this.assignToRoverList.length == assignToRoverSet.size; //all itineraries (including sub itineraries) must be unique.

    this.uploadToCISFlag = !!statusSet.has('HANDLED') && statusSet.size == 1 && !hasSelectedSubItn; //selected itineraries must only be with status HANDLED and no sub itineraries to enable upload to cis
  
    //merge sub itineraries validation
    this.mergeFlag = this.assignToRoverList.length > 1;//enable merge button if there's at least 2 itineraries selected. Validation will be served as toast message when Merge button is clicked

    //remotely read validation
    const isRemoteSet = new Set(this.assignToRoverList.map(selected => String(selected.isRemote).toUpperCase()));
    this.remoteFlag = isRemoteSet.has("1");
  }

  /* istanbul ignore next: to revisit */
  assignToRoverListFn(modalContent, option?){
    
    this.deviceCodeList = [];
    // this.assignToRoverList = this.itineraryArray.filter(itineraries =>{
    //   let arrayTest = this.itineraryArray.filter(data => { return !!data.checked });
    //   let percent = arrayTest.map(selected => selected.completionPercent)
    //   let isAllCompleted = percent.every(item => item === '100.00');

    //   return (
    //         !!itineraries.checked && 
    //                               (  String(itineraries.status).toUpperCase() == 'RECEIVED' ||
    //                                 (String(itineraries.status).toUpperCase() == 'HANDLED' && isAllCompleted == false ) ||
    //                                 String(itineraries.status).toUpperCase() == 'PARTIALLY UPLOADED'
    //                               )
    //         );
    // });

    //check if route_image_capturing is deactivated. If so, then set imageHIGHLOW_0Consumption and imageMRFF to zero.
    this.assignToRoverList = this.assignToRoverList.map(itn =>{
      if(itn.route_image_capturing == 0){
        itn.imageHIGHLOW_0Consumption = 0;
        itn.imageMRFF = 0;
      }
      return itn;
    });
    
    this.deviceCode$ = this.hhtApiService.getDeviceCodes('AVAILABLE')
        .subscribe({
          next: (result) => {
            this.deviceCodeList = result['body']['results'];
          },
          error: (error) => {
            alert('Error fetching Device Codes: \n'+ error)
          }
        });
   
    this.assignToRoverModal = this.modal.open(modalContent, option);
    
  }

  assignToRover(){
    //validations
    //check if there are duplicate device codes being used
    // const uniqueValues = new Set(this.assignToRoverList.map(roverList => roverList.deviceCode));
    // if(uniqueValues.size < this.assignToRoverList.length){
    //   const modalRef = this.modal.open(InformationComponent);
    //   modalRef.componentInstance.header = 'Information';
    //   modalRef.componentInstance.information = 'Itineraries must have its own unique device code.'
    //   modalRef.componentInstance.positiveButton = 'Close';
    //   return;
    // }
    
    //assigning proper
   
    let itnAssigned: Array<any> = [];
    // console.log(this.assignToRoverList);
    for(let itineraries of this.assignToRoverList){
      itnAssigned.push({
        itineraryCode: itineraries.itineraryNumber,
        deviceCode: itineraries.deviceCode,
        imageHIGHLOW_0Consumption: itineraries.imageHIGHLOW_0Consumption,
        imageMRFF: itineraries.imageMRFF,
        email: this.authService.getCurrentUser().email,
        year: this.selectedYear,
        month: this.selectedMonth,
        isSplitted: itineraries.isSplitted,
        split: itineraries.split,
        id: itineraries.id
      })
    }
    
    const requestPayload = { data: itnAssigned };
     //console.log('payload', requestPayload);
    this.modal.dismissAll();
    this.isLoading = true;
    this.itineraryPreviewFlag = false;
    this.hhtApiService.assignToRover(requestPayload)
                      .subscribe({
                        next: (result) => {
                          this.toastr.success('Your itinerary have been assigned successfully!', 'Updated Itineraries', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 })
                          this.resetFlags();
                          //fetch al itineraries again, but consider making one seperate function for getting itineraries as its always being used in this component (best practice!)
                          this.hhtApiService.getAllItineraries(this.searchString, this.limit, this.offset, this.selectedYear == '-' ? '' : this.selectedYear, this.selectedMonth, this.userEmail, this.itineraryStatusFilter, this.sortKey, this.sortOrder).subscribe((res: any[])=>{    
                            this.itineraryArray = res['body']['itineraries'];
                            this.totalCount = res['body']['details'][0]['total_count'];
                            this.itineraryArray.forEach(item => {
                              item.checked = this.assignToRoverList.some(/* istanbul ignore next */list => list.itineraryNumber == item.itineraryNumber)
                            });
                            this.isLoading = false;
                          })

                        },
                        error: /* istanbul ignore next */(error) => {
                          this.toastr.error('Error assigning to rover. Please try again later.', 'Error', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
                        }
                      })
  }

  /* istanbul ignore next: to revisit */
  uploadtoCISValidation(modalRef){
    if(this.uploadToCISItineraries.some(itn => parseInt(itn.completionPercent) < 100)){ //show message that some itineraries have incomplete readings
      this.modal.open(modalRef, {backdrop: 'static'})
    }else{
      this.uploadtoCISFn();
    }
  }

  /* istanbul ignore next: to revisit */
  uploadtoCISFn(){
    this.uploadToCISIsLoading = true;
    let requestBody = {
      data: [],
      email: this.authService.getCurrentUser().email,
      year: this.selectedYear,
      month: this.selectedMonth
    }
    const itineraryCodes = [];
    for(let itinerary of this.uploadToCISItineraries){
      if(!!itinerary.checked){
        requestBody.data.push({
          itineraryCode: itinerary.itineraryNumber,
          status: itinerary.cis_status
        })
        itineraryCodes.push(itinerary.itineraryNumber);
      }
    }
    this.hhtApiService.uploadToCIS(requestBody)
                      .pipe(take(1))
                      .subscribe(uploadResult =>{
                        this.toastr.success('Your Itineraries have been uploaded to CMSv10 successfully!', 'Uploaded to CMSv10', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
                        this.uploadToCISIsLoading = false;
                        this.resetFlags();
                        this.modal.dismissAll();
                        this.isLoading = true;
                        const result = uploadResult['body'];
                        const alo047fileName = result['ALO047_filename'];
                        const alo050fileName = result['ALO050_filename'];
                        console.log("47",alo047fileName)
                        console.log("50",alo050fileName)
                        // ALO047 upload json file
                        const alo047Data = result['ALO047'];
                        // TODO once the endpoint is finalized
                        // TODO: change the `result` variable to `alo047Data` in alo047JsonFile
                        const alo047JsonFile = this.convertToJsonFile(result, "ALO047.json");
                        // TODO: change the API with the finalized endpoint
                        // this.hhtApiService.validateUploadToCISFile(alo047JsonFile).subscribe((res: any[])=>{
                        let requestBodyAlo047 = {
                          fileType: "alo047",
                          fileName: alo047fileName
                        }
                        this.hhtApiService.validateUploadToCISFile(requestBodyAlo047).subscribe((res: any[])=>{
                          console.log("alo047 response");
                          console.log(res['body']);
                        });
                        
                        // ALO050 upload json file
                        const alo050Data = result['ALO050'];
                        // TODO once the endpoint is finalized
                        // TODO: change the `result` variable to `alo050Data` in alo050JsonFile
                        const alo050JsonFile = this.convertToJsonFile(result, "ALO050.json");
                        // TODO: change the API with the finalized endpoint
                        // this.hhtApiService.validateUploadToCISFile(alo050JsonFile).subscribe((res: any[])=>{
                        let requestBodyAlo050 = {
                          fileType: "alo050",
                          fileName: alo050fileName
                        }
                        this.hhtApiService.validateUploadToCISFile(requestBodyAlo050).subscribe((res: any[])=>{
                          console.log("alo050 response");
                          console.log(res['body']);
                        });
                        //fetch al itineraries again, but consider making one seperate function for getting itineraries as its always being used in this component (best practice!)
                        this.hhtApiService.getAllItineraries(this.searchString, this.limit, this.offset, this.selectedYear == '-' ? '' : this.selectedYear, this.selectedMonth, this.userEmail, this.itineraryStatusFilter, this.sortKey, this.sortOrder).subscribe((res: any[])=>{    
                            this.itineraryArray = res['body']['itineraries'];
                            this.totalCount = res['body']['details'][0]['total_count'];
                            this.itineraryArray.forEach(item => {
                              item.checked = this.assignToRoverList.some(list => list.itineraryNumber == item.itineraryNumber)
                            });
                            // console.log('totalCount',this.totalCount)
                            // console.log('itineraryArray',this.itineraryArray)
                            this.isLoading = false;
                        });
                      },
                      error =>{
                        this.modal.dismissAll();
                        this.toastr.error(error['message'], 'Upload to CMSv10 Failed', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
                        this.uploadToCISIsLoading = false;
                      });
  }

  /* istanbul ignore next: to revisit */
  selectAllItineraries() { //Note that select all does not mean select all itineraries on the database. Only the ones sent by the API will be selected (max 10 rows)
    this.selectAllFlag = !this.selectAllFlag;
    this.itineraryArray.forEach(data =>{
      if(!!this.selectAllFlag){
        this.assignToRoverList.push(data);
      }else{
        this.assignToRoverList = this.assignToRoverList.filter(list => { return data.itineraryNumber != list.itineraryNumber });
      }
      data.checked = this.selectAllFlag;
    });
    console.log('selectall',this.assignToRoverList)
    // this.itineraryArray.forEach(data => { return data.checked = this.selectAllFlag });
    this.assignToRoverFlagFn();
  }

  /* istanbul ignore next: for validation only */
  isCheckedAll(): boolean{
    return this.itineraryArray.every(data => data.checked);
  }

  /* istanbul ignore next: for validation only */
  assignToRoverFormValidation(): boolean{
    // console.log('validate', this.assignToRoverList);
    // console.log('tufals', this.assignToRoverList.every(data => !!data.deviceCode));
    return this.assignToRoverList.every(data => !!data.deviceCode);
  }

  /* istanbul ignore next: to revisit */
  getSingleCheckedItinerary() {
    const checkedItineraries = this.itineraryArray.filter(data => { return data.checked });
    if (checkedItineraries.length === 1) {
      return checkedItineraries[0];
    } else {
      return null;
    }
  }

  /* istanbul ignore next: to revisit */
  getSingleCheckedReceivedItinerary() {
    const checkedItinerary = this.getSingleCheckedItinerary();
    if (checkedItinerary && checkedItinerary.status === "RECEIVED") {
      return checkedItinerary.itineraryNumber;
    } else {
      return null;
    }
  }

  /* istanbul ignore next: to revisit */
  assignToRoverMultiple(){
    this.assignToRoverList.forEach((value, key) => {
      this.assignToRoverList[key].deviceCode = this.selectedDevice;
   });
  }

  /* istanbul ignore next: to revisit */
  removeAssignToRoverMultiple(){
    this.selectedDevice = '';
    this.assignToRoverList.forEach((value, key) => {
      this.assignToRoverList[key].deviceCode = ''; //empty the inputted device code after cancelling
      //revert the default values of imageMRFF and imageHIGHLOW_0Consumption to its original (based from itineraries_v2)
      this.assignToRoverList[key].imageMRFF = this.assignToRoverList[key].imageMRFF_prev;
      this.assignToRoverList[key].imageHIGHLOW_0Consumption = this.assignToRoverList[key].imageHIGHLOW_0Consumption_prev;
   });
  }

  confirmModal(modalContent){
    //this.modal.dismissAll();
    this.modal.open(modalContent);
  }

  showItineraryPreview(itineraryData){
    const params = { 
      itineraryCode: itineraryData.itineraryNumber,
      split: itineraryData.split,
      email: this.userEmail, 
      year: this.selectedYear, 
      month: this.selectedMonth
    };
    this.itineraryPreviewIsLoading = true;
    this.previewOfItinerary$ = this.hhtApiService.previewOfItinerary(params)
            .subscribe({
            next: (itinerary) => {
              const result = itinerary['body']['results'];
              if(result.length == 1){
                this.itineraryPreviewData = result[0];
                this.lotsCollectionSize = this.itineraryPreviewData['lots'].length;
                this.itineraryPreviewData['itineraryCode'] = itineraryData.itineraryNumber;
                this.previewPageChange();
              }
              this.itineraryPreviewIsLoading = false;
            },
            error: /* istanbul ignore next */(error) =>{                  
              alert('Error in retrieving itinerary preview data.');
            }
            })
  }

  /* istanbul ignore next: to revisit */
  previewPageChange(){
    this.lotsDisplayData = this.itineraryPreviewData['lots']
                .map((elements, index) => ({
                  test: index + 1, ...elements
                }))
                .slice((this.lotsPage - 1) * this.limit, (this.lotsPage -1) * this.limit + this.limit);
    // console.log(this.lotsDisplayData);
  }
  
  printPreviewItinerary(){
    this.printService.printPreviewOfItinerary(this.itineraryPreviewData);
  }

  onChangeFilter(){
    this.isLoading = true;
    this.resetFlags();
    this.itineraryStatusFilter = this.tempStatusFilter;
    this.hhtApiService.getAllItineraries(this.searchString, this.limit, this.offset, this.selectedYear == '-' ? '' : this.selectedYear, this.selectedMonth, this.userEmail, this.itineraryStatusFilter, this.sortKey, this.sortOrder).subscribe((res: any[])=>{    
      this.itineraryArray = res['body']['itineraries'];
      this.totalCount = res['body']['details'][0]['total_count'];
      // console.log(this.totalCount)
      // console.log(this.itineraryArray)
      this.isLoading = false;
    }) 
  }
  
  /* istanbul ignore next: to revisit */
  openUploadToCISModal(modalContent) {
    this.uploadToCISItineraries = this.assignToRoverList.filter((data) => data.checked).map((data) => ({ ...data, cis_status: 'TRANSMITTED' }));
    //this.uploadToCISItineraries = this.itineraryArray.filter((data) => data.checked).map((data) => ({ ...data, cis_status: Number(data.completionPercent) === 100 ? "TRANSMITTED" : null }));
    //this.isUploadToCISButtonDisabled = true;
    //this.isUploadToCISButtonDisabled = this.assignToRoverList.some((data) => !data.cis_status);
    this.isUploadToCISButtonDisabled = this.uploadToCISItineraries.some((data) => !data.cis_status);
    this.uploadToCISIsLoading = false;
    this.modal.open(modalContent, { size: 'lg' });
  }

  /* istanbul ignore next: to revisit */
  changeUploadToCISStatus() {
    //this.isUploadToCISButtonDisabled = this.assignToRoverList.some((data) => !data.cis_status);
    this.isUploadToCISButtonDisabled = this.uploadToCISItineraries.some((data) => !data.cis_status);
  }

  /* istanbul ignore next: to revisit */
  convertToJsonFile(data, filename) {
    const jsonFile = new File([JSON.stringify(data)], filename, { type: "application/json" });
    return jsonFile;
  }

  /* istanbul ignore next: to revisit */
  convertMstsToTextFile(data, filename) {
    const results = data['results'];
    const date = data['date'];
    const time = data['time'];
    const space = (count) => { return ' '.repeat(count); };
    const modifiedData = results.map((result) => {
      let textHeader = `                       MERALCO-CUSTOMER MANAGEMENT SYSTEM\n`;
      textHeader += `USER : ${this.userEmail}${space(26 - this.userEmail.length > 0 ? 26 - this.userEmail.length : 1)}METER SEAL REPORT              DATE :${this.datePipe.transform(date, 'MM/d/yyyy')}\n`;
      textHeader += `                                                                TIME :${time}\n\n`;
      textHeader += `             READING CENTER  : ${result['readingCenterCode']}        THEORETICAL RDG DATE: ${this.datePipe.transform(result['theoreticalReadDate'], 'M/d/yyyy') || ''}\n`;
      textHeader += `             ROUTE           : ${result['route']}          ACTUAL RDG DATE     : ${this.datePipe.transform(result['readDate'], 'M/d/yyyy') || ''}\n`;
      textHeader += `             CYCLE           : ${result['cycle']}          READER CODE         : ${result['readerCode']}\n`;
      textHeader += `             ITINERARY NUMBER: ${result['itineraryCode'].substr(result['itineraryCode'].length - 4)}        HHT CODE            : ${result['device_code']}\n\n`;
      textHeader += `-------------------------------------------------------------------------------\n`;
      textHeader += `        ITEM NO.  INSTALLED SEAL  REMOVED SEAL  METER NUMBER  LOT ID NO.\n`;
      textHeader += `-------------------------------------------------------------------------------\n`;
      let table = [];
      result['lots'].forEach(lot => {
        lot['meteringPoints'].forEach(meteringPoint => {
          meteringPoint['meters'].forEach(meter => {
            if (meter['meterSeal'].length > 0) {
              meter['meterSeal'].forEach(seal => {
                const getItemNoMiddle = (5 - String(seal['item_no']).length) / 2;
                const getLotIdNoMiddle = (8 - String(lot['lot_id_number']).length) / 2;
                const getRemovedMiddle = (12 - String(seal['removed']).length);
                table.splice(seal['item_no'], 0, `${space(9 + Math.ceil(getItemNoMiddle))}${seal['item_no']}${space(5 + Math.floor(getItemNoMiddle))}${seal['installed']}   ${seal['removed']}${space(Math.floor(getRemovedMiddle))} ${meter['meterNo']}${space(4 + Math.floor(getLotIdNoMiddle))}${lot['lot_id_number']}\n`);
              });
            }
          });
        });
      });
      if (table.length > 0) {
        return textHeader + table.join('');
      } else {
        return '';
      }
    });
    const textFile = new File([modifiedData.join('')], filename, { type: "text/plain" });
    return textFile;
  }

  /* istanbul ignore next: for validation only */
  isSortedBy(col: string) {
    if (this.sortKey === col && this.sortOrder) {
      return this.sortOrder;
    } else {
      return null;
    }
  }

  /* istanbul ignore next: to revisit */
  onSortFn(col) {
    switch(this.sortOrder){
      case 'asc':
        this.sortOrder = 'desc';
      break;
      case 'desc':
        this.sortOrder = '';
      break;
      case '':
        this.sortOrder = 'asc';
      break;
    }
    if (this.sortKey !== col) {
      this.sortOrder = 'asc';
    }
    this.sortKey = col;
    this.isLoading = true;
    this.hhtApiService.getAllItineraries(this.searchString, this.limit, this.offset, this.selectedYear == '-' ? '' : this.selectedYear, this.selectedMonth, this.userEmail, this.itineraryStatusFilter, this.sortKey, this.sortOrder).subscribe((res: any[])=>{    
      this.itineraryArray = res['body']['itineraries'];
      this.itineraryArray.forEach(item => {
        item.checked = this.assignToRoverList.some(list => list.itineraryNumber == item.itineraryNumber)
      });
      this.selectAllFlag = this.isCheckedAll();
      this.isLoading = false;
    }) 
  }

  resetFlags(){
    this.assignToRoverFlag = false;
    this.assignToRoverSplitFlag = false;
    this.mergeFlag = false;
    this.uploadToCISFlag = false;
    this.itineraryPreviewFlag = false
    this.selectAllFlag = false;
    this.itineraryPreviewData = null;
    this.assignToRoverList = [];
    this.uploadToCISItineraries = [];
  }

  cancelUploadToCISModal(){
    this.modal.dismissAll();
    this.toastr.success('Itineraries have not been uploaded to CIS. No changes were saved', 'Unsaved Changes', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
  }

  /* istanbul ignore next: for validationOnly */
  noOfSubItnValidator(): boolean{
    return !this.noOfSubItn || (this.noOfSubItn > 1 && this.noOfSubItn < 6);
  }

  navigateToSplitItinerary(){
    console.log(this.assignToRoverList[0]);
    this.router.navigate(['hht/itinerary-list/split-itinerary', {id: this.assignToRoverList[0].id, noOfSubItn: this.noOfSubItn, itineraryCode: this.assignToRoverList[0].itineraryNumber}], {skipLocationChange: true});
    this.modal.dismissAll();
  }

  /* istanbul ignore next: to revisit */
  openMergeModal(modalContent){
    //validation for merging itineraries
    const percent = this.assignToRoverList.map(selected => selected.completionPercent);
    const isSplittedArray = this.assignToRoverList.map(selected => selected.isSplitted);
    const itnSet = new Set(this.assignToRoverList.map(selected => selected.itineraryNumber));
    const isAllSubItn = isSplittedArray.every(item => item == "1");
    const isAllSameItn = itnSet.size == 1;
    if(!isAllSubItn){ //if there's a selected regular cycle itinerary or merged itinerary
      this.toastr.error('The selected sub-itineraries should match the originally created sub-itineraries under the parent itinerary.', 'Cannot Merge', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
    }else if(isAllSubItn && !isAllSameItn){ //if there were selected sub itinerary that don't have the same parent itinerary
      this.toastr.error('The selected sub-itineraries should belong to the same parent itinerary.', 'Cannot Merge', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
    }else if((isAllSubItn && isAllSameItn) && this.assignToRoverList[0].noOfSplit != this.assignToRoverList.length){ //if the selected sub itineraries are not complete
      this.toastr.error('The selected sub-itineraries should match the originally created sub-itineraries under the parent itinerary.', 'Cannot Merge', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
    }else if((isAllSubItn && isAllSameItn) && (this.assignToRoverList[0].noOfSplit == this.assignToRoverList.length) && !(percent.every(item => parseInt(item) >= 85))){ //if the selected itineraries have 84 or below percent upload percentage each
      this.toastr.error('All the selected sub-itineraries must have at least 85% upload% (visited meters).', 'Cannot Merge', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
    }else{
      this.modal.open(modalContent);
    }
  }

  mergeSubItineraries(){
    const body = {
      id: this.assignToRoverList.map(/* istanbul ignore next */list => list.id)
    }
    this.isLoading = true;
    this.hhtApiService.mergeMeters(body).subscribe({
      next: () =>{
        this.isLoading = false;
        this.toastr.success('Merging of sub itineraries successful.', 'Merge Successful', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
        this.yearChanged(); //refresh the table.
      },
      error: /* istanbul ignore next */() => {
        this.isLoading = false;
        this.toastr.error('Something went wrong. Please try again later.', 'Error', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
      }
    })
  }

  remoteDownloadTemplateConfirmation(modalContent){
    this.modal.open(modalContent);
  }

  downloadRemote(){
    this.isLoading = true;
    const body = {
      itineraryNumber: this.assignToRoverList[0].itineraryNumber,
      month: this.selectedMonth,
      year: this.selectedYear,
      email: this.authService.getCurrentUser().email,
      id: this.assignToRoverList[0].id
    }
    this.hhtApiService.downloadRemote(body).subscribe({
      next: (result) => {
        const url = result['body']['results'];
        window.open(url, '_blank');
        this.isLoading = false;
        this.toastr.success('Remote Meter Reading file is successfully downloaded.', 'Download Successful', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
        this.yearChanged();
      },
      error: /* istanbul ignore next */() => {
        this.isLoading = false;
        this.toastr.error('Something went wrong. Please try again later.', 'Error', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
      }
    })
  }

  openUploadModal(modalContent, modalOpts?){
    this.modal.open(modalContent, modalOpts);
  }

  onFileUploadChange(event){
    console.log(event);
    this.remoteItnFile = event.target.files[0];
  }

  /* istanbul ignore next: to revisit also nested observable */
  uploadRemoteItn(){
    this.uploadRemoteLoading = true;
    this.uploadRemoteIsError = false;
    const presignedRequest = {
      itineraryNumber: this.assignToRoverList[0].itineraryNumber,
      month: this.selectedMonth,
      year: this.selectedYear,
      email: this.authService.getCurrentUser().email,
      id: this.assignToRoverList[0].id,
      filename: this.remoteItnFile.name
    }
    this.uploadRemoteStatus = "Preparing to upload file...";
    this.hhtApiService.getPresignedRemote(presignedRequest).subscribe({
      next: (data) => {
        const validateRequest = {
          itineraryNumber: this.assignToRoverList[0].itineraryNumber,
          email: this.authService.getCurrentUser().email,
          id: this.assignToRoverList[0].id,
          filename: data['body']['filename']
        }
        this.uploadRemoteStatus = 'Uploading file...';
        this.hhtApiService.uploadRemotePresigned(data['body']['results'], this.remoteItnFile).subscribe({
          next: (results) => {
            this.uploadRemoteStatus = 'Validating file...';
            this.hhtApiService.validateUploadedRemote(validateRequest).subscribe({
              next: (results) => {
                this.toastr.success(`Remote Itinerary file uploaded successfully!`, 'Upload Itinerary', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 })
                this.uploadRemoteLoading = false;
                this.uploadRemoteIsError = false;
                this.modal.dismissAll();
                this.yearChanged();
                this.uploadRemoteStatus = '';
                this.uploadRemoteErrorStack = [];
              },
              error: (err) => {
                this.uploadRemoteStatus = '';
                this.uploadRemoteErrorStack = err['error']['results'];
                this.modal.open(this.remoteUploadFailedMdl);
                this.uploadRemoteLoading = false;
                this.uploadRemoteIsError = true;
              }
            });
          },
          error: (err) =>{
            this.uploadRemoteStatus = err['error']['results'];
            this.uploadRemoteLoading = false;
            this.uploadRemoteIsError = true;
          }
        });
        
      },
      error: (err) =>{
        this.uploadRemoteStatus = err['error']['results'];
        this.uploadRemoteLoading = false;
        this.uploadRemoteIsError = true;
        console.log(err);
      }
    });
  }

}
