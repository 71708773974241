/* istanbul ignore file */
import { Injectable } from '@angular/core';
import { HHTProviderService } from './http/http-provider.service';
import { HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HhtApiService {


  //IMPORTANT import service on Providers list on your module.
  constructor(private httpProvider: HHTProviderService) { }

  public getAllItineraries(searchString: any, limit: number, offset: number, year: any, month: any, email: any, status: any, sort_key: any, sort_order: any) {
    let params = new HttpParams().set('search_field', searchString)
                                 .set('limit', limit)
                                 .set('offset', offset)
                                 .set('year', year)
                                 .set('month', month)
                                 .set('email', email)
                                 .set('status', status)
                                 .set('sort_key', sort_key)
                                 .set('sort_order', sort_order)
    //return this.httpProvider.sendGetRequest(`/get_all_itineraries?search_field=${searchString}&limit=${limit}&offset=${offset}&year=${year}&month=${month}`, new HttpHeaders, new HttpParams)
    return this.httpProvider.sendGetRequest(`/get_all_itineraries_v2`, new HttpHeaders, params)
  }

  public getItinerariesCIS(params) {
    let httpParams = new HttpParams().set('search_field', params.searchField)
                                     .set('limit', params.limit)
                                     .set('offset', params.offset)
                                     .set('device_code', params.deviceCode)
                                     .set('itinerary_no', params.itineraryNo)
                                     .set('readingCenterCode', params.readingCenterCode);
    return this.httpProvider.sendGetRequest(`/get_assign_itineraries`, new HttpHeaders, httpParams);
  }
  
  public getAllMeters(searchString: any, limit: number, offset: number, sort_by: any, asc_desc: any, email: any) {
    return this.httpProvider.sendGetRequest(`/get_all_meter?search_field=${searchString}&limit=${limit}&offset=${offset}&sort_by=${sort_by}&asc_desc=${asc_desc}&email=${email}`, new HttpHeaders, new HttpParams)
  }

  public getAllGeolocationHistory(meterNumber: any, limit: number, offset: number, year: any, month: any, sort_by: any, asc_desc: any, email:any) {
    return this.httpProvider.sendGetRequest(`/get_meter_location_history?meter_number=${meterNumber}&limit=${limit}&offset=${offset}&year=${year}&month=${month}&sort_by=${sort_by}&asc_desc=${asc_desc}&email=${email}`, new HttpHeaders, new HttpParams)
  }

  public getAllImageCaptureHistory(meterNumber: any, limit: number, offset: number, year: any, month: any, sort_by: any, asc_desc: any, email:any) {
    return this.httpProvider.sendGetRequest(`/get_image_capture_history?meter_number=${meterNumber}&limit=${limit}&offset=${offset}&year=${year}&month=${month}&sort_by=${sort_by}&asc_desc=${asc_desc}&email=${email}`, new HttpHeaders, new HttpParams)
  }

  // public getItineraryById(itineraryNumber:any, readings:any, device_code:any, center_code:any){
  //   return this.httpProvider.sendGetRequest(`/get_itineraries_by_id?itineraryNumber=${itineraryNumber}&readings=${readings}&device_code=${device_code}&center_code=${center_code}`, new HttpHeaders, new HttpParams);
  // }

  public getItineraryById(params){
    let httpParams = new HttpParams()
                          .set('itineraryNumber', params.itineraryNumber)
                          .set('readings', params.readings)
                          .set('device_code', params.device_code)
                          .set('center_code', params.center_code)
                          .set('data_filter', params.data_filter)
                          .set('month', params.month)
                          .set('year', params.year)
                          .set('email', params.email)
                          .set('isSplitted', params.isSplitted)
                          .set('split', params.split)
                          .set('id', params.id)
    return this.httpProvider.sendGetRequest(`/get_itineraries_by_id`, new HttpHeaders, httpParams);
  }

  public getImage(meter_number:any, consumption_type:any, actual_reading_date:any){
    return this.httpProvider.sendGetRequest(`/get_file_presigned_url?meter_number=${meter_number}&consumption_type=${consumption_type}&actual_reading_date=${actual_reading_date}`, new HttpHeaders, new HttpParams);
  }
  
  public updateMeterLocation(body:any){
    return this.httpProvider.sendPostRequest('/update_meter_location', body, new HttpHeaders, new HttpParams);
  }

  public getUserAccountInfo(user_name:any){
    return this.httpProvider.sendGetRequest(`/get_user_account_Info?user_name=${user_name}`, new HttpHeaders, new HttpParams);
  }

  public validateInsertPassword(body:any){
    return this.httpProvider.sendPostRequest('/validate_Insert_Password', body, new HttpHeaders, new HttpParams);
  }

  public deleteSession(body:any){
    return this.httpProvider.sendPostRequest(`/delete_session`,body, new HttpHeaders, new HttpParams);
  }

  public cognitoCognitoChangePassword(userName:any){
    return this.httpProvider.sendGetRequest(`/cognito_forgot_password?userName=${userName}`, new HttpHeaders, new HttpParams);
  }

  public cognitoCognitoConfirmChangePassword(userName:any, confirmationCode:any, password:any, prevPassword: any){
    return this.httpProvider.sendGetRequest(`/cognito_confirm_forgot_password?userName=${userName}&confirmationCode=${confirmationCode}&password=${password}&password=${prevPassword}`, new HttpHeaders, new HttpParams);
  }

  public cognitoChangePassword(userName:any,prevPassword:any,newPassword:any){
    return this.httpProvider.sendGetRequest(`/cognito_change_password?userName=${userName}&prevPassword=${prevPassword}&newPassword=${newPassword}`, new HttpHeaders, new HttpParams);
  }

  public uploadAlo030(body: any){
    return this.httpProvider.sendPostRequest(`/insert_ALO30`,body, new HttpHeaders, new HttpParams);
  }

  public getMeterFFCodes(params){
    let httpParams = new HttpParams().set('code', params.code)
                                     .set('serviceType', !!params.serviceType ? params.serviceType : '');
    return this.httpProvider.sendGetRequest(`/get_meterFFCodes`, new HttpHeaders, httpParams);
  }

  public getLotFFCodes(params){
    let httpParams = new HttpParams().set('code', params.code);
    return this.httpProvider.sendGetRequest(`/get_lotFFCodes`, new HttpHeaders, httpParams);
  }

  public getMpFFCodes(params){
    let httpParams = new HttpParams().set('code', params.code);
    return this.httpProvider.sendGetRequest(`/get_mpFFCodes`, new HttpHeaders, httpParams);
  }

  public getReaderFFCodes(params){
    let httpParams = new HttpParams()
                          .set('email', params.email)
                          .set('userType', params.userType);
    return this.httpProvider.sendGetRequest(`/get_readerCodes`, new HttpHeaders, httpParams);
  }

  public ffCodeCrud(body: any){
    return this.httpProvider.sendPostRequest(`/crud_FFcodes`,body, new HttpHeaders, new HttpParams);
  }

  public downloadAlo040(deviceCode, readingCenterCode) {
    let params = new HttpParams()
                      .set('device_code', deviceCode)
                      .set('readingCenterCode', readingCenterCode);
    return this.httpProvider.sendGetRequest(`/get_ALO040_data`, new HttpHeaders, params);
  }

  public downloadAlo045(deviceCode, readingCenterCode) {
    let params = new HttpParams()
                      .set('device_code', deviceCode)
                      .set('readingCenterCode', readingCenterCode);
    return this.httpProvider.sendGetRequest(`/get_ALO045_data`, new HttpHeaders, params);
  }

  public downloadMsd010(deviceCode, readingCenterCode) {
    let params = new HttpParams()
                      .set('device_code', deviceCode)
                      .set('readingCenterCode', readingCenterCode);
    return this.httpProvider.sendGetRequest(`/get_MSD010_data`, new HttpHeaders, params);
  }

  public getLots(params) {
    let httpParams = new HttpParams().set('search_field', params.searchField)
                                     .set('limit', params.limit)
                                     .set('offset', params.offset)
                                     .set('device_code', params.deviceCode)
                                     .set('itinerary_no', params.itineraryNo)
                                     .set('readingCenterCode', params.readingCenterCode);
    return this.httpProvider.sendGetRequest(`/get_assign_itineraries_lots`, new HttpHeaders, httpParams);
  }

  public getMeteringPoints(params) {
    let httpParams = new HttpParams().set('search_field', params.searchField)
                                     .set('limit', params.limit)
                                     .set('offset', params.offset)
                                     .set('device_code', params.deviceCode)
                                     .set('lotId', params.lotId)
                                     .set('readingCenterCode', params.readingCenterCode);
    return this.httpProvider.sendGetRequest(`/get_assign_itineraries_meteringPoints`, new HttpHeaders, httpParams);
  }

  public getMeters(params) {
    let httpParams = new HttpParams().set('search_field', params.searchField)
                                     .set('limit', params.limit)
                                     .set('offset', params.offset)
                                     .set('device_code', params.deviceCode)
                                     .set('meteringPointId', params.meteringPointId)
                                     .set('readingCenterCode', params.readingCenterCode);
    return this.httpProvider.sendGetRequest(`/get_assign_itineraries_meters`, new HttpHeaders, httpParams);
  }

  public getReadingCenterCodes(gp_nongp) {
    let httpParams = new HttpParams().set('GP/NON-GP', gp_nongp);
    return this.httpProvider.sendGetRequest(`/get_readingCenterCodes`, new HttpHeaders, httpParams);
  }

  public userMaintenanceCrud(body){
    return this.httpProvider.sendPostRequest(`/crud_readerCodes`, body, new HttpHeaders, new HttpParams);
  }

  public getDeviceCodes(status, device_code?){
    let httpParams = new HttpParams().set('status', status)
                                     .set('device_code', !!device_code ? device_code : '');
    return this.httpProvider.sendGetRequest(`/get_deviceCodes`, new HttpHeaders, httpParams);
  }

  public crudDeviceCodes(body){
    return this.httpProvider.sendPostRequest(`/crud_deviceCodes`, body, new HttpHeaders, new HttpParams);
  }

  public assignToRover(body){
    return this.httpProvider.sendPostRequest(`/assign_itineraries_to_device`, body, new HttpHeaders, new HttpParams);
  }

  public getConsumptionType(params){
    let httpParams = new HttpParams().set('Type_Cons', params.Type_Cons)
    return this.httpProvider.sendGetRequest(`/get_consumptionType`, new HttpHeaders, httpParams);
  }

  public crudConsumptionType(body){
    return this.httpProvider.sendPostRequest(`/crud_consumptionType`, body, new HttpHeaders, new HttpParams);
  }

  public getReadingCenters(code){
    let httpParams = new HttpParams().set('code', code)
    return this.httpProvider.sendGetRequest(`/get_readingCenter`, new HttpHeaders, httpParams);
  }
  
  public uploadToCIS(body){
    return this.httpProvider.sendPostRequest(`/export_data_to_CIS`, body, new HttpHeaders, new HttpParams);
  }

  public crudReadingCenters(body){
    return this.httpProvider.sendPostRequest(`/crud_readingCenter`, body, new HttpHeaders, new HttpParams);
  }
  
  // public getInquiryMapPortal(limit: number, offset: number, searchBy: any, searches: any, year: any, month: any) {
  //   return this.httpProvider.sendGetRequest(`/get_all_meter?search_field=&limit=${limit}&offset=${offset}`, new HttpHeaders, new HttpParams)
  // }

  public getAWSCredentials(env: any) {
    return this.httpProvider.sendGetRequest(`/get_aws_credentials?env=${env}`, new HttpHeaders, new HttpParams)
  }

  public getSplitItineraryMeters(itineraryNumber: any, limit: number, offset: number) {
    return this.httpProvider.sendGetRequest(`/get_meters?itineraryCode${itineraryNumber}=&limit=${limit}&offset=${offset}`, new HttpHeaders, new HttpParams)
  }

  public printListOfItineraries(params) {
    let httpParams = new HttpParams().set('route', params.route)
                                     .set('itineraryCode', params.itineraryCode);
    return this.httpProvider.sendGetRequest(`/listing_of_itineraries`, new HttpHeaders, httpParams);
  }

  public printAccomplishmentReports(params) {
    let httpParams = new HttpParams().set('itineraryCode', params.itineraryCode)
                                     .set('route', params.route)
                                     .set('date', params.date);
    return this.httpProvider.sendGetRequest(`/accomplishment_report`, new HttpHeaders, httpParams);
  }

  public printSummaryAccomplishmentReports(params) {
    let httpParams = new HttpParams().set('readingCenterCode', params.readingCenterCode)
                                     .set('date', params.date);
    return this.httpProvider.sendGetRequest(`/summary_of_accomplishment_readings`, new HttpHeaders, httpParams);
  }

  public getRoutes(route, email?, status?) {
    let httpParams = new HttpParams().set('route', route)
                                     .set('email', !!email ? email : '')
                                     .set('status', !!status ? status : '');
    return this.httpProvider.sendGetRequest(`/get_routes`, new HttpHeaders, httpParams)
  }

  public printItineraryRemarksReport(params) {
    let httpParams = new HttpParams().set('itineraryCode', params.itineraryCode)
                                     .set('date_from', params.date_from)
                                     .set('date_to', params.date_to);
    return this.httpProvider.sendGetRequest(`/remarks_inputed_to_itineraries`, new HttpHeaders, httpParams)
  }

  public previouslyAndOrCurrentlyAcceptedItinerariesReport(params) {
    let httpParams = new HttpParams().set('readingCenterCode', params.readingCenterCode);
    return this.httpProvider.sendGetRequest(`/previously_currently_accepted_itineraries`, new HttpHeaders, httpParams)
  }

  public dailyAssignmentOfItinerariesReport(params) {
    let httpParams = new HttpParams().set('readingCenterCode', params.readingCenterCode)
                                     .set('route', params.route)
                                     .set('cycle', params.cycle);
    return this.httpProvider.sendGetRequest(`/daily_assign_of_itineraries`, new HttpHeaders, httpParams);
  }

  public previewOfItinerary(params) {
    let httpParams = new HttpParams().set('itineraryCode', params.itineraryCode)
                                     .set('split', params.split)
                                     .set('email', params.email)
                                     .set('year', params.year)
                                     .set('month', params.month);
    return this.httpProvider.sendGetRequest(`/preview_of_itineraries`, new HttpHeaders, httpParams);
  }
  
  public printMetersWithFieldFindingsReports(params) {
    let httpParams = new HttpParams().set('itineraryCode', params.itineraryCode)
                                     .set('route', params.route)
                                     .set('date_from', params.date_from)
                                     .set('date_to', params.date_to);
    return this.httpProvider.sendGetRequest(`/meter_with_FF`, new HttpHeaders, httpParams);
  }

  public printRemovedAndInstalledMeterSealsReports(params) {
    let httpParams = new HttpParams().set('itineraryCode', params.itineraryCode)
                                     .set('route', params.route)
                                     .set('date_from', params.date_from)
                                     .set('date_to', params.date_to);
    return this.httpProvider.sendGetRequest(`/removed_installed_meter_seal`, new HttpHeaders, httpParams);
  }

  public printHandlingOfItinerariesReports(params) {
    let httpParams = new HttpParams().set('readingCenterCode', params.readingCenterCode);
    return this.httpProvider.sendGetRequest(`/handling_of_itineraries`, new HttpHeaders, httpParams);
  }

  public printAdditionOfItinerariesReports(params) {
    let httpParams = new HttpParams().set('readingCenterCode', params.readingCenterCode)
                                     .set('month', params.month)
                                     .set('year', params.year);
    return this.httpProvider.sendGetRequest(`/addition_of_itineraries`, new HttpHeaders, httpParams);
  }

  public getItinerariesByStatusOrCode(params){
    let httpParams = new HttpParams().set('itineraryCode', params.itineraryCode)
                                     .set('status', params.status)
                                     .set('email', params.email)
                                     .set('route', !!params.route ? params.route : '');
    return this.httpProvider.sendGetRequest(`/get_all_itineraries_for_reports`, new HttpHeaders, httpParams);
  }

  public getReaderCodesbyEmail(email: any) {
    return this.httpProvider.sendGetRequest(`/get_readingCenterCodebyEmail?email=${email}`, new HttpHeaders, new HttpParams)
  }

  public saveReadingCenterByEmail(body){
    return this.httpProvider.sendPostRequest(`/save_readingCenterbyEmail`, body, new HttpHeaders, new HttpParams);
  }

  public validateUploadToCISFile(body){
    return this.httpProvider.sendPostRequest(`/validate_upload_to_CIS_file`, body, new HttpHeaders, new HttpParams);
  }

  public getMSTS(itineraryCode: any) {
    return this.httpProvider.sendGetRequest(`/msts?itineraryCode=${itineraryCode}`, new HttpHeaders, new HttpParams);
  }

  public crudRoutes(body){
    return this.httpProvider.sendPostRequest(`/crud_routes`, body, new HttpHeaders, new HttpParams);
  }

  public getUserLoginInfo(email: any) {
    return this.httpProvider.sendGetRequest(`/get_user_login_info?email=${email}`, new HttpHeaders, new HttpParams);
  }

  public generateMPIN(body){
    return this.httpProvider.sendPostRequest(`/generate_MPIN`, body, new HttpHeaders, new HttpParams);
  }

  public exportMSTSreport(params){
    let httpParams = new HttpParams().set('email', params.email);

    return this.httpProvider.sendGetRequest(`/export_msts_report`, new HttpHeaders,  httpParams);
  } 
  //https://4tffqdueif.execute-api.ap-southeast-1.amazonaws.com/dev/update_websession_token

  public exportMSTSreportBlob(body){
    return this.httpProvider.sendPostRequest(`/export_msts_report_blob`, body, new HttpHeaders, new HttpParams);
  }


  public update_websession_token(body){
    return this.httpProvider.sendPostRequest(`/update_websession_token`, body, new HttpHeaders, new HttpParams);
  }

  public get_websession_token(email: any){
    return this.httpProvider.sendGetRequest(`/get_websession_token?username=${email}` ,new HttpHeaders, new HttpParams);
  }

  public get_splitMeters(id: number) {
    let httpParams = new HttpParams().set('id', id);
    return this.httpProvider.sendGetRequest(`/get_splitMeters` ,new HttpHeaders, httpParams);
  }

  public splitMeters(body){
    return this.httpProvider.sendPostRequest(`/update_splitMeters`, body, new HttpHeaders, new HttpParams);
  }

  public mergeMeters(body){
    return this.httpProvider.sendPostRequest(`/update_mergeMeters`, body, new HttpHeaders, new HttpParams);
  }

  public downloadRemote(body){
    return this.httpProvider.sendPostRequest(`/get_remotelyReadMeters`, body, new HttpHeaders, new HttpParams);
  }

  public getPresignedRemote(body){
    return this.httpProvider.sendPostRequest(`/presignedUrl_remotelyReadMeters`, body, new HttpHeaders, new HttpParams);
  }

  public uploadRemotePresigned(url, body){
    return this.httpProvider.sendPutRequest(url, body, new HttpHeaders, new HttpParams);
  }

  public validateUploadedRemote(body){
    return this.httpProvider.sendPostRequest(`/upload_remotelyReadMeters`, body, new HttpHeaders, new HttpParams);
  }

  public updateRemoteMeters(body){
    return this.httpProvider.sendPostRequest(`/update_remotelyReadMeters`, body, new HttpHeaders, new HttpParams);
  }

  public getInquiryMapPortal(params){
    const httpParams = new HttpParams().set('readingCenter', params.readingCenter)
                                       .set('route', params.route)
                                       .set('itineraryNumber', params.itineraryNumber)
                                       .set('email', params.email);
    return this.httpProvider.sendGetRequest(`/get_inquiryPortalMeters`, new HttpHeaders, httpParams);
  }
}