import { Inject, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HhtApiService } from '@shared/services/hht-api.service';
import { AuthService } from '@shared/services/guards/auth.service';
import pdfMake from "pdfmake/build/pdfmake";  
import pdfFonts from "@assets/fonts/helvetica-vfs";  
pdfMake.vfs = pdfFonts.pdfMake.vfs;  

pdfMake.fonts = {
  HelveticaNeue: {
    normal: 'Helvetica65Medium_22443.woff',
    bold: 'HelveticaNeueBold.woff',
    italics: 'HelveticaNeueBlackItalic 1.woff',
    bolditalics: 'Helvetica 76 Bold Italic.woff'
  }
}

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  isGenerating: boolean = false;

  constructor(private datePipe: DatePipe,
              private hhtApiService: HhtApiService,
              private authService: AuthService) { 
                
              }

  getUser(): any{
    return this.authService.getCurrentUser();
  }

  // printListingOfItineraries(params){
  //   this.isGenerating = true;
  //   this.hhtApiService.printListOfItineraries(params).subscribe(data =>{
  //     console.log(data['body']['itineraries'])
  //     let result = data['body']['itineraries'];
  //     let date = data['body']['date'];
  //     let time = data['body']['time'];
  //     let parent: Array<any> = [];
  //     result.forEach(itn=>{
  //       parent.push(
  //         {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 794, y2: 5, lineWidth: 2 }]},
  //         { text: '\n' }, //newline
  //         {
  //           columns: [
  //             {
  //               text: `Reading Center:`,
  //               bold: true,
  //               width: 110
  //             },
  //             {
  //               text: ` ${itn['readingCenterCode']}`
  //             },
  //             {
  //               text: `Route:`,
  //               bold: true,
  //               width: 70
  //             },
  //             {
  //               text: `${itn['route']}`
  //             },
  //             {
  //               text: `Reader Code:`,
  //               bold: true,
  //               width: 170
  //             },
  //             {
  //               text: `${itn['readerCode']}`
  //             }
  //           ]
  //         },
  //         {
  //           columns: [
  //             {
  //               text: ``,
  //               bold: true,
  //               width: 110
  //             },
  //             {
  //               text: ``
  //             },
  //             {
  //               text: `Cycle:`,
  //               bold: true,
  //               width: 70
  //             },
  //             {
  //               text: `${itn['cycle']}`
  //             },
  //             {
  //               text: `Theoretical Reading Date:`,
  //               bold: true,
  //               width: 170
  //             },
  //             {
  //               text: `${this.datePipe.transform(itn['theoreticalReadDate'], 'M/d/yy') || ''}`
  //             }
  //           ]
  //         },
  //         {
  //           columns: [
  //             {
  //               text: ``,
  //               bold: true,
  //               width: 110
  //             },
  //             {
  //               text: ``
  //             },
  //             {
  //               text: `Itin.No.:`,
  //               bold: true,
  //               width: 70
  //             },
  //             {
  //               text: `${itn['itineraryNo']}`
  //             },
  //             {
  //               text: `Actual Reading Date:`,
  //               bold: true,
  //               width: 170
  //             },
  //             {
  //               text: `${this.datePipe.transform(itn['readDate'], 'M/d/yy') || ''}`
  //             }
  //           ]
  //         }
  //       );
  //       itn['lots'].forEach(lots=>{
  //         parent.push(
  //           {
  //             columns: [
  //               {
  //                 text: `Province:`,
  //                 bold: true,
  //                 width: 110
  //               },
  //               {
  //                 text: ` ${lots['province']}`
  //               }
  //             ]
  //           },
  //           {
  //             columns: [
  //               {
  //                 text: `Mun./City:`,
  //                 bold: true,
  //                 width: 110
  //               },
  //               {
  //                 text: ` ${lots['municipality']}`
  //               }
  //             ]
  //           },
  //           {
  //             columns: [
  //               {
  //                 text: `Barangay:`,
  //                 bold: true,
  //                 width: 110
  //               },
  //               {
  //                 text: ` ${lots['barangay']}`
  //               }
  //             ]
  //           },
  //           {
  //             columns: [
  //               {
  //                 text: `Subd./CMPD.:`,
  //                 bold: true,
  //                 width: 110
  //               },
  //               {
  //                 text: ` ${lots['subdivision']}`
  //               }
  //             ]
  //           },
  //           { text: '\n' }, //newline
  //           {canvas: [{ type: 'line', x1: 0, y1: 2, x2: 794, y2: 2, lineWidth: 2, dash: {length: 15, space: 0} }]},
  //           { text: '\n' }, //newline
  //           );
  //         let mtrs: Array<any> = [
  //           [
  //             {text: '', rowSpan: 2},
  //             {text: 'Customer Name', rowSpan: 2, alignment: 'center', bold: true},
  //             {text: 'Service', rowSpan: 2, alignment: 'center', bold: true},
  //             {text: 'Meter Number', rowSpan: 2, alignment: 'center', bold: true},
  //             {text: 'Meter Brand', rowSpan: 2, alignment: 'center', bold: true},
  //             {text: 'Cons. Type', rowSpan: 2, alignment: 'center', bold: true},
  //             {text: 'Reading', colSpan: 2, alignment: 'center', bold: true},
  //             {},
  //             {text: 'Field Finding', colSpan: 2, alignment: 'center', bold: true},
  //             {}
  //           ],
  //           [
  //             {},{},{},{},{},{},
  //             {text: 'Previous', alignment: 'center', bold: true},
  //             {text: 'Present', alignment: 'center', bold: true},
  //             {text: 'Code', alignment: 'center', bold: true},
  //             {text: 'Remarks', alignment: 'center', bold: true}
  //           ],
  //           [
  //             {text: 'Lot'},
  //             {text: lots['street']},
  //             {text: lots['houseNo']},
  //             {},{},{},{},{},{},{}
  //           ],
  //           [
  //             {text: 'Metering Pt.'},
  //             {},{},{},{},{},{},{},{},{}
  //           ]
  //         ];
  //         lots['meteringPoints'].forEach(mp=>{
  //           mp['meters'].forEach(meters=>{
  //             mtrs.push([
  //               {},
  //               {text: mp['clientName']},
  //               {},
  //               {text: meters['meterNo']},
  //               {text: meters['meterBrand']},
  //               {text: meters['consumptionType']},
  //               {text: meters['previousReading']},
  //               {text: meters['presentReading']},
  //               {text: meters['fieldFindingCode']},
  //               {text: meters['fieldFindingRemarks']}
  //             ])
  //           })
  //         })
  //         parent.push(
  //           {
  //             columns: [
  //               {width: '*', text: ''},
  //               {
  //                 table: {
  //                   headerRows: 2,
  //                   widths:['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
  //                   body: mtrs
  //                 },
  //                 width: 'auto'
  //               },
  //               {width: '*', text: ''}
  //             ]
  //           },
  //           {//empty space new line
  //             text: '\n'
  //           }
  //         )
  //       })
  //     });

  //     let docDef = {
  //       pageOrientation: 'landscape',
  //       pageSize: 'LEGAL',
  //       pageMargins: [96, 96, 96, 96],
  //       defaultStyle: {
  //         fontSize: 12,
  //         font: 'HelveticaNeue'
  //       },
  //       header: (currentPage) => {
  //         return [
  //           {
  //             columns: [
  //               {
  //                 text: 'Meralco - Customer Management System',
  //                 alignment: 'center',
  //                 bold: true
  //               }
  //             ],
  //             marginTop: 30
  //           },
  //           {
  //             columns: [
  //               {
  //                 text: 'Listing of Itineraries for Manual Reading',
  //                 alignment: 'center',
  //                 bold: true
  //               }
  //             ]
  //           },
  //           {text: '\n'}, //new line
  //           {
  //             columns: [
  //               {
  //                 text: [{text: 'User:', bold: true}, `${this.getUser()['email']}`]
  //               },
  //               {
  //                 text: [{text: 'Date:', bold: true}, ` ${this.datePipe.transform(date, 'M/d/yy')}`]
  //               },
  //               {
  //                 text: [{text: 'Time:', bold: true}, ` ${time}`]
  //               },
  //               {
  //                 text: [{text: 'Page:', bold: true}, ` ${currentPage}`]
  //               },
  //             ],
  //             marginLeft: 96
  //           },
  //           {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 890, y2: 5, lineWidth: 2 }]}
  //         ]
  //       },
  //       content: parent
  //     }

  //     pdfMake.createPdf(docDef).open();
  //     this.isGenerating = false;
  //   })
  // }

  printListingOfItineraries(params) {
    this.isGenerating = true;
    this.hhtApiService.printListOfItineraries(params).subscribe(data => {
        console.log(data['body']['itineraries']);
        let result = data['body']['itineraries'];
        let date = data['body']['date'];
        let time = data['body']['time'];
        let parent: Array<any> = [];
        
        // Initialize CSV and text content
        let csvContent = 'Reading Center,Route,Reader Code,Cycle,Theoretical Reading Date,Itin.No.,Actual Reading Date,Province,Mun./City,Barangay,Subd./CMPD.,Customer Name,Service,Meter Number,Meter Brand,Cons. Type,Previous Reading,Present Reading,Field Finding Code,Field Finding Remarks\n';
        let textContent = `Listing of Itineraries for Manual Reading\n\n`;
        textContent += `Date: ${this.datePipe.transform(date, 'M/d/yy')}\nTime: ${time}\n\n`;

        result.forEach(itn => {
       
            let itineraryCsv = `${itn['readingCenterCode']},${itn['route']},${itn['readerCode']},${itn['cycle']},${this.datePipe.transform(itn['theoreticalReadDate'], 'M/d/yy') || ''},${itn['itineraryNo']},${this.datePipe.transform(itn['readDate'], 'M/d/yy') || ''}\n`;

            itn['lots'].forEach(lots => {
 
                let lotCsv = `${lots['province']},${lots['municipality']},${lots['barangay']},${lots['subdivision']}\n`;

                lots['meteringPoints'].forEach(mp => {
                    mp['meters'].forEach(meters => {
                  
                        // Append meter details to CSV and text content
                        let meterCsv = `${mp['clientName']},${meters['meterNo']},${meters['meterBrand']},${meters['consumptionType']},${meters['previousReading']},${meters['presentReading']},${meters['fieldFindingCode']},${meters['fieldFindingRemarks']}\n`;
                        csvContent += itineraryCsv + lotCsv + meterCsv;

                        textContent += `Customer Name: ${mp['clientName']}\n`;
                        textContent += `Service: ${meters['service']}\n`;
                        textContent += `Meter Number: ${meters['meterNo']}\n`;
                        textContent += `Meter Brand: ${meters['meterBrand']}\n`;
                        textContent += `Cons. Type: ${meters['consumptionType']}\n`;
                        textContent += `Previous Reading: ${meters['previousReading']}\n`;
                        textContent += `Present Reading: ${meters['presentReading']}\n`;
                        textContent += `Field Finding Code: ${meters['fieldFindingCode']}\n`;
                        textContent += `Field Finding Remarks: ${meters['fieldFindingRemarks']}\n`;
                        textContent += '\n';
                    });
                });

            });
        });

        if(params.fileType && params.fileType === "csv"){
            // CSV Generation
            console.log('csv');
            this.downloadCSV(csvContent,"ListingOfItineraries");
        }
        else{
            // Text Generation
            console.log('txt');
            this.downloadTextFile(textContent,"ListingOfItineraries");
        }

        this.isGenerating = false;
    });
  }

  
  // printAccomplishmentReports(params){
  //   this.isGenerating = true;
  //   let parent: Array<any> = [];
  //   this.hhtApiService.printAccomplishmentReports(params).subscribe(data =>{
  //     let result = data['body']['results'];
  //     let date = data['body']['date'];
  //     let time = data['body']['time'];
  //     console.log(result);
  //     result.forEach(itn => {
  //       parent.push(
  //       {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 815, y2: 5, lineWidth: 2 }]},
  //       {text: '\n'}, //new line
  //       {
  //         columns: [
  //           {text: 'Reading Center:', bold: true},
  //           {text: `${itn['readingCenterCode']}`},
  //           {text: 'Theoretical Rdg Date:', bold: true},
  //           {text: `${this.datePipe.transform(itn['theoreticalReadDate'], 'M/d/yy') || ''}`}
  //         ]
  //       },
  //       {
  //         columns: [
  //           {text: 'Route:', bold: true},
  //           {text: `${itn['route']}`},
  //           {text: 'Actual Reading Date:', bold: true},
  //           {text: `${this.datePipe.transform(itn['readDate'], 'M/d/yy') || ''}`}
  //         ]
  //       },
  //       {
  //         columns: [
  //           {text: 'Cycle:', bold: true},
  //           {text: `${itn['cycle']}`},
  //           {text: 'Reader Code:', bold: true},
  //           {text: `${itn['readerCode']}`}
  //         ]
  //       },
  //       {
  //         columns: [
  //           {text: 'Itinerary Number:', bold: true},
  //           {text: `${itn['itineraryNo']}`},
  //           {text: 'HHT Code:', bold: true},
  //           {text: `${itn['device_code']}`}
  //         ]
  //       },
  //       {text: '\n'}, //newline
  //       {canvas: [{ type: 'line', x1: 0, y1: 2, x2: 815, y2: 2, lineWidth: 2, dash: {length: 15, space: 0} }]},
  //       {text: '\n'}
  //       );
  //       let mtrs: Array<any> = [
  //         [
  //           {text: 'Lot Address', alignment: 'center', bold: true},
  //           {text: 'Client Name', alignment: 'center', bold: true},
  //           {text: 'Meter No.', alignment: 'center', bold: true},
  //           {text: 'Type', alignment: 'center', bold: true},
  //           {text: 'Previous Rdg.', alignment: 'center', bold: true},
  //           {text: 'Rdg.', alignment: 'center', bold: true},
  //           {text: 'Time', alignment: 'center', bold: true},
  //           {text: 'RT', alignment: 'center', bold: true},
  //           {text: 'Mtr FF/Remark', alignment: 'center', bold: true, colSpan: 3},
  //           {},
  //           {}
  //         ]
  //       ];
  //       itn['lots'].forEach(lots =>{
  //         let mpFirstIndex = 0;
  //         lots['meteringPoints'].forEach(mp =>{
  //           let meterFirstIndex = 0;
  //             mp['meters'].forEach(meter =>{
  //               mtrs.push(
  //                 [
  //                   {text: mpFirstIndex == 0 ? `${lots['houseNo']} ${lots['street']}` : ''},
  //                   {text: meterFirstIndex == 0 ? `${mp['clientName']}` : ''},
  //                   {text: `${meter['meterNo']}`},
  //                   {text: `${meter['consumptionType']}`},
  //                   {text: `${meter['previousReading']}`},
  //                   {text: `${meter['presentReading']}`},
  //                   {text: `${meter['readTime']}`},
  //                   {text: `${meter['RT']}`},
  //                   {text: `${meter['fieldFindingCode']} - ${meter['fieldFindingRemarks']}`},
  //                   {text: `${meter['fieldFindingCode_2']} - ${meter['fieldFindingRemarks_2']}`},
  //                   {text: `${meter['fieldFindingCode_3']} - ${meter['fieldFindingRemarks_3']}`}
  //                 ]
  //               )
  //               mpFirstIndex++;
  //               meterFirstIndex++;
  //             })
  //         })
  //       });
  //       parent.push(
  //         {
  //           columns: [
  //             {width: '*', text: ''},
  //             {
  //               table: {
  //                 headerRows: 1,
  //                 widths:['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
  //                 body: mtrs
  //               },
  //               width: 'auto'
  //             },
  //             {width: '*', text: ''}
  //           ]
  //         })
  //     })
  //     let docDef = {
  //       pageOrientation: 'landscape',
  //       pageSize: 'LEGAL',
  //       pageMargins: [96, 96, 96, 96],
  //       defaultStyle: {
  //         fontSize: 12,
  //         font: 'HelveticaNeue'
  //       },
  //       header: (currentPage) => {
  //         return [
  //           {
  //             columns: [
  //               {
  //                 text: 'Meralco - Customer Management System',
  //                 alignment: 'center',
  //                 bold: true
  //               }
  //             ],
  //             marginTop: 30
  //           },
  //           {
  //             columns: [
  //               {
  //                 text: 'Listing on Accomplished Readings',
  //                 alignment: 'center',
  //                 bold: true
  //               }
  //             ]
  //           },
  //           {text: '\n'}, //new line
  //           {
  //             columns: [
  //               {text: 'Date:', bold: true},
  //               {text: `${this.datePipe.transform(date, 'M/d/yy')}`},
  //               {text: 'Time:', bold: true},
  //               {text: `${time}`},
  //               {text: 'Page:', bold: true},
  //               {text: `${currentPage}`}
  //             ],
  //             marginLeft: 96
  //           },
  //           {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 911, y2: 5, lineWidth: 2 }]}
  //         ]
  //       },
  //       content: parent
  //     }
      
  //     pdfMake.createPdf(docDef).open();
  //     this.isGenerating = false;
  //   })
  // }

  printAccomplishmentReports(params) {
    this.isGenerating = true;
    this.hhtApiService.printAccomplishmentReports(params).subscribe(data => {
        let result = data['body']['results'];
        let date = data['body']['date'];
        let time = data['body']['time'];
        console.log(result);

        // Initialize CSV and text content
        let csvContent = 'Reading Center,Theoretical Rdg Date,Route,Actual Reading Date,Cycle,Reader Code,Itinerary Number,HHT Code,Lot Address,Client Name,Meter No.,Type,Previous Rdg.,Rdg.,Time,RT,Mtr FF/Remark 1,Mtr FF/Remark 2,Mtr FF/Remark 3\n';
        let textContent = `Accomplishment Report\n\n`;
        textContent += `Date: ${this.datePipe.transform(date, 'M/d/yy')}\nTime: ${time}\n\n`;

        result.forEach(itn => {
         
            let rowContent = [
                itn['readingCenterCode'],
                this.datePipe.transform(itn['theoreticalReadDate'], 'M/d/yy') || '',
                itn['route'],
                this.datePipe.transform(itn['readDate'], 'M/d/yy') || '',
                itn['cycle'],
                itn['readerCode'],
                itn['itineraryNo'],
                itn['device_code']
            ];

            // Append row for CSV
            csvContent += rowContent.join(',') + '\n';

            // Append row for text file
            textContent += `Reading Center: ${itn['readingCenterCode']}\n`;
            textContent += `Theoretical Rdg Date: ${this.datePipe.transform(itn['theoreticalReadDate'], 'M/d/yy') || ''}\n`;
            textContent += `Route: ${itn['route']}\n`;
            textContent += `Actual Reading Date: ${this.datePipe.transform(itn['readDate'], 'M/d/yy') || ''}\n`;
            textContent += `Cycle: ${itn['cycle']}\n`;
            textContent += `Reader Code: ${itn['readerCode']}\n`;
            textContent += `Itinerary Number: ${itn['itineraryNo']}\n`;
            textContent += `HHT Code: ${itn['device_code']}\n`;
            textContent += '\nLot Details:\n';

            itn['lots'].forEach(lots => {
                let mpFirstIndex = 0;
                lots['meteringPoints'].forEach(mp => {
                    let meterFirstIndex = 0;
                    mp['meters'].forEach(meter => {
                        let mtrRow = [
                            mpFirstIndex == 0 ? `${lots['houseNo']} ${lots['street']}` : '',
                            meterFirstIndex == 0 ? `${mp['clientName']}` : '',
                            `${meter['meterNo']}`,
                            `${meter['consumptionType']}`,
                            `${meter['previousReading']}`,
                            `${meter['presentReading']}`,
                            `${meter['readTime']}`,
                            `${meter['RT']}`,
                            `${meter['fieldFindingCode']} - ${meter['fieldFindingRemarks']}`,
                            `${meter['fieldFindingCode_2']} - ${meter['fieldFindingRemarks_2']}`,
                            `${meter['fieldFindingCode_3']} - ${meter['fieldFindingRemarks_3']}`
                        ];

                        // Append meter details to CSV
                        csvContent += mtrRow.join(',') + '\n';

                        // Append meter details to text file
                        textContent += `Lot Address: ${lots['houseNo']} ${lots['street']}\n`;
                        textContent += `Client Name: ${mp['clientName']}\n`;
                        textContent += `Meter No.: ${meter['meterNo']}\n`;
                        textContent += `Type: ${meter['consumptionType']}\n`;
                        textContent += `Previous Rdg.: ${meter['previousReading']}\n`;
                        textContent += `Rdg.: ${meter['presentReading']}\n`;
                        textContent += `Time: ${meter['readTime']}\n`;
                        textContent += `RT: ${meter['RT']}\n`;
                        textContent += `Mtr FF/Remark 1: ${meter['fieldFindingCode']} - ${meter['fieldFindingRemarks']}\n`;
                        textContent += `Mtr FF/Remark 2: ${meter['fieldFindingCode_2']} - ${meter['fieldFindingRemarks_2']}\n`;
                        textContent += `Mtr FF/Remark 3: ${meter['fieldFindingCode_3']} - ${meter['fieldFindingRemarks_3']}\n`;
                        textContent += '\n';

                        mpFirstIndex++;
                        meterFirstIndex++;
                    });
                });
            });

        });

        if(params.fileType && params.fileType === "csv"){
            // CSV Generation
            console.log('csv');
            this.downloadCSV(csvContent,"AccomplishmentReport");
        }
        else{
            // Text Generation
            console.log('txt');
            this.downloadTextFile(textContent,"AccomplishmentReport");
        }

        this.isGenerating = false;
    });
  }

  // printSummaryAccomplishmentReports(params){
  //   this.isGenerating = true;
  //   let parent: Array<any> = [];
  //   this.hhtApiService.printSummaryAccomplishmentReports(params).subscribe(data =>{
  //     let result = data['body']['results'];
  //     let date = data['body']['date'];
  //     let time = data['body']['time'];
  //     console.log(result);
  //     result.forEach(itn => {
  //       parent.push(
  //       {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 815, y2: 5, lineWidth: 2 }]},
  //       {text: '\n'}, //new line
  //       {
  //         columns: [
  //           {text: 'Reading Center:', bold: true},
  //           {text: `${itn['readingCenterCode']}`},
  //           {text: 'Theoretical Rdg Date:', bold: true},
  //           {text: `${this.datePipe.transform(itn['theoreticalReadDate'], 'M/d/yy') || ''}`}
  //         ]
  //       },
  //       {
  //         columns: [
  //           {text: 'Route:', bold: true},
  //           {text: `${itn['route']}`},
  //           {text: 'Actual Reading Date:', bold: true},
  //           {text: `${this.datePipe.transform(itn['readDate'], 'M/d/yy') || ''}`}
  //         ]
  //       },
  //       {
  //         columns: [
  //           {text: 'Cycle:', bold: true},
  //           {text: `${itn['cycle']}`},
  //           {text: 'Reader Code:', bold: true},
  //           {text: `${itn['readerCode']}`}
  //         ]
  //       },
  //       {
  //         columns: [
  //           {text: 'Itinerary Number:', bold: true},
  //           {text: `${itn['itineraryNo']}`},
  //           {text: 'HHT Code:', bold: true},
  //           {text: `${itn['device_code']}`}
  //         ]
  //       },
  //       {text: '\n'}, //newline
  //       {canvas: [{ type: 'line', x1: 0, y1: 2, x2: 815, y2: 2, lineWidth: 2, dash: {length: 15, space: 0} }]},
  //       {text: '\n'}
  //       );
  //       let mtrs: Array<any> = [
  //         [
  //           {text: 'Lot Address', alignment: 'center', bold: true},
  //           {text: 'Client Name', alignment: 'center', bold: true},
  //           {text: 'Meter No.', alignment: 'center', bold: true},
  //           {text: 'Type', alignment: 'center', bold: true},
  //           {text: 'Previous Rdg.', alignment: 'center', bold: true},
  //           {text: 'Rdg.', alignment: 'center', bold: true},
  //           {text: 'Time', alignment: 'center', bold: true},
  //           {text: 'RT', alignment: 'center', bold: true},
  //           {text: 'Mtr FF/Remark', alignment: 'center', bold: true, colSpan: 3},
  //           {},
  //           {}
  //         ]
  //       ];
  //       itn['lots'].forEach(lots =>{
  //         let mpFirstIndex = 0;
  //         lots['meteringPoints'].forEach(mp =>{
  //           let meterFirstIndex = 0;
  //             mp['meters'].forEach(meter =>{
  //               mtrs.push(
  //                 [
  //                   {text: mpFirstIndex == 0 ? `${lots['houseNo']} ${lots['street']}` : ''},
  //                   {text: meterFirstIndex == 0 ? `${mp['clientName']}` : ''},
  //                   {text: `${meter['meterNo']}`},
  //                   {text: `${meter['consumptionType']}`},
  //                   {text: `${meter['previousReading']}`},
  //                   {text: `${meter['presentReading']}`},
  //                   {text: `${meter['readTime']}`},
  //                   {text: `${meter['RT']}`},
  //                   {text: `${meter['fieldFindingCode']} - ${meter['fieldFindingRemarks']}`},
  //                   {text: `${meter['fieldFindingCode_2']} - ${meter['fieldFindingRemarks_2']}`},
  //                   {text: `${meter['fieldFindingCode_3']} - ${meter['fieldFindingRemarks_3']}`}
  //                 ]
  //               )
  //               mpFirstIndex++;
  //               meterFirstIndex++;
  //             })
  //         })
  //       });
  //       parent.push(
  //         {
  //           columns: [
  //             {width: '*', text: ''},
  //             {
  //               table: {
  //                 headerRows: 1,
  //                 widths:['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
  //                 body: mtrs
  //               },
  //               width: 'auto'
  //             },
  //             {width: '*', text: ''}
  //           ]
  //         })
  //     })
  //     let docDef = {
  //       pageOrientation: 'landscape',
  //       pageSize: 'LEGAL',
  //       pageMargins: [96, 96, 96, 96],
  //       defaultStyle: {
  //         fontSize: 12,
  //         font: 'HelveticaNeue'
  //       },
  //       header: (currentPage) => {
  //         return [
  //           {
  //             columns: [
  //               {
  //                 text: 'Meralco - Customer Management System',
  //                 alignment: 'center',
  //                 bold: true
  //               }
  //             ],
  //             marginTop: 30
  //           },
  //           {
  //             columns: [
  //               {
  //                 text: 'Listing on Accomplished Readings',
  //                 alignment: 'center',
  //                 bold: true
  //               }
  //             ]
  //           },
  //           {text: '\n'}, //new line
  //           {
  //             columns: [
  //               {text: 'Date:', bold: true},
  //               {text: `${this.datePipe.transform(date, 'M/d/yy')}`},
  //               {text: 'Time:', bold: true},
  //               {text: `${time}`},
  //               {text: 'Page:', bold: true},
  //               {text: `${currentPage}`}
  //             ],
  //             marginLeft: 96
  //           },
  //           {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 911, y2: 5, lineWidth: 2 }]}
  //         ]
  //       },
  //       content: parent
  //     }
      
  //     pdfMake.createPdf(docDef).open();
  //     this.isGenerating = false;
  //   })
  // }

  printSummaryAccomplishmentReports(params) {
    this.isGenerating = true;
    let parent: Array<any> = [];
    
    // Initialize CSV and text content
    let csvContent = 'Reading Center,Theoretical Rdg Date,Route,Actual Reading Date,Cycle,Reader Code,Itinerary Number,HHT Code,Lot Address,Client Name,Meter No.,Type,Previous Rdg.,Rdg.,Time,RT,Mtr FF/Remark 1,Mtr FF/Remark 2,Mtr FF/Remark 3\n';
    let textContent = `Listing on Accomplished Readings\n\n`;

    this.hhtApiService.printSummaryAccomplishmentReports(params).subscribe(data => {
        let result = data['body']['results'];
        let date = data['body']['date'];
        let time = data['body']['time'];
        console.log(result);

        result.forEach(itn => {
            let itineraryDetails = [
                `Reading Center: ${itn['readingCenterCode']}`,
                `Theoretical Rdg Date: ${this.datePipe.transform(itn['theoreticalReadDate'], 'M/d/yy') || ''}`,
                `Route: ${itn['route']}`,
                `Actual Reading Date: ${this.datePipe.transform(itn['readDate'], 'M/d/yy') || ''}`,
                `Cycle: ${itn['cycle']}`,
                `Reader Code: ${itn['readerCode']}`,
                `Itinerary Number: ${itn['itineraryNo']}`,
                `HHT Code: ${itn['device_code']}`
            ];

            textContent += itineraryDetails.join('\n') + '\n\n';
            
            // CSV line for itinerary
            let itineraryCsvLine = [
                itn['readingCenterCode'],
                this.datePipe.transform(itn['theoreticalReadDate'], 'M/d/yy') || '',
                itn['route'],
                this.datePipe.transform(itn['readDate'], 'M/d/yy') || '',
                itn['cycle'],
                itn['readerCode'],
                itn['itineraryNo'],
                itn['device_code']
            ].join(',') + '\n';

            csvContent += itineraryCsvLine;

            itn['lots'].forEach(lots => {
                let mpFirstIndex = 0;
                lots['meteringPoints'].forEach(mp => {
                    let meterFirstIndex = 0;
                    mp['meters'].forEach(meter => {
                   
                        textContent += `Lot Address: ${mpFirstIndex == 0 ? `${lots['houseNo']} ${lots['street']}` : ''}\n`;
                        textContent += `Client Name: ${meterFirstIndex == 0 ? `${mp['clientName']}` : ''}\n`;
                        textContent += `Meter No.: ${meter['meterNo']}\n`;
                        textContent += `Type: ${meter['consumptionType']}\n`;
                        textContent += `Previous Rdg.: ${meter['previousReading']}\n`;
                        textContent += `Rdg.: ${meter['presentReading']}\n`;
                        textContent += `Time: ${meter['readTime']}\n`;
                        textContent += `RT: ${meter['RT']}\n`;
                        textContent += `Mtr FF/Remark 1: ${meter['fieldFindingCode']} - ${meter['fieldFindingRemarks']}\n`;
                        textContent += `Mtr FF/Remark 2: ${meter['fieldFindingCode_2']} - ${meter['fieldFindingRemarks_2']}\n`;
                        textContent += `Mtr FF/Remark 3: ${meter['fieldFindingCode_3']} - ${meter['fieldFindingRemarks_3']}\n\n`;

                        // CSV line for meter
                        let meterCsvLine = [
                            mpFirstIndex == 0 ? `${lots['houseNo']} ${lots['street']}` : '',
                            meterFirstIndex == 0 ? `${mp['clientName']}` : '',
                            meter['meterNo'],
                            meter['consumptionType'],
                            meter['previousReading'],
                            meter['presentReading'],
                            meter['readTime'],
                            meter['RT'],
                            `${meter['fieldFindingCode']} - ${meter['fieldFindingRemarks']}`,
                            `${meter['fieldFindingCode_2']} - ${meter['fieldFindingRemarks_2']}`,
                            `${meter['fieldFindingCode_3']} - ${meter['fieldFindingRemarks_3']}`
                        ].join(',') + '\n';

                        csvContent += meterCsvLine;
                        mpFirstIndex++;
                        meterFirstIndex++;
                    });
                });
            });

        });

        if(params.fileType && params.fileType === "csv"){
            // CSV Generation
            console.log('csv');
            this.downloadCSV(csvContent,"SummaryAccomplishedReadings");
        }
        else{
            // Text Generation
            console.log('txt');
            this.downloadTextFile(textContent,"SummaryAccomplishedReadings");
        }

        this.isGenerating = false;
    });
  }



  // printItineraryRemarksReport(params){
  //   this.isGenerating = true;
  //   this.hhtApiService.printItineraryRemarksReport(params).subscribe(data =>{
  //     console.log(data['body'])
  //     let result = data['body']['results'];
  //     let date = data['body']['date'];
  //     let time = data['body']['time'];
  //     let rmks: Array<any> = [];
  //     rmks.push([
  //       {text: 'Remark Number', alignment: 'center', bold: true},
  //       {text: 'Itinerary Remarks', alignment: 'center', bold: true}
  //     ])
  //     result.forEach(remarks => {
  //       rmks.push([
  //         {text: `${remarks['remark_number']}`},
  //         {text: `${remarks['remarks'] || ''}`}
  //       ])
  //     });

  //     let parent: Array<any> = [
  //       {
  //         columns: [
  //           {width: '*', text: ''},
  //           {
  //             table: {
  //               headerRows: 1,
  //               widths:['auto', 'auto'],
  //               body: rmks
  //             },
  //             width: 'auto'
  //           },
  //           {width: '*', text: ''}
  //         ]
  //       }
  //     ];

  //     let docDef = {
  //       pageOrientation: 'landscape',
  //       pageSize: 'LEGAL',
  //       pageMargins: [96, 96, 96, 96],
  //       defaultStyle: {
  //         fontSize: 12,
  //         font: 'HelveticaNeue'
  //       },
  //       header: (currentPage) => {
  //         return [
  //           {
  //             columns: [
  //               {
  //                 text: 'Meralco - Customer Management System',
  //                 alignment: 'center',
  //                 bold: true
  //               }
  //             ],
  //             marginTop: 30
  //           },
  //           {
  //             columns: [
  //               {
  //                 text: 'Itinerary Remarks Report',
  //                 alignment: 'center',
  //                 bold: true
  //               }
  //             ]
  //           },
  //           {text: '\n'}, //new line
  //           {
  //             columns: [
  //               {
  //                 text: [{text: 'User:', bold: true}, ` ${this.getUser()['email']}`],
  //                 width: 300
  //               },
  //               {text: 'Date:', bold: true},
  //               {text: `${this.datePipe.transform(date, 'M/d/yy')}`},
  //               {text: 'Time:', bold: true},
  //               {text: `${time}`},
  //               {text: 'Page:', bold: true},
  //               {text: `${currentPage}`}
  //             ],
  //             marginLeft: 96
  //           },
  //           {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 911, y2: 5, lineWidth: 2 }]}
  //         ]
  //       },
  //       content: parent
  //     }

  //     pdfMake.createPdf(docDef).open();
  //     this.isGenerating = false;
  //   })
  // }

  printItineraryRemarksReport(params) {
    this.isGenerating = true;
    this.hhtApiService.printItineraryRemarksReport(params).subscribe(data => {
        console.log(data['body']);
        let result = data['body']['results'];
        let date = data['body']['date'];
        let time = data['body']['time'];
        let rmks: Array<any> = [];

        // Initialize CSV and text content
        let csvContent = `Remark Number,Itinerary Remarks\n`;
        let textContent = `Itinerary Remarks Report\n\n`;
        textContent += `User: ${this.getUser()['email']}\nDate: ${this.datePipe.transform(date, 'M/d/yy')}\nTime: ${time}\n\n`;
        textContent += `Remark Number\tItinerary Remarks\n`;

        // Add table headers
        rmks.push([
            { text: 'Remark Number', alignment: 'center', bold: true },
            { text: 'Itinerary Remarks', alignment: 'center', bold: true }
        ]);

        // Add rows and populate CSV and text content
        result.forEach(remarks => {
            let remarkNumber = remarks['remark_number'];
            let itineraryRemarks = remarks['remarks'] || '';

            // Append CSV row
            csvContent += `${remarkNumber},${itineraryRemarks}\n`;

            // Append text row
            textContent += `${remarkNumber}\t${itineraryRemarks}\n`;
        });

        if(params.fileType && params.fileType === "csv"){
            // CSV Generation
            console.log('csv');
            this.downloadCSV(csvContent,"ItineraryRemarksReport");
        }
        else{
            // Text Generation
            console.log('txt');
            this.downloadTextFile(textContent,"ItineraryRemarksReport");
        }

        this.isGenerating = false;
    });
  }

  // printPreviousAndOrCurrentAcceptedItineraries(params) {
  //   this.hhtApiService.previouslyAndOrCurrentlyAcceptedItinerariesReport(params).subscribe(data =>{
  //     console.log(data['body'])
  //     let result = data['body']['results'];
  //     const date = data['body']['date'];
  //     const time = data['body']['time'];
  //     let tableBody: Array<any> = [];
  //     tableBody.push([
  //       {text: 'Itinerary Number', alignment: 'center', bold: true},
  //       {text: 'Readings', alignment: 'center', bold: true},
  //       {text: 'Size', alignment: 'center', bold: true},
  //       {text: 'Diff Level', alignment: 'center', bold: true},
  //       {text: 'Assigned To', alignment: 'center', bold: true},
  //       {text: 'Theor. Rdg. Date', alignment: 'center', bold: true},
  //       {text: 'Actual Rdg. Date', alignment: 'center', bold: true},
  //       {text: 'Status', alignment: 'center', bold: true},
  //     ])
  //     result.forEach(itn => {
  //       tableBody.push([
  //         {text: `${itn['itineraryCode']}`},
  //         {text: `${itn['readings']}`},
  //         {text: `${itn['size']}`},
  //         {text: `${itn['difficultyLevel']}`},
  //         {text: `${itn['assigned_to'] || ''}`},
  //         {text: `${this.datePipe.transform(itn['theoreticalReadDate'], 'M/d/yy') || ''}`},
  //         {text: `${this.datePipe.transform(itn['readDate'], 'M/d/yy') || ''}`},
  //         {text: `${itn['status']}`},
  //       ])
  //     });

  //     let parent: Array<any> = [
  //       {
  //         columns: [
  //           {width: '*', text: ''},
  //           {
  //             table: {
  //               headerRows: 1,
  //               widths:['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
  //               body: tableBody
  //             },
  //             width: 'auto'
  //           },
  //           {width: '*', text: ''}
  //         ]
  //       }
  //     ];

  //     let docDef = {
  //       pageOrientation: 'landscape',
  //       pageSize: 'LEGAL',
  //       pageMargins: [96, 130, 96, 96],
  //       defaultStyle: {
  //         fontSize: 12,
  //         font: 'HelveticaNeue'
  //       },
  //       header: (currentPage) => {
  //         return [
  //           {
  //             columns: [
  //               {
  //                 text: 'Meralco - Customer Management System',
  //                 alignment: 'center',
  //                 bold: true
  //               }
  //             ],
  //             marginTop: 30
  //           },
  //           {
  //             columns: [
  //               {
  //                 text: 'Previously and/or Currently Accepted Itineraries',
  //                 alignment: 'center',
  //                 bold: true
  //               }
  //             ]
  //           },
  //           {text: '\n'}, //new line
  //           {
  //             columns: [
  //               {
  //                 text: [{text: 'User:', bold: true}, `${this.getUser()['email']}`],
  //                 width: 'auto'
  //               },
  //               {
  //                 text: '',
  //                 width: '*'
  //               },
  //               {
  //                 text: [{text: 'Page:', bold: true}, `             ${currentPage}`],
  //                 width: 'auto'
  //               }
  //             ],
  //             marginLeft: 96,
  //             marginRight: 96
  //           },
  //           {
  //             columns: [
  //               {
  //                 text: [{text: 'Reading Center: ', bold: true}, `${params.readingCenterCode}`],
  //                 width: 'auto'
  //               },
  //               {
  //                 text: '',
  //                 width: '*'
  //               },
  //               {
  //                 text: [{text: 'Date:', bold: true}, `   ${this.datePipe.transform(date, 'M/d/yy')}`],
  //                 width: 'auto'
  //               }
  //             ],
  //             marginLeft: 96,
  //             marginRight: 96
  //           },
  //           {
  //             columns: [
  //               {
  //                 text: '',
  //                 width: '*'
  //               },
  //               {
  //                 text: [{text: 'Time:', bold: true}, ` ${time}`],
  //                 width: 'auto'
  //               }
  //             ],
  //             marginLeft: 96,
  //             marginRight: 96
  //           },
  //           {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 910, y2: 5, lineWidth: 2 }]}
  //         ]
  //       },
  //       content: parent
  //     }

  //     pdfMake.createPdf(docDef).open();
  //     this.isGenerating = false;
  //   })
  // }
  printPreviousAndOrCurrentAcceptedItineraries(params) {
    this.hhtApiService.previouslyAndOrCurrentlyAcceptedItinerariesReport(params).subscribe(data => {
        console.log(data['body']);
        let result = data['body']['results'];
        const date = data['body']['date'];
        const time = data['body']['time'];
        let tableBody: Array<any> = [];

        // Initialize CSV and text content
        let csvContent = `Itinerary Number,Readings,Size,Diff Level,Assigned To,Theor. Rdg. Date,Actual Rdg. Date,Status\n`;
        let textContent = `Previously and/or Currently Accepted Itineraries\n\n`;
        textContent += `User: ${this.getUser()['email']}\nReading Center: ${params.readingCenterCode}\nDate: ${this.datePipe.transform(date, 'M/d/yy')}\nTime: ${time}\n\n`;
        textContent += `Itinerary Number\tReadings\tSize\tDiff Level\tAssigned To\tTheor. Rdg. Date\tActual Rdg. Date\tStatus\n`;

        // Add rows and populate CSV and text content
        result.forEach(itn => {
            let itineraryCode = itn['itineraryCode'];
            let readings = itn['readings'];
            let size = itn['size'];
            let difficultyLevel = itn['difficultyLevel'];
            let assignedTo = itn['assigned_to'] || '';
            let theoreticalReadDate = this.datePipe.transform(itn['theoreticalReadDate'], 'M/d/yy') || '';
            let actualReadDate = this.datePipe.transform(itn['readDate'], 'M/d/yy') || '';
            let status = itn['status'];

            // Append CSV row
            csvContent += `${itineraryCode},${readings},${size},${difficultyLevel},${assignedTo},${theoreticalReadDate},${actualReadDate},${status}\n`;

            // Append text row
            textContent += `${itineraryCode}\t${readings}\t${size}\t${difficultyLevel}\t${assignedTo}\t${theoreticalReadDate}\t${actualReadDate}\t${status}\n`;
        });

        if(params.fileType && params.fileType === "csv"){
            // CSV Generation
            console.log('csv');
            this.downloadCSV(csvContent,"PreviousAndOrCurrentAcceptedItineraries");
        }
        else{
            // Text Generation
            console.log('txt');
            this.downloadTextFile(textContent,"PreviousAndOrCurrentAcceptedItineraries");
        }

        this.isGenerating = false;
    });
  }
  // printDailyAssignmentOfItineraries(params){
  //   this.isGenerating = true;
  //   this.hhtApiService.dailyAssignmentOfItinerariesReport(params).subscribe(data =>{
  //     console.log(data['body']['results'])
  //     let result = data['body']['results'];
  //     let date = data['body']['date'];
  //     let time = data['body']['time'];
  //     let tableBody: Array<any> = [];
  //     tableBody.push([
  //       {text: 'R/C', alignment: 'center', bold: true},
  //       {text: 'Route', alignment: 'center', bold: true},
  //       {text: 'Cycle', alignment: 'center', bold: true},
  //       {text: 'Itin.No.', alignment: 'center', bold: true},
  //       {text: 'Support', alignment: 'center', bold: true},
  //       {text: 'Theor. Rdg. Date', alignment: 'center', bold: true},
  //       {text: 'Reader', alignment: 'center', bold: true},
  //       {text: 'Date Received', alignment: 'center', bold: true},
  //       {text: 'Signature', alignment: 'center', bold: true},
  //       {text: 'Date Returned', alignment: 'center', bold: true},
  //       {text: 'Status', alignment: 'center', bold: true},
  //       {text: 'Signature', alignment: 'center', bold: true}
  //     ])
  //     result.forEach(itn => {
  //       tableBody.push([
  //         {text: `${itn['readingCenterCode']}`},
  //         {text: `${itn['route']}`},
  //         {text: `${itn['cycle']}`},
  //         {text: `${itn['itineraryNo']}`},
  //         {text: `${itn['support']}`}, 
  //         {text: `${itn['theoreticalReadDate']}`},
  //         {text: `${itn['readerCode'] || ''}`},
  //         {text: `${this.datePipe.transform(itn['date_received'], 'M/d/yy') || ''}`}, 
  //         {text: `${itn['received_signature']}`},
  //         {text: `${this.datePipe.transform(itn['date_returned'], 'M/d/yy') || ''}`}, 
  //         {text: `${itn['status']}`},
  //         {text: `${itn['returned_signature']}`}
  //       ])
  //     });
  //     let parent: Array<any> = [
  //       {
  //         columns: [
  //           {width: '*', text: ''},
  //           {
  //             table: {
  //               headerRows: 1,
  //               widths:['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
  //               body: tableBody
  //             },
  //             width: 'auto'
  //           },
  //           {width: '*', text: ''}
  //         ]
  //       }
  //     ];

  //     let docDef = {
  //       pageOrientation: 'landscape',
  //       pageSize: 'LEGAL',
  //       pageMargins: [96, 130, 96, 96],
  //       defaultStyle: {
  //         fontSize: 12,
  //         font: 'HelveticaNeue'
  //       },
  //       header: (currentPage) => {
  //         return [
  //           {
  //             columns: [
  //               {
  //                 text: 'Meralco - Customer Management System',
  //                 alignment: 'center',
  //                 bold: true
  //               }
  //             ],
  //             marginTop: 30
  //           },
  //           {
  //             columns: [
  //               {
  //                 text: 'Daily Assignment of Itineraries to be read',
  //                 alignment: 'center',
  //                 bold: true
  //               }
  //             ]
  //           },
  //           {text: '\n'}, //new line
  //           {
  //             columns: [
  //               {
  //                 text: [{text: 'User:', bold: true}, `${this.getUser()['email']}`],
  //                 width: 'auto'
  //               },
  //               {
  //                 text: '',
  //                 width: '*'
  //               },
  //               {
  //                 text: [{text: 'Page:', bold: true}, `             ${currentPage}`],
  //                 width: 'auto'
  //               }
  //             ],
  //             marginLeft: 96,
  //             marginRight: 96
  //           },
  //           {
  //             columns: [
  //               {
  //                 text: [{text: 'Reading Center: ', bold: true}, `${params.readingCenterCode}`],
  //                 width: 'auto'
  //               },
  //               {
  //                 text: '',
  //                 width: '*'
  //               },
  //               {
  //                 text: [{text: 'Date:', bold: true}, `   ${this.datePipe.transform(date, 'M/d/yy')}`],
  //                 width: 'auto'
  //               }
  //             ],
  //             marginLeft: 96,
  //             marginRight: 96
  //           },
  //           {
  //             columns: [
  //               {
  //                 text: '',
  //                 width: '*'
  //               },
  //               {
  //                 text: [{text: 'Time:', bold: true}, ` ${time}`],
  //                 width: 'auto'
  //               }
  //             ],
  //             marginLeft: 96,
  //             marginRight: 96
  //           },
  //           {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 910, y2: 5, lineWidth: 2 }]}
  //         ]
  //       },
  //       content: parent
  //     }

  //     pdfMake.createPdf(docDef).open();
  //     this.isGenerating = false;
  //   })
  // }

  printDailyAssignmentOfItineraries(params) {
    this.isGenerating = true;
    this.hhtApiService.dailyAssignmentOfItinerariesReport(params).subscribe(data => {
        console.log(data['body']['results']);
        let result = data['body']['results'];
        let date = data['body']['date'];
        let time = data['body']['time'];

        let tableBody = [];
        let csvContent = [];
        let textContent = '';

        // Table headers
        const headers = [
            'R/C', 'Route', 'Cycle', 'Itin.No.', 'Support', 'Theor. Rdg. Date', 'Reader',
            'Date Received', 'Signature', 'Date Returned', 'Status', 'Signature'
        ];
        tableBody.push(headers.map(header => ({ text: header, alignment: 'center', bold: true })));

        // Add headers to CSV and text content
        csvContent.push(headers);
        textContent += headers.join(' | ') + '\n';
        textContent += '-'.repeat(120) + '\n';

        result.forEach(itn => {
            const row = [
                `${itn['readingCenterCode']}`,
                `${itn['route']}`,
                `${itn['cycle']}`,
                `${itn['itineraryNo']}`,
                `${itn['support']}`,
                `${itn['theoreticalReadDate']}`,
                `${itn['readerCode'] || ''}`,
                `${this.datePipe.transform(itn['date_received'], 'M/d/yy') || ''}`,
                `${itn['received_signature']}`,
                `${this.datePipe.transform(itn['date_returned'], 'M/d/yy') || ''}`,
                `${itn['status']}`,
                `${itn['returned_signature']}`
            ];

            // Add row to table body for PDF
            tableBody.push(row.map(cell => ({ text: cell })));

            // Add row to CSV and text content
            csvContent.push(row);
            textContent += row.join(' | ') + '\n';
        });

        // CSV and text file generation
        if(params.fileType && params.fileType === "csv"){
            // CSV Generation
            console.log('csv');
            this.downloadCSV(csvContent.map(e => e.join(',')).join('\n'),"DailyAssignmentOfItineraries");
        }
        else{
            // Text Generation
            console.log('txt');
            this.downloadTextFile(textContent,"DailyAssignmentOfItineraries");
        }

        this.isGenerating = false;
    });
  }

//   printMetersWithFieldFindings(params){
//     this.isGenerating = true;
//     this.hhtApiService.printMetersWithFieldFindingsReports(params).subscribe(data =>{
//       console.log('printMetersWithFieldFindingsReports')
//       console.log(data['body']['results'])
//       let results = data['body']['results'];
//       const date = data['body']['date'];
//       const time = data['body']['time'];
//       let parent: Array<any> = [];
//       const table: Array<any> = [];
//       if (results.length > 0) {
//         this.hhtApiService.getReadingCenters(results[0]['readingCenterCode']).subscribe(readingCenterData =>{
//           const readingCenterResults = readingCenterData['body']['results'];
//           if (readingCenterResults.length === 1) {
//             console.log(readingCenterResults)
//             const readingCenter = readingCenterResults[0].description;
//             table.push(
//               [
//                 {text: 'Date handled', alignment: 'center', bold: true},
//                 {text: 'Route', alignment: 'center', bold: true},
//                 {text: 'Itin No.', alignment: 'center', bold: true},
//                 {text: 'Meter Number', alignment: 'center', bold: true},
//                 {text: 'L.I.N.', alignment: 'center', bold: true},
//                 {text: 'Address', alignment: 'center', bold: true},
//                 {text: 'FF', alignment: 'center', bold: true},
//                 {text: 'FF_2', alignment: 'center', bold: true},
//                 {text: 'FF_3', alignment: 'center', bold: true},
//                 {text: 'Description', alignment: 'center', bold: true},
//                 {text: 'Description_2', alignment: 'center', bold: true},
//                 {text: 'Description_3', alignment: 'center', bold: true},
//                 {text: 'Remarks', alignment: 'center', bold: true},
//                 {text: 'Rdg', alignment: 'center', bold: true},
//               ]
//             );
//             results.forEach(result=>{
//               table.push(
//                 [
//                   {text: this.datePipe.transform(result['date_handled'], 'M/d/yy') || ''},
//                   {text: result['route']},
//                   {text: result['itineraryNo']},
//                   {text: result['meterNo']},
//                   {text: result['l_i_n']},
//                   {text: result['street']},
//                   {text: result['fieldFindingCode']},
//                   {text: result['fieldFindingCode_2']},
//                   {text: result['fieldFindingCode_3']},
//                   {text: result['description']},
//                   {text: result['description_2']},
//                   {text: result['description_3']},
//                   {text: result['remarks']},
//                   {text: result['readings']},
//                 ]
//               );
//             });
      
//             parent.push(
//               { text: '\n' }, //newline
//               {
//                 columns: [
//                   {width: '*', text: ''},
//                   {
//                     table: {
//                       headerRows: 1,
//                       widths:['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
//                       body: table
//                     },
//                     width: 'auto',
//                     layout: 'headerLineOnly'
//                   },
//                   {width: '*', text: ''}
//                 ]
//               }
//             );
      
//             let docDef = {
//               pageOrientation: 'landscape',
//               pageSize: 'LEGAL',
//               pageMargins: [96, 96, 96, 96],
//               defaultStyle: {
//                 fontSize: 12,
//                 font: 'HelveticaNeue'
//               },
//               header: (currentPage) => {
//                 return [
//                   {
//                     columns: [
//                       {
//                         text: 'Meralco - Customer Management System',
//                         alignment: 'center',
//                         bold: true
//                       }
//                     ],
//                     marginTop: 30
//                   },
//                   {
//                     columns: [
//                       {
//                         text: 'Meter Reading Field Findings Report',
//                         alignment: 'center',
//                         bold: true
//                       }
//                     ]
//                   },
//                   {text: '\n'}, //new line
//                   {
//                     columns: [
//                       {
//                         text: [{text: 'Reading Center:', bold: true}, ` ${readingCenter}`]
//                       },
//                       {
//                         text: [{text: 'Date:', bold: true}, ` ${this.datePipe.transform(date, 'M/d/yy')}`]
//                       },
//                       {
//                         text: [{text: 'Time:', bold: true}, ` ${time}`]
//                       },
//                       {
//                         text: [{text: 'Page:', bold: true}, ` ${currentPage}`]
//                       },
//                     ],
//                     marginLeft: 96
//                   },
//                   {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 970, y2: 5, lineWidth: 2 }]}
//                 ]
//               },
//               content: parent
//             }
      
//             pdfMake.createPdf(docDef).open();
//             this.isGenerating = false;
//           }
//         })
//       }
//     })
//   }

  printMetersWithFieldFindings(params) {
    console.log("printMetersWithFieldFindings");
    this.isGenerating = true;
    this.hhtApiService.printMetersWithFieldFindingsReports(params).subscribe(data => {
        console.log('printMetersWithFieldFindingsReports');
        let results = data['body']['results'];
        const date = data['body']['date'];
        const time = data['body']['time'];
        let parent = [];
        const table = [];

        // CSV and Text content arrays
        let csvContent = [];
        let textContent = '';

        if (results.length > 0) {
            this.hhtApiService.getReadingCenters(results[0]['readingCenterCode']).subscribe(readingCenterData => {
                const readingCenterResults = readingCenterData['body']['results'];
                if (readingCenterResults.length === 1) {
                    const readingCenter = readingCenterResults[0].description;

                    // Table headers
                    const headers = [
                        'Date handled', 'Route', 'Itin No.', 'Meter Number', 'L.I.N.', 'Address', 'FF', 'FF_2', 
                        'FF_3', 'Description', 'Description_2', 'Description_3', 'Remarks', 'Rdg'
                    ];
           
                    // Add headers to CSV and text content
                    csvContent.push(headers);
                    textContent += headers.join(' | ') + '\n';
                    textContent += '-'.repeat(120) + '\n';

                    // Iterate through the results to populate the table and CSV/text data
                    results.forEach(result => {
                        const row = [
                            this.datePipe.transform(result['date_handled'], 'M/d/yy') || '',
                            result['route'],
                            result['itineraryNo'],
                            result['meterNo'],
                            result['l_i_n'],
                            result['street'],
                            result['fieldFindingCode'],
                            result['fieldFindingCode_2'],
                            result['fieldFindingCode_3'],
                            result['description'],
                            result['description_2'],
                            result['description_3'],
                            result['remarks'],
                            result['readings']
                        ];

                        // Add row data to CSV and text content
                        csvContent.push(row);
                        textContent += row.join(' | ') + '\n';
                    });

                    
                    if(params.fileType && params.fileType === "csv"){
                        // CSV Generation
                        console.log('csv');
                        this.downloadCSV(csvContent.map(e => e.join(',')).join('\n'),"MetersWithFieldFindings");
                    }
                    else{
                        // Text Generation
                        console.log('txt');
                        this.downloadTextFile(textContent,"MetersWithFieldFindings");
                    }
                    
                    this.isGenerating = false;
                }
            });
        }
    });
  }

  // printRemovedAndInstalledMeterSeals(params){
  //   this.isGenerating = true;
  //   this.hhtApiService.printRemovedAndInstalledMeterSealsReports(params).subscribe(data =>{
  //     console.log(data['body']['results'])
  //     let results = data['body']['results'];
  //     const date = data['body']['date'];
  //     const time = data['body']['time'];
  //     let parent: Array<any> = [];
  //     let table: Array<any> = [];
  //     results.forEach(result => {
  //       parent.push(
  //         {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 794, y2: 5, lineWidth: 2 }]},
  //         { text: '\n' }, //newline
  //         {
  //           columns: [
  //             {
  //               text: `Reading Center:`,
  //               bold: true,
  //               width: 110
  //             },
  //             {
  //               text: ` ${result['readingCenterCode']}`
  //             },
  //             {
  //               text: `Theoretical Rdg Date:`,
  //               bold: true,
  //               width: 145
  //             },
  //             {
  //               text: ` ${this.datePipe.transform(result['theoreticalReadDate'], 'M/d/yy') || ''}`
  //             }
  //           ]
  //         },
  //         { text: '\n' }, //newline
  //         {
  //           columns: [
  //             {
  //               text: `Route:`,
  //               bold: true,
  //               width: 110
  //             },
  //             {
  //               text: ` ${result['route']}`
  //             },
  //             {
  //               text: `Actual Rdg Date:`,
  //               bold: true,
  //               width: 145
  //             },
  //             {
  //               text: ` ${this.datePipe.transform(result['readDate'], 'M/d/yy') || ''}`
  //             }
  //           ]
  //         },
  //         {
  //           columns: [
  //             {
  //               text: `Cycle:`,
  //               bold: true,
  //               width: 110
  //             },
  //             {
  //               text: ` ${result['cycle']}`
  //             },
  //             {
  //               text: `Reader Code:`,
  //               bold: true,
  //               width: 145
  //             },
  //             {
  //               text: ` ${result['readerCode']}`
  //             }
  //           ]
  //         },
  //         {
  //           columns: [
  //             {
  //               text: `Itinerary Number:`,
  //               bold: true,
  //               width: 110
  //             },
  //             {
  //               text: ` ${result['itineraryNo']}`
  //             },
  //             {
  //               text: `HHT Code:`,
  //               bold: true,
  //               width: 145
  //             },
  //             {
  //               text: ` ${result['device_code']}`
  //             }
  //           ]
  //         },
  //         { text: '\n' }, //newline
  //       );
  //       table.push(
  //         [
  //           {text: 'Item No.', alignment: 'center', bold: true},
  //           {text: 'Installed Seal', alignment: 'center', bold: true},
  //           {text: 'Removed Seal', alignment: 'center', bold: true},
  //           {text: 'Meter Number', alignment: 'center', bold: true},
  //           {text: 'Lot ID No.', alignment: 'center', bold: true},
  //         ]
  //       );
  //       result['lots'].forEach(lot => {
  //         console.log('waow');
  //         console.log(parent);
  //         lot['meteringPoints'].forEach(meteringPoint => {
  //           meteringPoint['meters'].forEach(meter => {
  //             if (meter['meterSeal'].length > 0) {
                
  //               meter['meterSeal'].forEach( seal => {
  //                 table.push(
  //                   [
  //                     {text: seal['item_no']},
  //                     {text: seal['installed']},
  //                     {text: seal['removed']},
  //                     {text: meter['meterNo']},
  //                     {text: lot['lot_id_number']},
  //                   ]
  //                 );
  //               })
  //             }
  //           })
  //         })
  //       })
  //       parent.push(
  //         { text: '\n' }, //newline
  //         {
  //           columns: [
  //             {width: '*', text: ''},
  //             {
  //               table: {
  //                 headerRows: 1,
  //                 widths:['auto', 'auto', 'auto', 'auto', 'auto'],
  //                 body: table
  //               },
  //               width: 'auto'
  //             },
  //             {width: '*', text: ''}
  //           ]
  //         }
  //       );
  //     })

  //     let docDef = {
  //       pageOrientation: 'landscape',
  //       pageSize: 'LEGAL',
  //       pageMargins: [96, 96, 96, 96],
  //       defaultStyle: {
  //         fontSize: 12,
  //         font: 'HelveticaNeue'
  //       },
  //       header: (currentPage) => {
  //         return [
  //           {
  //             columns: [
  //               {
  //                 text: 'Meralco - Customer Management System',
  //                 alignment: 'center',
  //                 bold: true
  //               }
  //             ],
  //             marginTop: 30
  //           },
  //           {
  //             columns: [
  //               {
  //                 text: 'Meter Seal Report',
  //                 alignment: 'center',
  //                 bold: true
  //               }
  //             ]
  //           },
  //           {text: '\n'}, //new line
  //           {
  //             columns: [
  //               {
  //                 text: [{text: 'User:', bold: true}, ` ${this.getUser()['email']}`]
  //               },
  //               {
  //                 text: [{text: 'Date:', bold: true}, ` ${this.datePipe.transform(date, 'M/d/yy')}`]
  //               },
  //               {
  //                 text: [{text: 'Time:', bold: true}, ` ${time}`]
  //               },
  //               {
  //                 text: [{text: 'Page:', bold: true}, ` ${currentPage}`]
  //               },
  //             ],
  //             marginLeft: 96
  //           },
  //           {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 890, y2: 5, lineWidth: 2 }]}
  //         ]
  //       },
  //       content: parent
  //     }

  //     pdfMake.createPdf(docDef).open();
  //     this.isGenerating = false;
  //   })
  // }

  printRemovedAndInstalledMeterSeals(params) {
    this.isGenerating = true;
    this.hhtApiService.printRemovedAndInstalledMeterSealsReports(params).subscribe(data => {
        let results = data['body']['results'];
        const date = data['body']['date'];
        const time = data['body']['time'];
        
        // For CSV and Text data
        let csvContent = '';
        let textContent = '';
        
        // CSV Headers
        csvContent += `Reading Center, Theoretical Rdg Date, Route, Actual Rdg Date, Cycle, Reader Code, Itinerary No., HHT Code, Item No., Installed Seal, Removed Seal, Meter Number, Lot ID No.\n`;
        
        results.forEach(result => {
            let readingCenterInfo = `${result['readingCenterCode']},${this.datePipe.transform(result['theoreticalReadDate'], 'M/d/yy') || ''},${result['route']},${this.datePipe.transform(result['readDate'], 'M/d/yy') || ''},${result['cycle']},${result['readerCode']},${result['itineraryNo']},${result['device_code']}\n`;
            
            // Text version (Formatted)
            textContent += `Reading Center: ${result['readingCenterCode']}\n`;
            textContent += `Theoretical Rdg Date: ${this.datePipe.transform(result['theoreticalReadDate'], 'M/d/yy') || ''}\n`;
            textContent += `Route: ${result['route']}\n`;
            textContent += `Actual Rdg Date: ${this.datePipe.transform(result['readDate'], 'M/d/yy') || ''}\n`;
            textContent += `Cycle: ${result['cycle']}\n`;
            textContent += `Reader Code: ${result['readerCode']}\n`;
            textContent += `Itinerary No.: ${result['itineraryNo']}\n`;
            textContent += `HHT Code: ${result['device_code']}\n\n`;

            // Iterate through lots and meter seals
            result['lots'].forEach(lot => {
                lot['meteringPoints'].forEach(meteringPoint => {
                    meteringPoint['meters'].forEach(meter => {
                        if (meter['meterSeal'].length > 0) {
                            meter['meterSeal'].forEach(seal => {
                                let sealInfo = `${seal['item_no']},${seal['installed']},${seal['removed']},${meter['meterNo']},${lot['lot_id_number']}\n`;
                                csvContent += readingCenterInfo + sealInfo;

                                // Text version
                                textContent += `Item No.: ${seal['item_no']}, Installed Seal: ${seal['installed']}, Removed Seal: ${seal['removed']}, Meter No.: ${meter['meterNo']}, Lot ID No.: ${lot['lot_id_number']}\n`;
                            });
                        }
                    });
                });
            });
            textContent += '\n';
        });

            if(params.fileType && params.fileType === "csv"){
                // CSV Generation
                console.log('csv');
                this.downloadCSV(csvContent,"RemovedInstalledMeterSealsReport");
            }
            else{
                // Text Generation
                console.log('txt');
                this.downloadTextFile(textContent,"RemovedInstalledMeterSealsReport");
            }

          this.isGenerating = false;
      });
  }


  // printHandlingOfItineraries(params){
  //   this.isGenerating = true;
  //   let readingCenter = params.readingCenterCode;
  //   this.hhtApiService.getReadingCenters(params.readingCenterCode).subscribe(readingCenterData =>{
  //     const readingCenterResults = readingCenterData['body']['results'];
  //     if (readingCenterResults.length === 1) {
  //       readingCenter = readingCenterResults[0].description;
  //     }
  //     this.hhtApiService.printHandlingOfItinerariesReports(params).subscribe(data =>{
  //       console.log(data['body']['results'])
  //       let results = data['body']['results'];
  //       const total = data['body']['total'];
  //       const date = data['body']['date'];
  //       const time = data['body']['time'];
  //       let parent: Array<any> = [];
  //       const table: Array<any> = [];
  //       table.push(
  //         [
  //           {text: '', colSpan: 3},
  //           {},
  //           {},
  //           {text: 'Date of Reading', rowSpan: 2, alignment: 'center', bold: true},
  //           {text: 'Number of Readings', colSpan: 8, alignment: 'center', bold: true},
  //           {},
  //           {},
  //           {},
  //           {},
  //           {},
  //           {},
  //           {},
  //           {text: 'Diff. Lev.', colSpan: 2, alignment: 'center', bold: true},
  //           {},
  //           {text: 'Quality of Reading', colSpan: 8, alignment: 'center', bold: true},
  //           {},
  //           {},
  //           {},
  //           {},
  //           {},
  //           {},
  //           {},
  //         ],
  //         [
  //           {text: 'RR', alignment: 'center', bold: true},
  //           {text: 'CC', alignment: 'center', bold: true},
  //           {text: 'Itin', alignment: 'center', bold: true},
  //           {},
  //           {text: 'Reader', alignment: 'center', bold: true},
  //           {text: 'HHT', alignment: 'center', bold: true},
  //           {text: 'Loaded', alignment: 'center', bold: true},
  //           {text: 'Visited', alignment: 'center', bold: true},
  //           {text: 'Actual', alignment: 'center', bold: true},
  //           {text: 'Absent', alignment: 'center', bold: true},
  //           {text: 'W/F.F.', alignment: 'center', bold: true},
  //           {text: 'Forced', alignment: 'center', bold: true},
  //           {text: 'Theor', alignment: 'center', bold: true},
  //           {text: 'Actual', alignment: 'center', bold: true},
  //           {text: 'Visited', alignment: 'center', bold: true},
  //           {text: 'Actual', alignment: 'center', bold: true},
  //           {text: 'Absent', alignment: 'center', bold: true},
  //           {text: 'W/F.F.', alignment: 'center', bold: true},
  //           {text: 'Forced', alignment: 'center', bold: true},
  //           {text: 'Action', alignment: 'center', bold: true},
  //           {text: 'Start', alignment: 'center', bold: true},
  //           {text: 'End', alignment: 'center', bold: true},
  //         ]
  //       );

  //       results.forEach(result => {
  //         table.push(
  //           [
  //             {text: result['route']},
  //             {text: result['cycle']},
  //             {text: result['itineraryNo']},
  //             {text: this.datePipe.transform(result['readDate'], 'M/d/yy') || ''},
  //             {text: result['readingCenterCode']},
  //             {text: result['hht']},
  //             {text: result['loaded']},
  //             {text: result['visited']},
  //             {text: result['actual']},
  //             {text: result['absent']},
  //             {text: result['wff']},
  //             {text: result['forced']},
  //             {text: result['theo_diff_level']},
  //             {text: result['actual_diff_level']},
  //             {text: result['q.visited']},
  //             {text: result['q.actual']},
  //             {text: result['q.absent']},
  //             {text: result['q.wff']},
  //             {text: result['q.forced']},
  //             {text: result['action']},
  //             {text: this.datePipe.transform(result['read_start_time'], 'h:mm:ss')},
  //             {text: this.datePipe.transform(result['read_end_time'], 'h:mm:ss')}
  //           ]
  //         );
  //       })

  //       table.push(
  //         [
  //           {text: '', colSpan: 4},
  //           {},
  //           {},
  //           {},
  //           {text: 'Total'},
  //           {text: '...'},
  //           {text: total['loaded']},
  //           {text: total['visited']},
  //           {text: total['actual']},
  //           {text: total['absent']},
  //           {text: total['wff']},
  //           {text: total['forced']},
  //           {text: total['theo_diff_level']},
  //           {text: total['actual_diff_level']},
  //           {text: total['q_visited']},
  //           {text: total['q_actual']},
  //           {text: total['q_absent']},
  //           {text: total['q_wff']},
  //           {text: total['q_forced']},
  //           {text: '', colSpan: 3},
  //           {},
  //           {},
  //         ]
  //       );

  //       parent.push(
  //         { text: '\n' }, //newline
  //         {
  //           columns: [
  //             {width: '*', text: ''},
  //             {
  //               table: {
  //                 headerRows: 2,
  //                 widths:[
  //                   'auto',
  //                   'auto',
  //                   'auto',
  //                   45, //date of reading
  //                   'auto',
  //                   'auto',
  //                   'auto',
  //                   'auto',
  //                   'auto',
  //                   'auto',
  //                   'auto',
  //                   'auto',
  //                   'auto',
  //                   'auto',
  //                   'auto',
  //                   'auto',
  //                   'auto',
  //                   'auto',
  //                   'auto',
  //                   'auto',
  //                   'auto',
  //                   'auto',
  //                 ],
  //                 body: table,
  //               },
  //               width: 'auto'
  //             },
  //             {width: '*', text: ''}
  //           ]
  //         }
  //       );

  //       let docDef = {
  //         pageOrientation: 'landscape',
  //         pageSize: 'LEGAL',
  //         pageMargins: [15, 96, 15, 96],
  //         defaultStyle: {
  //           fontSize: 10,
  //           font: 'HelveticaNeue'
  //         },
  //         header: (currentPage) => {
  //           return [
  //             {
  //               columns: [
  //                 {
  //                   text: 'Meralco - Customer Management System',
  //                   alignment: 'center',
  //                   bold: true
  //                 }
  //               ],
  //               marginTop: 15
  //             },
  //             {
  //               columns: [
  //                 {
  //                   text: 'Handling of Itineraries',
  //                   alignment: 'center',
  //                   bold: true
  //                 }
  //               ]
  //             },
  //             {
  //               columns: [
  //                 {
  //                   text: 'Unloaded from the HHT\'s',
  //                   alignment: 'center',
  //                   bold: true
  //                 }
  //               ]
  //             },
  //             {text: '\n'}, //new line
  //             {
  //               columns: [
  //                 {
  //                   text: [{text: 'User:', bold: true}, ` ${this.getUser()['email']}`],
  //                   width: 300
  //                 },
  //                 {
  //                   text: [{text: 'Reading Center:', bold: true}, ` ${readingCenter}`],
  //                   width: 300
  //                 },
  //                 {
  //                   text: [{text: 'Date:', bold: true}, ` ${this.datePipe.transform(date, 'M/d/yy')}`]
  //                 },
  //                 {
  //                   text: [{text: 'Time:', bold: true}, ` ${time}`]
  //                 },
  //                 {
  //                   text: [{text: 'Page:', bold: true}, ` ${currentPage}`]
  //                 },
  //               ],
  //               marginLeft: 15
  //             },
  //             {canvas: [{ type: 'line', x1: 15, y1: 5, x2: 990, y2: 5, lineWidth: 2 }]}
  //           ]
  //         },
  //         content: parent
  //       }

  //       pdfMake.createPdf(docDef).open();
  //       this.isGenerating = false;
  //     })
  //   })
  // }

  printHandlingOfItineraries(params) {
    this.isGenerating = true;
    let readingCenter = params.readingCenterCode;
    this.hhtApiService.getReadingCenters(params.readingCenterCode).subscribe(readingCenterData =>{
        const readingCenterResults = readingCenterData['body']['results'];
        if (readingCenterResults.length === 1) {
            readingCenter = readingCenterResults[0].description;
        }
        this.hhtApiService.printHandlingOfItinerariesReports(params).subscribe(data =>{
            let results = data['body']['results'];
            const total = data['body']['total'];
            const date = data['body']['date'];
            const time = data['body']['time'];
            let parent = [];
            const table = [];

            // CSV and Text Data initialization
            let csvContent = `Route,Cycle,Itinerary No.,Date of Reading,Reading Center Code,HHT,Loaded,Visited,Actual,Absent,W/F.F.,Forced,Theo Diff Lev,Actual Diff Lev,Q. Visited,Q. Actual,Q. Absent,Q. W/F.F.,Q. Forced,Action,Start,End\n`;
            let textContent = '';

            results.forEach(result => {
                const row = [
                    result['route'], result['cycle'], result['itineraryNo'], 
                    this.datePipe.transform(result['readDate'], 'M/d/yy') || '', 
                    result['readingCenterCode'], result['hht'], result['loaded'], result['visited'], 
                    result['actual'], result['absent'], result['wff'], result['forced'], 
                    result['theo_diff_level'], result['actual_diff_level'], 
                    result['q.visited'], result['q.actual'], result['q.absent'], 
                    result['q.wff'], result['q.forced'], result['action'], 
                    this.datePipe.transform(result['read_start_time'], 'h:mm:ss'), 
                    this.datePipe.transform(result['read_end_time'], 'h:mm:ss')
                ];

                // Append CSV data
                csvContent += row.join(',') + '\n';

                // Append Text data
                textContent += `Route: ${result['route']}, Cycle: ${result['cycle']}, Itinerary No.: ${result['itineraryNo']}\n`;
                textContent += `Date of Reading: ${this.datePipe.transform(result['readDate'], 'M/d/yy') || ''}, Reading Center: ${result['readingCenterCode']}, HHT: ${result['hht']}\n`;
                textContent += `Loaded: ${result['loaded']}, Visited: ${result['visited']}, Actual: ${result['actual']}, Absent: ${result['absent']}\n`;
                textContent += `W/F.F.: ${result['wff']}, Forced: ${result['forced']}, Theo Diff Lev: ${result['theo_diff_level']}, Actual Diff Lev: ${result['actual_diff_level']}\n`;
                textContent += `Q. Visited: ${result['q.visited']}, Q. Actual: ${result['q.actual']}, Q. Absent: ${result['q.absent']}, Q. W/F.F.: ${result['q.wff']}, Q. Forced: ${result['q.forced']}\n`;
                textContent += `Action: ${result['action']}, Start: ${this.datePipe.transform(result['read_start_time'], 'h:mm:ss')}, End: ${this.datePipe.transform(result['read_end_time'], 'h:mm:ss')}\n\n`;
            });

            if(params.fileType && params.fileType === "csv"){
                // CSV Generation
                console.log('csv');
                this.downloadCSV(csvContent,"HandlingOfItineraries");
            }
            else{
                // Text Generation
                console.log('txt');
                this.downloadTextFile(textContent,"HandlingOfItineraries");
            }

              this.isGenerating = false;
          });
      });
  }


  // printAdditionOfItineraries(params){
  //   this.isGenerating = true;
  //   let readingCenter = params.readingCenterCode;
  //   this.hhtApiService.getReadingCenters(params.readingCenterCode).subscribe(readingCenterData =>{
  //     const readingCenterResults = readingCenterData['body']['results'];
  //     if (readingCenterResults.length === 1) {
  //       readingCenter = readingCenterResults[0].description;
  //     }

  //     this.hhtApiService.printAdditionOfItinerariesReports(params).subscribe(data =>{
  //       console.log(data['body']['results'])
  //       let results = data['body']['results'];
  //       const date = data['body']['date'];
  //       const time = data['body']['time'];
  //       let parent: Array<any> = [];
  //       const table: Array<any> = [];
  //       table.push(
  //         [
  //           {text: 'R/C', alignment: 'center', bold: true},
  //           {text: 'Route', alignment: 'center', bold: true},
  //           {text: 'Cycle', alignment: 'center', bold: true},
  //           {text: 'Itin. No.', alignment: 'center', bold: true},
  //           {text: 'Theor. Rdg. Date', alignment: 'center', bold: true},
  //           {text: 'Action', alignment: 'center', bold: true},
  //         ]
  //       );
  //       results.forEach(result=>{
  //         table.push(
  //           [
  //             {text: result['readingCenterCode']},
  //             {text: result['route']},
  //             {text: result['cycle']},
  //             {text: result['itineraryNo']},
  //             {text: this.datePipe.transform(result['theoreticalReadDate'], 'M/d/yy') || ''},
  //             {text: result['action']},
  //           ]
  //         );
  //       });
  
  //       parent.push(
  //         { text: '\n' }, //newline
  //         {
  //           columns: [
  //             {width: '*', text: ''},
  //             {
  //               table: {
  //                 headerRows: 1,
  //                 widths:['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
  //                 body: table
  //               },
  //               width: 'auto'
  //             },
  //             {width: '*', text: ''}
  //           ]
  //         }
  //       );
  
  //       let docDef = {
  //         pageOrientation: 'landscape',
  //         pageSize: 'LEGAL',
  //         pageMargins: [96, 96, 96, 96],
  //         defaultStyle: {
  //           fontSize: 12,
  //           font: 'HelveticaNeue'
  //         },
  //         header: (currentPage) => {
  //           return [
  //             {
  //               columns: [
  //                 {
  //                   text: 'Meralco - Customer Management System',
  //                   alignment: 'center',
  //                   bold: true
  //                 }
  //               ],
  //               marginTop: 30
  //             },
  //             {
  //               columns: [
  //                 {
  //                   text: 'ADDITION OF ITINERARIES RECEIVED IN THE READING CENTER',
  //                   alignment: 'center',
  //                   bold: true
  //                 }
  //               ]
  //             },
  //             {text: '\n'}, //new line
  //             {
  //               columns: [
  //                 {
  //                   text: [{text: 'Reading Center:', bold: true}, ` ${readingCenter}`]
  //                 },
  //                 {
  //                   text: [{text: 'Date:', bold: true}, ` ${this.datePipe.transform(date, 'M/d/yy')}`]
  //                 },
  //                 {
  //                   text: [{text: 'Time:', bold: true}, ` ${time}`]
  //                 },
  //                 {
  //                   text: [{text: 'Page:', bold: true}, ` ${currentPage}`]
  //                 },
  //               ],
  //               marginLeft: 96
  //             },
  //             {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 890, y2: 5, lineWidth: 2 }]}
  //           ]
  //         },
  //         content: parent
  //       }
  
  //       pdfMake.createPdf(docDef).open();
  //       this.isGenerating = false;
  //     })
  //   });
  // }
  printAdditionOfItineraries(params) {
    this.isGenerating = true;
    let readingCenter = params.readingCenterCode;
    this.hhtApiService.getReadingCenters(params.readingCenterCode).subscribe(readingCenterData => {
        const readingCenterResults = readingCenterData['body']['results'];
        if (readingCenterResults.length === 1) {
            readingCenter = readingCenterResults[0].description;
        }

        this.hhtApiService.printAdditionOfItinerariesReports(params).subscribe(data => {
            let results = data['body']['results'];
            const date = data['body']['date'];
            const time = data['body']['time'];
            let parent = [];
            const table = [];

            // CSV and Text Data initialization
            let csvContent = `Reading Center Code,Route,Cycle,Itinerary No.,Theoretical Reading Date,Action\n`;
            let textContent = '';

            results.forEach(result => {
                const row = [
                    result['readingCenterCode'], 
                    result['route'], 
                    result['cycle'], 
                    result['itineraryNo'], 
                    this.datePipe.transform(result['theoreticalReadDate'], 'M/d/yy') || '', 
                    result['action']
                ];

                // Append CSV data
                csvContent += row.join(',') + '\n';

                // Append Text data
                textContent += `Reading Center Code: ${result['readingCenterCode']}, Route: ${result['route']}, Cycle: ${result['cycle']}\n`;
                textContent += `Itinerary No.: ${result['itineraryNo']}, Theoretical Reading Date: ${this.datePipe.transform(result['theoreticalReadDate'], 'M/d/yy') || ''}\n`;
                textContent += `Action: ${result['action']}\n\n`;
            });

            if(params.fileType && params.fileType === "csv"){
                // CSV Generation
                console.log('csv');
                this.downloadCSV(csvContent,"AdditionOfItineraries");
            }
            else{
                // Text Generation
                console.log('txt');
                this.downloadTextFile(textContent,"AdditionOfItineraries");
            }
            this.isGenerating = false;
        });
    });
  }


  printPreviewOfItinerary(itineraryData: any){
      let tableBody: Array<any> = [];
      tableBody.push([
        {text: 'Lot Address', alignment: 'center', bold: true},
        {text: 'Readings', alignment: 'center', bold: true},
        {text: 'Access Description', alignment: 'center', bold: true}
      ])
      itineraryData['lots'].forEach(itn => {
        tableBody.push([
          {text: `${itn['street'] || ''}, ${itn['houseNo'] || ''}, ${itn['barangay'] || ''}, ${itn['municipality'] || ''}, ${itn['province'] || ''}`},
          {text: !!itn['meteringPoints'] ? `${itn['meteringPoints'][0]['meters'][0]['readings']}` : '-'},
          {text: `${itn['accessDescription'] || '-'}`}
        ])
      });

      let parent: Array<any> = [
        {
          columns: [
            {width: '*', text: ''},
            {
              table: {
                headerRows: 1,
                widths:['auto', 'auto', 'auto'],
                body: tableBody
              },
              width: 'auto'
            },
            {width: '*', text: ''}
          ]
        }
      ];

      let docDef = {
        pageOrientation: 'landscape',
        pageSize: 'LEGAL',
        pageMargins: [96, 120, 96, 96],
        defaultStyle: {
          fontSize: 12,
          font: 'HelveticaNeue'
        },
        header: (currentPage) => {
          return [
            {
              columns: [
                {
                  text: `Itinerary ${itineraryData['itineraryCode']}${itineraryData['split'] || ''}`,
                  alignment: 'center',
                  bold: true,
                  fontSize: 20
                }
              ],
              marginTop: 30
            },
            {text: '\n'}, //new line
            {
              columns: [
                {
                  text: [{text: 'Route:', bold: true}, ` ${itineraryData['route']}`],
                  width: 100
                },
                {
                  text: [{text: 'Cycle:', bold: true}, ` ${itineraryData['cycle']}`],
                  width: 100
                },
                {
                  text: [{text: 'Status:', bold: true}, ` ${itineraryData['status']}`]
                },
                {
                  text: [{text: 'Assign to:', bold: true}, ` ${!!itineraryData['device_code'] ? itineraryData['device_code'] : '-'}`]
                },
                {
                  text: [{text: 'Reader:', bold: true}, ` ${itineraryData['readerCode'] ? itineraryData['readerCode'] : '-'}`]
                },
              ],
              marginLeft: 96
            },
            {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 890, y2: 5, lineWidth: 2 }]}
          ]
        },
        content: parent
      }

      pdfMake.createPdf(docDef).open();
  }

  


  getIsGenerating(): boolean {
    return this.isGenerating;
  }



    // Function to download CSV file
    downloadCSV(csvContent,filename){
        let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        let link = document.createElement("a");
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename + '.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

  // Helper function to download Text File
  downloadTextFile(data,filename) {
    let blob = new Blob([data], { type: 'text/plain;charset=utf-8;' });
    let link = document.createElement('a');
    let url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename + '.txt');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
