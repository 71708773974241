import { Component, OnInit } from '@angular/core';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { ModalService } from '@shared/services/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import {Map, View, Feature} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import * as olProj from 'ol/proj';
import * as olControl from 'ol/control';
import VectoreSource from 'ol/source/Vector';
import Overlay from 'ol/Overlay';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/shared/services/guards/auth.service';
import { environment } from '@environments/environment';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'inquiry-map-portal',
  templateUrl: './inquiry-map-portal.component.html',
  styleUrls: ['./inquiry-map-portal.component.scss']
})
export class InquiryMapPortalComponent implements OnInit {
  
  map: Map;

  filtersFg: FormGroup;

  get filtersFgArray(){
    return this.filtersFg.get('filters') as FormArray;
  }

  private redIconTransUrl = '../../../assets/img/red-marker-inactive.png';
  private blueIconTransUrl = '../../../assets/img/blue-marker-inactive.png';
  private yellowIconTransUrl = '../../../assets/img/yellow-marker-inactive.png';

  private redPinLayer: any;
  private bluePinLayer: any;
  private yellowPinLayer: any;

  private isRedPinAvailable: boolean = true;
  private isBluePinAvailable: boolean = true;
  private isYellowPinAvailable: boolean = true;

  isLoading: boolean = false;
  
  currentServiceType: string = '';
  currentRCC: Array<any> = [];

  routesFilterArray: Array<any> = [];
  itineraryCodeFilterArray: Array<any> = [];
  filterFieldsValidArray: Array<boolean> = [];
  selectedItineraries: Array<any> = [];

  meterDetailsPopup: any = {
    meterNumber: '',
    consumptionType: '',
    longitude: '',
    latitude: ''
  }

  popupOverlay: Overlay;

  email: any;
  userAccountInfo: any;


  constructor(
    private hhtApiService: HhtApiService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private authService: AuthService
  ) {
    this.filtersFg = this.fb.group({
      filters: this.fb.array([])
    });
   }

  ngOnInit() {
    Auth.currentSession().then( data => {

      this.email = String(data.getIdToken().payload['cognito:username']).replace(environment.adPrefix, ''); 
      this.authService.assignCurrentUser(this.email);
      
      this.hhtApiService.getUserAccountInfo(this.email).subscribe(/* istanbul ignore next: promise to observable */(res: any[])=>{  
        console.log(res['body']['UserInfo'])
        this.userAccountInfo = res['body']['UserInfo']
        let currentUser = this.authService.getCurrentUser();
        
        if(this.userAccountInfo.length > 0){       
          console.log('Password Expiration Date:' + res['body']['UserInfo'][0]['expirationDate'])
          const d = new Date();
          let year = d.getFullYear();
          let month = d.getMonth() + 1;
          let day = d.getDate();
          console.log(year + '-' + month + '-' + day)

          if (new Date(res['body']['UserInfo'][0]['expirationDate']) >= d) {

          }
          else {
            if(!currentUser.isAD){ //temporary workaround so that AD user won't redirect to change password. Need more discussion on this.
              this.router.navigate(['hht/change-password', {mustChangePass: true}], 
              { 
              });
            }
          }
          
        }
        else {
          console.log('Credential does not exist in db')
          if(!currentUser.isAD){ //temporary workaround so that AD user won't redirect to change password. Need more discussion on this.
            this.router.navigate(['hht/change-password', {mustChangePass: true}], 
            { 
            });
          }
          
        }
      })
    });


    this.loadMap();
    this.definePins();
    this.definePopupOverlay();
    this.mapListener();
    this.fetchServiceType();
  }

  fetchServiceType(){
    this.isLoading = true;
    this.authService.currentUserInfo().subscribe(user =>{
      this.authService.assignCurrentUser(String(user['username']).replace(environment.adPrefix, '')).then(
        /* istanbul ignore next: observable to promise */() => {
          const params = {
            readingCenter: [],
            route: [],
            itineraryNumber: '',
            email: this.authService.getCurrentUser().email
          }
          this.hhtApiService.getInquiryMapPortal(params).subscribe({
            next: (response) =>{
              this.isLoading = false;
              console.log(response);
              this.currentRCC = response['body']['readingCenter'];
              switch(response['body']['serviceType']){
                case '0':
                  this.currentServiceType = 'NON-GP';
                  break;
                case '1':
                  this.currentServiceType = 'GP';
                  break;
                case '2':
                  this.currentServiceType = 'GP/NON-GP';
                  break;
                default:
                  this.currentServiceType = '';
              }
              this.addFilter();
            },
            error: (err) => {
              console.log(err);
              this.isLoading = false;
            }
          })
        }
      ).catch(
        /* istanbul ignore next */(err) => {
          this.isLoading = false;
          alert('Something went wrong. Please try again later.');
        }
      );
    })
  }

  loadMap(){
    this.map = new Map({
      layers: [
        new TileLayer({source: new OSM()}),
      ],
      view: new View({
        center: olProj.fromLonLat([121.0639245, 14.5890092]),
        zoom: 12,
      }),
      target: 'map',
      controls: olControl.defaults({attribution: false, zoom: false, rotate: false}).extend([new olControl.FullScreen(
        {
          source: 'location-container'
        }
      )])
    });
    
  }

  definePins(){
    this.bluePinLayer = new VectorLayer({
      source: new VectoreSource(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: this.blueIconTransUrl
        })
      }),
      className: 'blue-markers',
      updateWhileAnimating: true,
      updateWhileInteracting: true
    });

    this.redPinLayer = new VectorLayer({
      source: new VectoreSource(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: this.redIconTransUrl
        })
      }),
      className: 'red-markers',
      updateWhileAnimating: true,
      updateWhileInteracting: true
    });

    this.yellowPinLayer = new VectorLayer({
      source: new VectoreSource(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: this.yellowIconTransUrl
        })
      }),
      className: 'yellow-markers',
      updateWhileAnimating: true,
      updateWhileInteracting: true
    });

    this.map.addLayer(this.bluePinLayer);
    this.map.addLayer(this.redPinLayer);
    this.map.addLayer(this.yellowPinLayer);
  }

  definePopupOverlay() {
    const popupElement = document.getElementById('popup');
    this.popupOverlay = new Overlay({
      element: popupElement,
      positioning: 'center-center'
    });

    this.map.addOverlay(this.popupOverlay);
  }

  mapListener(){
    this.map.on('pointermove', /* istanbul ignore next: difficult to simulate map listener events on karma */(event) => {
      this.popupOverlay.setPosition(undefined);
      this.popupOverlay.setOffset([0,0]);
      this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
        const returnCoord = feature.get('geometry').flatCoordinates;
        const featureProperties = feature.getProperties();
        this.meterDetailsPopup.meterNumber = featureProperties.meterNumber;
        this.meterDetailsPopup.consumptionType = featureProperties.consumptionType;
        this.meterDetailsPopup.longitude = featureProperties.longitude;
        this.meterDetailsPopup.latitude = featureProperties.latitude;
        this.popupOverlay.setPosition(returnCoord);
        this.popupOverlay.setOffset([0,-40]);
      });
    });
  }

  loadRoutesLOV(index){
    this.filterFieldsValidArray[index] = true; //remove error message
    this.isLoading = true;
    const params = {
      readingCenter: this.filtersFgArray.controls[index].get('readingCenterCodes').value,
      route: [],
      itineraryNumber: '',
      email: this.authService.getCurrentUser().email
    }
    this.hhtApiService.getInquiryMapPortal(params).subscribe({
      next: (response) =>{
        console.log(response);
        this.routesFilterArray[index].routes = response['body']['route'];
        this.routesFilterArray[index].itineraries = [];
        this.filtersFgArray.controls[index].patchValue({
          routes: [],
          itineraryCode: ''
        });
        this.isLoading = false;

      },
      error: /* istanbul ignore next: difficult to replicate API errors on karma */(err) => {
        console.log(err);
        this.isLoading = false;
      }
    })
  }

  loadItinerariesLOV(index){
    this.filterFieldsValidArray[index] = true; //remove error message
    this.isLoading = true;
    const params = {
      readingCenter: this.filtersFgArray.controls[index].get('readingCenterCodes').value,
      route: this.filtersFgArray.controls[index].get('routes').value,
      itineraryNumber: '',
      email: this.authService.getCurrentUser().email
    }
    this.hhtApiService.getInquiryMapPortal(params).subscribe({
      next: (response) =>{
        console.log(response);
        this.itineraryCodeFilterArray[index].itineraries = response['body']['itineraries'];
        this.filtersFgArray.controls[index].patchValue({
          itineraryCode: ''
        });
        this.isLoading = false;

      },
      error: /* istanbul ignore next: difficult to replicate API errors on karma */(err) => {
        console.log(err);
        this.isLoading = false;
      }
    })
  }

  applyFilter(index){
    /* istanbul ignore else */
    if(this.filtersFgArray.controls[index].valid && !this.selectedItineraries.includes(this.filtersFgArray.controls[index].get('itineraryCode').value)){
      this.isLoading = true;
      this.filterFieldsValidArray[index] = true;
      this.selectedItineraries.push(this.filtersFgArray.controls[index].get('itineraryCode').value);
      /* istanbul ignore if */
      if(this.filtersFgArray.controls[index].get('pinColor').value.length == 0){ //if no assigned pin color yet, check for available pin color
        if(this.isBluePinAvailable){
          this.isBluePinAvailable = !this.isBluePinAvailable;
          this.filtersFgArray.controls[index].get('pinColor').setValue('blue');
          this.filtersFgArray.controls[index].get('pinColor').updateValueAndValidity();

        }else if(this.isRedPinAvailable){
          this.isRedPinAvailable = !this.isRedPinAvailable;
          this.filtersFgArray.controls[index].get('pinColor').setValue('red');
          this.filtersFgArray.controls[index].get('pinColor').updateValueAndValidity();

        }else if(this.isYellowPinAvailable){
          this.isYellowPinAvailable = !this.isYellowPinAvailable;
          this.filtersFgArray.controls[index].get('pinColor').setValue('yellow');
          this.filtersFgArray.controls[index].get('pinColor').updateValueAndValidity();
        }
      }

      const params = {
        readingCenter: this.filtersFgArray.controls[index].get('readingCenterCodes').value,
        route: this.filtersFgArray.controls[index].get('routes').value,
        itineraryNumber: this.filtersFgArray.controls[index].get('itineraryCode').value,
        email: this.authService.getCurrentUser().email
      }
      this.hhtApiService.getInquiryMapPortal(params).subscribe({
        next: (response) =>{
          const meterCoords: Array<any> = response['body']['results'];
          switch(this.filtersFgArray.controls[index].get('pinColor').value){
            case 'blue':
              this.bluePinLayer.getSource().clear();
              meterCoords.forEach((meter, i) => {
                const singleMarker = new Feature({
                  geometry: new Point(olProj.fromLonLat([meter['longitude'], meter['latitude']])),
                  name: 'Blue Pin',
                  meterNumber: meter['meter_number'],
                  consumptionType: meter['consumption_type'],
                  longitude: meter['longitude'],
                  latitude: meter['latitude']
                }); 
                this.bluePinLayer.getSource().addFeature(singleMarker);
                if(meterCoords.length - 1 == i) {
                  this.map.getView().setCenter(olProj.fromLonLat([meter['longitude'], meter['latitude']]));
                }
              });
              break;
            case 'red':
              this.redPinLayer.getSource().clear();
              meterCoords.forEach((meter, i) => {
                const singleMarker = new Feature({
                  geometry: new Point(olProj.fromLonLat([meter['longitude'], meter['latitude']])),
                  name: 'Red Pin',
                  meterNumber: meter['meter_number'],
                  consumptionType: meter['consumption_type'],
                  longitude: meter['longitude'],
                  latitude: meter['latitude']
                }); 
                this.redPinLayer.getSource().addFeature(singleMarker);
                if(meterCoords.length - 1 == i) {
                  this.map.getView().setCenter(olProj.fromLonLat([meter['longitude'], meter['latitude']]));
                }
              });
              break;
            case 'yellow':
              this.yellowPinLayer.getSource().clear();
              meterCoords.forEach((meter, i) => {
                const singleMarker = new Feature({
                  geometry: new Point(olProj.fromLonLat([meter['longitude'], meter['latitude']])),
                  name: 'Yellow Pin',
                  meterNumber: meter['meter_number'],
                  consumptionType: meter['consumption_type'],
                  longitude: meter['longitude'],
                  latitude: meter['latitude']
                }); 
                this.yellowPinLayer.getSource().addFeature(singleMarker);
                if(meterCoords.length - 1 == i) {
                  this.map.getView().setCenter(olProj.fromLonLat([meter['longitude'], meter['latitude']]));
                }
              });
              break;
            /* istanbul ignore next: for code review reco */
            default: null;
          }
          this.isLoading = false;
        },
        error: /* istanbul ignore next: difficult to replicate API errors on karma */(err) => {
          console.log(err);
          this.isLoading = false;
        }
      });

    }else{
      this.filterFieldsValidArray[index] = false; //show error message
    }
  }

  addFilter(){
    //push another form group from form array
    this.filtersFgArray.push(this.fb.group({
      serviceType: new FormControl({value: this.currentServiceType, disabled: true}),
      readingCenterCodes: new FormControl('', Validators.required),
      routes: new FormControl({value: [], disabled: false}, Validators.required),
      itineraryCode: new FormControl({value: '', disabled: false}, Validators.required),
      pinColor: new FormControl('')
    }));
    //push another route LOV
    this.routesFilterArray.push({
      routes: []
    });
    //push another itineraryCode LOV
    this.itineraryCodeFilterArray.push({
      itineraries: []
    });
    //push another filterFieldsValid 
    this.filterFieldsValidArray.push(true);
  }

  removeFilter(index){
    switch(this.filtersFgArray.controls[index].get('pinColor').value){
      case 'blue':
        this.isBluePinAvailable = true;
        this.bluePinLayer.getSource().clear();
        break;
      case 'red':
        this.isRedPinAvailable = true;
        this.redPinLayer.getSource().clear();
        break;
      case 'yellow':
        this.isYellowPinAvailable = true;
        this.yellowPinLayer.getSource().clear();
        break;
      /* istanbul ignore next */
      default: null
    }
    this.filtersFgArray.removeAt(index);
    this.routesFilterArray.splice(index, 1);
    this.itineraryCodeFilterArray.splice(index, 1);
    this.selectedItineraries.splice(index, 1);
  }

  resetForm(index){
    console.log(index);
    this.filtersFgArray.controls[index].patchValue({
      readingCenterCodes: [],
      routes: [],
      itineraryCode: ''
    });
    this.routesFilterArray[index].routes = [];
    this.itineraryCodeFilterArray[index].itineraries = [];
  }

  /* istanbul ignore next */
  isOptionDisabledRCC(index, opt): boolean {
    return this.filtersFgArray.controls[index].get('readingCenterCodes').value.length >= 5 && 
          !this.filtersFgArray.controls[index].get('readingCenterCodes').value.find(el => el == opt);
  }

  /* istanbul ignore next */
  isOptionDisabledRoute(index, opt): boolean {
    return this.filtersFgArray.controls[index].get('routes').value.length >= 5 && 
          !this.filtersFgArray.controls[index].get('routes').value.find(el => el == opt);
  }

}
