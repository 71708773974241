import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'; 
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { take } from 'rxjs/operators';
import { InformationComponent } from '@shared/modals/information/information.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Auth } from 'aws-amplify';
import { AuthService } from '@shared/services/guards/auth.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@environments/environment';
import * as _ from 'underscore';
import { UtilService } from '@shared/services/util.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  @ViewChild('confirmationMdl') confirmMdl;

  maxRow: number = 6; //number of rows to display before going to next page
  searchBar: string = '';

  meterFFCodeArray: Array<any> = [];
  unsortedMeterFFCodeArray: Array<any> = [];
  meterFFCodeDisplayArray: Array<any> = [];
  meterFFCodePage: number = 1;
  meterFFCodeCollectionSize: number = 0;
  meterFFCodeIsLoading: boolean = false;

  lotFFCodeArray: Array<any> = [];
  lotFFCodeDisplayArray: Array<any> = [];
  lotFFCodePage: number = 1;
  lotFFCodeCollectionSize: number = 0;
  lotFFCodeIsLoading: boolean = false;

  mpFFCodeArray: Array<any> = [];
  mpFFCodeDisplayArray: Array<any> = [];
  mpFFCodePage: number = 1;
  mpFFCodeCollectionSize: number = 0;
  mpFFCodeIsLoading: boolean = false;

  deviceCodeArray: Array<any> = [];
  deviceCodeDisplayArray: Array<any> = [];
  deviceCodePage: number = 1;
  deviceCodeCollectionSize: number = 0;
  deviceCodeIsLoading: boolean = false;

  consumptionTypeArray: Array<any> = [];
  consumptionTypeDisplayArray: Array<any> = [];
  consumptionTypePage: number = 1;
  consumptionTypeCollectionSize: number = 0;
  consumptionTypeIsLoading: boolean = false;

  readingCenterArray: Array<any> = [];
  readingCenterDisplayArray: Array<any> = [];
  readingCenterPage: number = 1;
  readingCenterCollectionSize: number = 0;
  readingCenterIsLoading: boolean = false;

  routeArray: Array<any> = [];
  routeDisplayArray: Array<any> = [];
  routePage: number = 1;
  routeCollectionSize: number = 0;
  routeIsLoading: boolean = false;
  routeArrayChanges: Array<any> = [];

  highlightedRowIndex: number = null;
  activeNav: string = 'MRFF';
  routeBarVisited: boolean = false;
  nextActiveNav: string = '';

  codesFormGroupDefaultValue = {
    oldCode: '',
    code: '',
    oldRemarks: '',
    remarks: '',
    codeType: '',
    imageFlag: 'Activated',
    description: '',
    gpNongp: 1,
    oldGpNongp: 1
  }

  isSortedAgain: string;
  selectedSort: string;

  saveIsLoading: boolean = false;

  codesFormGroup: UntypedFormGroup = new UntypedFormGroup({
    oldCode: new UntypedFormControl(''),
    code: new UntypedFormControl('', [Validators.required, Validators.minLength(2)]),
    oldRemarks: new UntypedFormControl(''),
    remarks: new UntypedFormControl('', [Validators.required, this.utilService.hasOnlyWhiteSpaces]),
    codeType: new UntypedFormControl(''),
    imageFlag: new UntypedFormControl(''),
    gpNongp: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
    oldGpNongp: new UntypedFormControl(''),
  })

  deviceCodeFormGroup: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    deviceCode: new UntypedFormControl('', [Validators.required, Validators.minLength(2), Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ]+$')]),
    serialNumber: new UntypedFormControl('', [Validators.required, this.utilService.hasOnlyWhiteSpaces]),
    status: new UntypedFormControl('', [Validators.required])
  })

  consumptionTypeFormGroup: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    Code_Desc: new UntypedFormControl('', [Validators.required, this.utilService.hasOnlyWhiteSpaces]),
    New_Code: new UntypedFormControl('', [Validators.required, Validators.minLength(2)]),
    Type_Cons: new UntypedFormControl('', [Validators.required, Validators.minLength(2)]),
  })

  readingCenterFormGroupDefaultValue = {
    id: '',
    code: '',
    description: '',
    gp_nongp: 1
  }
  readingCenterFormGroup: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    code: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
    description: new UntypedFormControl('', [Validators.required, Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$'), this.utilService.hasOnlyWhiteSpaces]),
    gp_nongp: new UntypedFormControl({value: '', disabled: true}, [Validators.required])
  })

  confirmModalHeader;
  confirmModalBody;
  confirmModalFormData;
  confirmModalYesButton;
  confirmModalNoButton;
  confirmModalTableData;

  constructor(private hhtApiService: HhtApiService,
              private modal: NgbModal,
              public authService: AuthService,
              private toastr: ToastrService,
              private utilService: UtilService) { }

  //@HostListener('window:beforeunload')            //Listen when closing tab/reloading browser side. Commented out for revisit later
  /* istanbul ignore next: to revisit */
  canDeactivate(): Promise<boolean> | boolean{
    if(this.routeArrayChanges.length > 0){
      this.confirmModalHeader = `Unsaved Changes`;
      this.confirmModalBody = `Are you sure you want to leave the current page? \n
                              You have unsaved changes to the following Route/s.`;
      this.confirmModalTableData = {
        header: [
          { class: 'text-left', style: 'width: 100px', text: 'Route' },
          { class: 'text-left', style: '', text: 'Allow image capture?' },
        ],
        body: this.routeArrayChanges.map((data) => {
          return [
            { class: 'text-left', text: data.route },
            { class: 'text-left text-capitalize', text: data.image_capturing.toLowerCase() }
          ];
        })
      };
      
      const config: NgbModalOptions = {
        windowClass: 'min-height-474',
        backdrop: 'static'
      };
      let ref = this.modal.open(this.confirmMdl, config);
      this.confirmModalYesButton = () => {
        ref.close(true);
      };
      this.confirmModalNoButton = () => {
        ref.close(false);
      };
      //ref.result.then(a=>console.log(a))
      return ref.result;
    }
    return this.routeArrayChanges.length == 0;
  }

  ngOnInit(): void {
    this.authService.currentUserInfo().subscribe(user =>{
      this.authService.assignCurrentUser(String(user['username']).replace(environment.adPrefix, ''));
      /* istanbul ignore if */
      if(['GP_DATA_STATION_OFFICER', 
        'NONGP_DATA_STATION_OFFICER',
        'MDOA_STAFF', 'METER_COORDINATOR'].includes(this.authService.getCurrentUser().accountType)){
        this.activeNav = 'LOT';
        this.getLotCodes();
      }else{
        this.getMeterCodes();
      }
    })
    
  }

  getMeterCodes(searchText?){
    //this.codesFormGroup.get('imageFlag').setValidators([Validators.required]);
    this.searchBar = !!searchText ? searchText : '';
    this.codesFormGroup.updateValueAndValidity();
    this.highlightedRowIndex = null;
    this.codesFormGroup.reset(this.codesFormGroupDefaultValue);
    this.meterFFCodeArray = [];
    this.meterFFCodeIsLoading = true;
    let params = {code: !!searchText? searchText : ''};
    this.selectedSort = '';
    this.isSortedAgain = '';
    this.hhtApiService.getMeterFFCodes(params)
                      .subscribe({
                        next: (res) => {
                          this.meterFFCodeArray = res['body']['results'];
                          this.unsortedMeterFFCodeArray = res['body']['results'];
                          this.meterFFCodeCollectionSize = this.meterFFCodeArray.length;
                          this.codePageChange('METER');
                          this.meterFFCodeIsLoading = false;
                        },
                        error: /* istanbul ignore next: difficult to replicate API errors on karma */(error) => {
                          console.error('Error fetching data', error);
                          this.meterFFCodeIsLoading = false;
                        }
                      })
  }
  
  getLotCodes(searchText?){
    this.searchBar = !!searchText ? searchText : '';
    this.codesFormGroup.get('imageFlag').removeValidators([Validators.required]);
    this.codesFormGroup.updateValueAndValidity();
    this.highlightedRowIndex = null;
    this.codesFormGroup.reset();
    this.lotFFCodeArray = [];
    this.lotFFCodeIsLoading = true;
    let params = {code: !!searchText? searchText : ''};
    this.selectedSort = '';
    this.isSortedAgain = '';
    this.hhtApiService.getLotFFCodes(params)
                      .subscribe({
                        next: (res) => {
                          this.lotFFCodeArray = res['body']['results'];
                          this.lotFFCodeCollectionSize = this.lotFFCodeArray.length;
                          this.codePageChange('LOT');
                          this.lotFFCodeIsLoading = false;
                        },
                        error: /* istanbul ignore next: difficult to replicate API errors on karma */(error) => {
                          console.error('Error fetching data', error);
                          this.lotFFCodeIsLoading = false;
                        }
                      })
  }

  getMPCodes(searchText?){
    this.searchBar = !!searchText ? searchText : '';
    this.codesFormGroup.get('imageFlag').removeValidators([Validators.required]);
    this.codesFormGroup.updateValueAndValidity();
    this.highlightedRowIndex = null;
    this.codesFormGroup.reset();
    this.mpFFCodeArray = [];
    this.mpFFCodeIsLoading = true;
    let params = {code: !!searchText? searchText : ''};
    this.selectedSort = '';
    this.isSortedAgain = '';
    this.hhtApiService.getMpFFCodes(params)
                      .subscribe({
                        next: (res) => {
                          this.mpFFCodeArray = res['body']['results'];
                          this.mpFFCodeCollectionSize = this.mpFFCodeArray.length;
                          this.codePageChange('MP');
                          this.mpFFCodeIsLoading = false;
                        },
                        error: /* istanbul ignore next: difficult to replicate API errors on karma */(error) => {
                          console.error('Error fetching data', error);
                          this.mpFFCodeIsLoading = false;
                        }
                      })
  }

  getDeviceCodes(searchText?){
    this.searchBar = !!searchText ? searchText : '';
    this.highlightedRowIndex = null;
    this.deviceCodeFormGroup.reset();
    this.deviceCodeArray = [];
    this.deviceCodeIsLoading = true;
    this.selectedSort = '';
    this.isSortedAgain = '';
    this.hhtApiService.getDeviceCodes('', !!searchText ? searchText : '') 
                      .subscribe({
                        next: (res) => {
                          this.deviceCodeArray = res['body']['results'];
                          this.deviceCodeCollectionSize = this.deviceCodeArray.length;
                          this.codePageChange('DEVICE');
                          this.deviceCodeIsLoading = false;
                        },
                        error: /* istanbul ignore next: difficult to replicate API errors on karma */(error) => {
                          console.error('Error fetching data', error);
                          this.deviceCodeIsLoading = false;
                        }
                      })
  }

  getConsumptionType(searchText?){
    this.searchBar = !!searchText ? searchText : '';
    this.highlightedRowIndex = null;
    this.consumptionTypeFormGroup.reset();
    this.consumptionTypeArray = [];
    this.consumptionTypeIsLoading = true;
    let params = {Type_Cons: !!searchText ? searchText : ''};
    this.selectedSort = '';
    this.isSortedAgain = '';
    this.hhtApiService.getConsumptionType(params)
                      .subscribe({
                        next: (res) => {
                          this.consumptionTypeArray = res['body']['results'];
                          this.consumptionTypeCollectionSize = this.consumptionTypeArray.length;
                          this.codePageChange('CONSUMPTION');
                          this.consumptionTypeIsLoading = false;
                        },
                        error: /* istanbul ignore next: difficult to replicate API errors on karma */(error) =>{
                          console.error('Error fetching data', error);
                          this.consumptionTypeIsLoading = false;
                        }
                      })
  }

  getReadingCenters(searchText?){
    this.searchBar = !!searchText ? searchText : '';
    this.highlightedRowIndex = null;
    this.readingCenterFormGroup.reset(this.readingCenterFormGroupDefaultValue);
    this.readingCenterArray = [];
    this.readingCenterIsLoading = true;
    this.selectedSort = '';
    this.isSortedAgain = '';
    this.hhtApiService.getReadingCenters(!!searchText ? searchText : '')
                      .subscribe({
                        next: (res) => {
                          this.readingCenterArray = res['body']['results'];
                          this.readingCenterCollectionSize = this.readingCenterArray.length;
                          this.codePageChange('READING CENTER');
                          this.readingCenterIsLoading = false;
                        },
                        error: /* istanbul ignore next: difficult to replicate API errors on karma */(error) => {
                          console.error('Error fetching data', error);
                          this.readingCenterIsLoading = false;
                        }
                      })
  }

  getRoutes(searchText?){
    /* istanbul ignore if */
    if(this.routeArrayChanges.length > 0){
      return;
    }
    this.searchBar = !!searchText ? searchText : '';
    this.highlightedRowIndex = null;
    this.routeArray = [];
    this.routeIsLoading = true;
    this.routeArrayChanges = [];
    this.selectedSort = '';
    this.isSortedAgain = '';
    this.hhtApiService.getRoutes(!!searchText ? searchText : '')
                      .subscribe({
                        next: (res) => {
                          this.routeArray = res['body']['results'];
                          this.routeCollectionSize = this.routeArray.length;
                          this.codePageChange('ROUTES');
                          this.routeIsLoading = false;
                        },
                        error: /* istanbul ignore next: difficult to replicate API errors on karma */(error) => {
                          console.error('Error fetching data', error);
                          this.routeIsLoading = false;
                        }
                      })
  }

  //Consider making the pageSize adjustable and stored in a const variable outside, but for now, it is set as 10.
  //Also, make the switch cases in a seperate const file and import it here, for better maintenance and elegance of the code
  //This pageChange is temporary and will be changed once we implement pagination in the GET API calls for the codes.
  /* istanbul ignore next: to revisit */
  codePageChange(tab){
    switch(tab){
      case 'METER':
        this.meterFFCodeDisplayArray = this.meterFFCodeArray
                .map((elements, index) => ({
                  test: index + 1, ...elements
                }))
                .slice((this.meterFFCodePage - 1) * this.maxRow, (this.meterFFCodePage -1) * this.maxRow + this.maxRow);
        break;
      case 'LOT':
        this.lotFFCodeDisplayArray = this.lotFFCodeArray
                .map((elements, index) => ({
                  test: index + 1, ...elements
                }))
                .slice((this.lotFFCodePage - 1) * this.maxRow, (this.lotFFCodePage -1) * this.maxRow + this.maxRow);
        break;
      case 'MP':
        this.mpFFCodeDisplayArray = this.mpFFCodeArray
                .map((elements, index) => ({
                  test: index + 1, ...elements
                }))
                .slice((this.mpFFCodePage - 1) * this.maxRow, (this.mpFFCodePage -1) * this.maxRow + this.maxRow);
        break;
      case 'DEVICE':
        this.deviceCodeDisplayArray = this.deviceCodeArray
                .map((elements, index) => ({
                  test: index + 1, ...elements
                }))
                .slice((this.deviceCodePage - 1) * this.maxRow, (this.deviceCodePage -1) * this.maxRow + this.maxRow);
        break;
      case 'CONSUMPTION':
        this.consumptionTypeDisplayArray = this.consumptionTypeArray
                .map((elements, index) => ({
                  test: index + 1, ...elements
                }))
                .slice((this.consumptionTypePage - 1) * this.maxRow, (this.consumptionTypePage -1) * this.maxRow + this.maxRow);
        break;
      case 'READING CENTER':
        this.readingCenterDisplayArray = this.readingCenterArray
                .map((elements, index) => ({
                  test: index + 1, ...elements
                }))
                .slice((this.readingCenterPage - 1) * this.maxRow, (this.readingCenterPage -1) * this.maxRow + this.maxRow);
        break;
      case 'ROUTES':
        this.routeDisplayArray = this.routeArray
                .map((elements, index) => ({
                  test: index + 1, ...elements
                }))
                .slice((this.routePage - 1) * this.maxRow, (this.routePage -1) * this.maxRow + this.maxRow);
        break;
    }
  }

  selectedRow(data, type, rowIndex) {
    /* istanbul ignore if */
    if(this.highlightedRowIndex == rowIndex){
      this.highlightedRowIndex = null;
      this.codesFormGroup.reset(this.codesFormGroupDefaultValue);
    }else{
      this.highlightedRowIndex = rowIndex;
      this.codesFormGroup.patchValue({
        oldCode: data.code,
        code: data.code,
        oldRemarks: data.remarks,
        remarks: data.remarks,
        codeType: type,
        imageFlag: data.image_flag,
        description: data.description,
        gpNongp: data.gp_nongp,
        oldGpNongp: data.gp_nongp
      });
      // if(type == 'meterFFCodes'){ //if from meterFFCodes, require imageFlag field
      //   this.codesFormGroup.get('imageFlag').setValidators([Validators.required]);
      // }else{ //else remove required field imageFlag
      //   this.codesFormGroup.get('imageFlag').removeValidators([Validators.required]);
      // }
      // this.codesFormGroup.updateValueAndValidity();
    }
  }

  /* istanbul ignore next: to revisit */
  selectedDeviceCodeRow(data, rowIndex){
    if(this.highlightedRowIndex == rowIndex){
      this.highlightedRowIndex = null;
      this.deviceCodeFormGroup.reset();
    }else{
      this.highlightedRowIndex = rowIndex;
      this.deviceCodeFormGroup.patchValue({
        id: data.id,
        deviceCode: data.hhtCode,
        serialNumber: data.hhtSerial,
        status: data.status
      });
    }
  }

  /* istanbul ignore next: to revisit */
  selectedConsumptionTypeRow(data, rowIndex){
    if(this.highlightedRowIndex == rowIndex){
      this.highlightedRowIndex = null;
      this.consumptionTypeFormGroup.reset();
    }else{
      this.highlightedRowIndex = rowIndex;
      this.consumptionTypeFormGroup.patchValue({
        id: data.id,
        Code_Desc: data.Code_Desc,
        New_Code: data.New_Code,
        Type_Cons: data.Type_Cons
      });
    }
  }

  /* istanbul ignore next: to revisit */
  selectedReadingCenterRow(data, rowIndex){
    if(this.highlightedRowIndex == rowIndex){
      this.highlightedRowIndex = null;
      this.readingCenterFormGroup.reset(this.readingCenterFormGroupDefaultValue);
    }else{
      this.highlightedRowIndex = rowIndex;
      this.readingCenterFormGroup.patchValue({
        id: data.id,
        code: data.code,
        description: data.description,
        gp_nongp: data.gp_nongp
      });
    }
  }

  /* istanbul ignore next: to revisit */
  crudCode(type, requestType){ //requestType is create/update/delete
    let requestPayload = {
      source: type,
      request_type: requestType,
      old_code: this.codesFormGroup.get('oldCode').value,
      new_code: this.codesFormGroup.get('code').value,
      old_remarks: this.codesFormGroup.get('oldRemarks').value,
      new_remarks: this.codesFormGroup.get('remarks').value,
      image_flag: !!this.codesFormGroup.get('imageFlag').value ? this.codesFormGroup.get('imageFlag').value : '',
      gp_nongp: !!this.codesFormGroup.get('gpNongp').value ? this.codesFormGroup.get('gpNongp').value : '',
      description: !!this.codesFormGroup.get('description').value ? this.codesFormGroup.get('description').value : '',
      old_gp_nongp: !!this.codesFormGroup.get('oldGpNongp').value ? this.codesFormGroup.get('oldGpNongp').value : '',
    }
    console.log(requestPayload);
    this.saveIsLoading = true;
    this.hhtApiService.ffCodeCrud(requestPayload)
                      .pipe(take(1))
                      .subscribe(res => {
                        this.saveIsLoading = false;
                        this.highlightedRowIndex = null;
                        this.codesFormGroup.reset(this.codesFormGroupDefaultValue);
                        this.modal.dismissAll();
                        let toastHeader = 'Information';
                        let toastMessage = res['body']['results'];
                        switch(type){
                          case 'meterFFCodes':
                            if (toastMessage === 'exist') {
                              toastHeader = 'Unsaved Changes';
                              toastMessage = `MRFF code is already in use. Please enter a different code`;
                            } else {
                              switch(requestType){
                                case 'create':
                                  toastHeader = 'Create MRFF';
                                  toastMessage = `MRFF Code has been added successfully. Image capture is currently ${requestPayload.image_flag.toLowerCase()}`;
                                  break;
                                case 'update':
                                  toastHeader = 'Update MRFF';
                                  toastMessage = 'Changes to MRFF Code has been successfully saved';
                                  break;
                                case 'delete':
                                  toastHeader = 'Deleted MRFF';
                                  toastMessage = 'MRFF Code has been successfully deleted';
                                  break;
                              }
                            }
                            break;
                          case 'lotFFCodes':
                            if (toastMessage === 'exist') {
                              toastHeader = 'Unsaved Changes';
                              toastMessage = `Lot code is already in use. Please enter a different code`;
                            } else {
                              switch(requestType){
                                case 'create':
                                  toastHeader = 'Lot Code Created';
                                  toastMessage = 'Lot Code successfully created';
                                  break;
                                case 'update':
                                  toastHeader = 'Lot Code Updated';
                                  toastMessage = 'Lot Code successfully updated';
                                  break;
                                case 'delete':
                                  toastHeader = 'Lot Code Deleted';
                                  toastMessage = 'Lot Code succesfully deleted';
                                  break;
                              }
                            }
                            break;
                          case 'mpFFCodes':
                            if (toastMessage === 'exist') {
                              toastHeader = 'Unsaved Changes';
                              toastMessage = `MP code is already in use. Please enter a different code`;
                            } else {
                              switch(requestType){
                                case 'create':
                                  toastHeader = 'MP Code Created';
                                  toastMessage = 'MP Code successfully created';
                                  break;
                                case 'update':
                                  toastHeader = 'MP Code Updated';
                                  toastMessage = 'MP Code successfully updated';
                                  break;
                                case 'delete':
                                  toastHeader = 'MP Code Deleted';
                                  toastMessage = 'MP Code succesfully deleted';
                                  break;
                              }
                            }
                            break;
                        }
                        this.toastr.success(toastMessage, toastHeader, {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 })
                      },
                      error=>{
                        this.saveIsLoading = false;
                        console.error('Error', error);
                        this.modal.dismissAll();
                        this.toastr.success(error['message'], 'Error', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 })
                      },
                      () =>{
                        switch(type){
                          case 'meterFFCodes':
                            this.getMeterCodes();
                            break;
                          case 'lotFFCodes':
                            this.getLotCodes();
                            break;
                          case 'mpFFCodes':
                            this.getMPCodes();
                            break;
                        }
                      })
  }

  /* istanbul ignore next: to revisit */
  deviceCodeCrud(action){
    let requestPayload = {
      action: action,
      id: this.deviceCodeFormGroup.get('id').value,
      hhtCode: this.deviceCodeFormGroup.get('deviceCode').value,
      hhtSerial: this.deviceCodeFormGroup.get('serialNumber').value,
      status: this.deviceCodeFormGroup.get('status').value
    }
    this.saveIsLoading = true;
    this.hhtApiService.crudDeviceCodes(requestPayload)
                      .pipe(take(1))
                      .subscribe(res => {
                        this.saveIsLoading = false;
                        this.highlightedRowIndex = null;
                        this.deviceCodeFormGroup.reset();
                        this.getDeviceCodes();
                        this.modal.dismissAll();
                        let toastHeader = 'Information';
                        let toastMessage = res['body']['results'];
                        if (toastMessage === 'exist') {
                          toastHeader = 'Unsaved Changes';
                          toastMessage = `Device code is already in use. Please enter a different code`;
                        } else {
                          switch(action){
                            case 'create':
                              toastHeader = 'Device Code Created';
                              toastMessage = 'Device Code was successfully created';
                              break;
                            case 'update':
                              toastHeader = 'Device Code Updated';
                              toastMessage = 'Device Code was successfully updated';
                              break;
                            case 'delete':
                              toastHeader = 'Device Code Deleted';
                              toastMessage = 'Device Code was successfully deleted';
                              break;
                          }
                        }
                        this.toastr.success(toastMessage, toastHeader, {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
                      },
                      error=>{
                        this.saveIsLoading = false;
                        console.error('Error', error);
                        this.modal.dismissAll();
                        this.toastr.success(error['message'], 'Error', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 })
                      })
  }

  /* istanbul ignore next: to revisit */
  consumptionTypeCrud(action) {
    let requestPayload = {
      action: action,
      id: !!this.consumptionTypeFormGroup.get('id').value ? this.consumptionTypeFormGroup.get('id').value : '',
      Code_Desc: this.consumptionTypeFormGroup.get('Code_Desc').value,
      New_Code: this.consumptionTypeFormGroup.get('New_Code').value,
      Type_Cons: this.consumptionTypeFormGroup.get('Type_Cons').value,
    }
    this.saveIsLoading = true;
    this.hhtApiService.crudConsumptionType(requestPayload)
                      .pipe(take(1))
                      .subscribe(res => {
                        this.saveIsLoading = false;
                        this.highlightedRowIndex = null;
                        this.consumptionTypeFormGroup.reset();
                        this.getConsumptionType();
                        this.modal.dismissAll();
                        let toastHeader = 'Information';
                        let toastMessage = res['body']['results'];
                        if (toastMessage == 'not exist') {
                          switch(action){
                            case 'create':
                              toastHeader = 'Successfully Created';
                              toastMessage = 'Consumption type was successfully created';
                              break;
                            case 'update':
                              toastHeader = 'Successfully Updated';
                              toastMessage = 'Consumption type was successfully updated';
                              break;
                            case 'delete':
                              toastHeader = 'Successfully Deleted';
                              toastMessage = 'Consumption type was successfully deleted';
                              break;
                          }
                        }
                        this.toastr.success(toastMessage, toastHeader, {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
                      },
                      error=>{
                        this.saveIsLoading = false;
                        console.error('Error', error);
                        this.modal.dismissAll();
                        this.toastr.success(error['message'], 'Error', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 })
                      })
  }

  /* istanbul ignore next: to revisit */
  readingCenterCrud(action){
    let requestPayload = {
      action: action,
      id: this.readingCenterFormGroup.get('id').value,
      code: this.readingCenterFormGroup.get('code').value,
      description: this.readingCenterFormGroup.get('description').value,
      gp_nongp: this.readingCenterFormGroup.get('gp_nongp').value
    }
    this.saveIsLoading = true;
    this.hhtApiService.crudReadingCenters(requestPayload)
                      .pipe(take(1))
                      .subscribe(res => {
                        this.saveIsLoading = false;
                        this.highlightedRowIndex = null;
                        this.readingCenterFormGroup.reset(this.readingCenterFormGroupDefaultValue);
                        this.getReadingCenters();
                        this.modal.dismissAll();
                        let toastHeader = 'Information';
                        let toastMessage = res['body']['results'];
                        if (toastMessage === 'exist') {
                          toastHeader = 'Unsaved Changes';
                          toastMessage = `Reading center is already in use. Please enter a different code`;
                        } else {
                          switch(action){
                            case 'create':
                              toastHeader = 'Successfully Created';
                              toastMessage = 'Reading Center was successfully created';
                              break;
                            case 'update':
                              toastHeader = 'Successfully Updated';
                              toastMessage = 'Reading Center was successfully updated';
                              break;
                            case 'delete':
                              toastHeader = 'Successfully Deleted';
                              toastMessage = 'Reading Center was successfully deleted';
                              break;
                          }
                        }
                        this.toastr.success(toastMessage, toastHeader, {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
                      },
                      error=>{
                        this.saveIsLoading = false;
                        console.error('Error', error);
                        this.modal.dismissAll();
                        this.toastr.success(error['message'], 'Error', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 })
                      })
  }


  confirmModal(modalContent, tab, action){
    this.confirmModalHeader = '';
    this.confirmModalBody = '';
    this.confirmModalFormData = null;
    this.confirmModalTableData = null;
    this.confirmModalYesButton = /* istanbul ignore next */() => {};
    switch(tab){
      case 'METER':
        this.confirmModalHeader = `<span class="text-capitalize font-family-inherit">${String(action)}</span> MRFF`;
        this.confirmModalFormData = [
          { 'key': 'Code', 'value': `<div class="font-weight-bold">${this.codesFormGroup.get('code').value}</div>` },
          { 'key': 'Name', 'value': this.codesFormGroup.get('remarks').value },
          { 'key': 'Description', 'value': this.codesFormGroup.get('description').value },
          { 'key': 'GP/Non-GP', 'value': this.codesFormGroup.get('gpNongp').value == 1 ? "GP" : "Non-GP" },
          { 'key': 'Image Capture', 'value': this.codesFormGroup.get('imageFlag').value },
        ];
        switch(action){
          case 'create':
            this.confirmModalBody = 'Are you sure you want to create this MRFF code?';
            break;
          case 'update':
            this.confirmModalBody = 'Are you sure you want to save changes to this MRFF code?';
            break;
          case 'delete':
            this.confirmModalBody = 'Are you sure you want to delete this MRFF Code?';
            this.confirmModalFormData.splice(3, 2);
            break;
        }
        this.confirmModalYesButton = () => {
          this.crudCode('meterFFCodes', action);
        };
        break;
        /* istanbul ignore next */
      case 'LOT':
        this.confirmModalHeader = `<span class="text-capitalize font-family-inherit">${String(action)}</span> Lot Code`;
        this.confirmModalBody = `Are you sure you want to ${String(action).toLowerCase()} this Lot Code?`;
        this.confirmModalFormData = [
          { 'key': 'Code', 'value': `<div class="font-weight-bold">${this.codesFormGroup.get('code').value}</div>` },
          { 'key': 'Description', 'value': this.codesFormGroup.get('remarks').value }
        ];
        this.confirmModalYesButton = () => {
          this.crudCode('lotFFCodes', action);
        };
      break;
      /* istanbul ignore next */
      case 'MP':
        this.confirmModalHeader = `<span class="text-capitalize font-family-inherit">${String(action)}</span> MP Code`;
        this.confirmModalBody = `Are you sure you want to ${String(action).toLowerCase()} this MP Code?`;
        this.confirmModalFormData = [
          { 'key': 'Code', 'value': `<div class="font-weight-bold">${this.codesFormGroup.get('code').value}</div>` },
          { 'key': 'Description', 'value': this.codesFormGroup.get('remarks').value }
        ];
        this.confirmModalYesButton = () => {
          this.crudCode('mpFFCodes', action);
        };
      break;
      /* istanbul ignore next */
      case 'DEVCODE':
        this.confirmModalHeader = `<span class="text-capitalize font-family-inherit">${String(action)}</span> Device Code`;
        this.confirmModalBody = `Are you sure you want to ${String(action).toLowerCase()} this Device Code?`;
        this.confirmModalFormData = [
          { 'key': 'Device Code', 'value': `<div class="font-weight-bold">${this.deviceCodeFormGroup.get('deviceCode').value}</div>` },
          { 'key': 'Device Serial', 'value': this.deviceCodeFormGroup.get('serialNumber').value },
          { 'key': 'Status', 'value': this.deviceCodeFormGroup.get('status').value }
        ];
        this.confirmModalYesButton = () => {
          this.deviceCodeCrud(action);
        };
      break;
      /* istanbul ignore next */
      case 'CONSTYPE':
        this.confirmModalHeader = `<span class="text-capitalize font-family-inherit">${String(action)}</span> Consumption Type`;
        this.confirmModalBody = `Are you sure you want to ${String(action).toLowerCase()} this Consumption Type?`;
        this.confirmModalFormData = [
          { 'key': 'Code Description', 'value': `<div class="font-weight-bold">${this.consumptionTypeFormGroup.get('Code_Desc').value}</div>` },
          { 'key': 'New Code', 'value': this.consumptionTypeFormGroup.get('New_Code').value },
          { 'key': 'Type', 'value': this.consumptionTypeFormGroup.get('Type_Cons').value }
        ];
        this.confirmModalYesButton = () => {
          this.consumptionTypeCrud(action);
        };
      break;
      /* istanbul ignore next */
      case 'RC':
        this.confirmModalHeader = `<span class="text-capitalize font-family-inherit">${String(action)}</span> Reading Center`;
        this.confirmModalBody = `Are you sure you want to ${String(action).toLowerCase()} this Reading Center?`;
        this.confirmModalFormData = [
          { 'key': 'Reading Center Code', 'value': `<div class="font-weight-bold">${this.readingCenterFormGroup.get('code').value}</div>` },
          { 'key': 'Description', 'value': this.readingCenterFormGroup.get('description').value }
        ];
        this.confirmModalYesButton = () => {
          this.readingCenterCrud(action);
        };
      break;
      case 'ROUTES':
        this.confirmModalHeader = `Update Route`;
        this.confirmModalBody = `Are you sure you want to save changes to the following Route/s?`;
        this.confirmModalTableData = {
          header: [
            { class: 'text-left', style: 'width: 100px', text: 'Route' },
            { class: 'text-left', style: '', text: 'Allow image capture?' },
          ],
          body: this.routeArrayChanges.map((data) => {
            return [
              { class: 'text-left', text: data.route },
              { class: 'text-left text-capitalize', text: data.image_capturing.toLowerCase() }
            ];
          })
        };
        this.confirmModalYesButton = () => {
          this.saveChangesRoutes();
        };
      break;
      /* istanbul ignore next */
      case 'ROUTES-UNSAVED':
        this.confirmModalHeader = `Unsaved Changes`;
        this.confirmModalBody = `Are you sure you want to leave the current page? \n
                                 You have unsaved changes to the following Route/s.`;
        this.confirmModalTableData = {
          header: [
            { class: 'text-left', style: 'width: 100px', text: 'Route' },
            { class: 'text-left', style: '', text: 'Allow image capture?' },
          ],
          body: this.routeArrayChanges.map((data) => {
            return [
              { class: 'text-left', text: data.route },
              { class: 'text-left text-capitalize', text: data.image_capturing.toLowerCase() }
            ];
          })
        };
        this.confirmModalYesButton = () => {
          this.modal.dismissAll();
          this.activeNav = this.nextActiveNav;
          this.routeArrayChanges = [];
        };
      break;
    }
    this.confirmModalNoButton = /* istanbul ignore next */() => {
      this.clickNoConfirmModal(tab);
    };
    this.modal.dismissAll();
    const config: NgbModalOptions = {
      windowClass: 'min-height-474',
      backdrop: 'static'
    };
    this.modal.open(modalContent, config);
  }

   /* istanbul ignore next: to revisit */
  clickNoConfirmModal(tab) {
    this.modal.dismissAll();
    switch(tab){
      case 'METER':
        this.toastr.success(
          'No changes were saved to MRFF Code.',
          'Unsaved Changes',
          {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 }
        );
        break;
        case 'LOT':
          this.toastr.success(
            'No changes were saved to Lot Code.',
            'Unsaved Changes',
            {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 }
          );
        break;
        /* istanbul ignore next */
        case 'MP':
          this.toastr.success(
            'No changes were saved to MP Code.',
            'Unsaved Changes',
            {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 }
          );
        break;
        /* istanbul ignore next */
        case 'DEVCODE':
          this.toastr.success(
            'No changes were saved to Device Code.',
            'Unsaved Changes',
            {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 }
          );
        break;
        /* istanbul ignore next */
        case 'CONSTYPE':
          this.toastr.success(
            'No changes were saved to Consumption Type.',
            'Unsaved Changes',
            {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 }
          );
        break;
        /* istanbul ignore next */
        case 'RC':
          this.toastr.success(
            'No changes were saved to Reading Center.',
            'Unsaved Changes',
            {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 }
          );
        break;
        case 'ROUTES':
          this.toastr.success(
            'No changes were saved to Routes.',
            'Unsaved Changes',
            {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 }
          );
        break;
        /* istanbul ignore next */
        case 'ROUTES-UNSAVED':
          this.toastr.success(
            'No changes were saved to Routes.',
            'Unsaved Changes',
            {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 }
          );
        break;
    }
  }

  // searchFilterFn(array: Array<any>, attr: string, searchText: string): Array<any> {
  //   return array.filter(data =>{
  //     return String(data[attr]).includes(searchText);
  //   })
  // }

  saveChangesRoutesImageCapture() {
    this.routeArrayChanges = this.routeDisplayArray
    .filter((data) => data.isChanged)
    .map((data) => ({
      action: "update",
      route: data.route,
      image_capturing: data.image_capturing
    }));
  }

  saveChangesRoutes() {
    const requestPayload = {
      data: this.routeArrayChanges
    };
    this.saveIsLoading = true;
    this.hhtApiService.crudRoutes(requestPayload)
                      .subscribe({
                        next: () => {
                          this.saveIsLoading = false;
                          this.highlightedRowIndex = null;
                          this.routeArrayChanges = [];
                          this.getRoutes();
                          this.modal.dismissAll();
                          this.toastr.success('Route Image Capture settings have been successfully saved', 'Routes Updated', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
                        },
                        error: /* istanbul ignore next: difficult to replicate API errors on karma */(error) => {
                          this.saveIsLoading = false;
                          console.error('Error', error);
                          this.modal.dismissAll();
                          this.toastr.error(error['message'], 'Error', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 })
                        }
                      })
  }

  isSortedBy(col: string) {
    /* istanbul ignore if */
    if (this.isSortedAgain === col && /* istanbul ignore next */this.selectedSort) {
      return this.selectedSort;
    } else {
      return null;
    }
  }

  /* istanbul ignore next: to revisit */
  onSort(tab: string, col: string) {
    const defPage = 1;
    switch(this.selectedSort){
      case 'asc':
        this.selectedSort = 'desc';
      break;
      case 'desc':
        this.selectedSort = '';
      break;
      case '':
        this.selectedSort = 'asc';
      break;
    }
    if (this.isSortedAgain !== col) {
      this.selectedSort = 'asc';
    }
    this.isSortedAgain = col;
    switch(tab){
      case 'METER':
        if (this.selectedSort) {
          this.meterFFCodeArray = _.sortBy(this.meterFFCodeArray, function(data) {
            const selectedCol = data[col];
            if (col === 'gp_nongp') {
              return selectedCol === 1 ? "GP" : "NON-GP";
            }
            if (col === 'code') {
              return Number(selectedCol);
            }
            return selectedCol.toLowerCase();
          });
          if (this.selectedSort === 'desc') {
            this.meterFFCodeArray = this.meterFFCodeArray.reverse();
          }
        } else {
          this.meterFFCodeArray = this.unsortedMeterFFCodeArray;
        }
        this.meterFFCodePage = defPage;
      break;
    }
    this.codePageChange(tab);
  }

  checkForSpecialCharacters(formGroup: UntypedFormGroup, formControlName: string): boolean {
    return this.utilService.hasSpecialCharacters(formGroup.get(formControlName).value);
  }

  checkIfEmpty(formGroup: UntypedFormGroup, formControlName: string): boolean {
    /* istanbul ignore if */
    if('deviceCode' == formControlName && formGroup.get(formControlName).value?.length == 2 && /* istanbul ignore next */formGroup.get(formControlName).value?.trim().length < 2){ //special check for device code
      return true;
    }
    return formGroup.get(formControlName).dirty && /* istanbul ignore next */formGroup.get(formControlName).value?.trim().length === 0;
  }

  /* istanbul ignore next: to revisit */
  autoAssignGPNonGP(){
    if(!!this.readingCenterFormGroup.get('code').value){
      if(String(this.readingCenterFormGroup.get('code').value).slice(2) == '00'){
        this.readingCenterFormGroup.get('gp_nongp').setValue(1);
      }else{
        this.readingCenterFormGroup.get('gp_nongp').setValue(0);
      }
    }else{
      this.readingCenterFormGroup.get('gp_nongp').setValue(1); //default to GP
    }
  }
  
  /* istanbul ignore next: to revisit */
  routeMaintenanceDeactivateGuard(changeEvent: NgbNavChangeEvent, modalContent){
    this.nextActiveNav = changeEvent.nextId;
    this.routeBarVisited = this.activeNav == 'ROUTE';
    if(changeEvent.nextId != 'ROUTE' && this.routeBarVisited && this.routeArrayChanges.length > 0){
      changeEvent.preventDefault();
      this.confirmModal(modalContent, 'ROUTES-UNSAVED', '');
    }
  }

  
}
