// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table tr.row-highlight td {
  background-color: #EEEFFA;
}

.code-form-wrapper {
  padding: 20px 0;
  background: #fff;
  border-radius: 10px;
  margin-top: 5%;
}
.code-form-wrapper .code-form-container {
  max-width: 85%;
  margin: auto;
}
.code-form-wrapper .code-form-container .code-form-header {
  font-family: "Trade Gothic Bold", serif;
  color: #171C8F;
}
.code-form-wrapper .code-form-container .code-form-action .button {
  background-color: #ddd;
  border: none;
  color: black;
  padding: 10px 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  margin-top: 3%;
  cursor: pointer;
  border-radius: 16px;
}
.code-form-wrapper .code-form-container .send-msts-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3B3B3B;
}
.code-form-wrapper .code-form-container .meterLbl-status {
  color: #919191;
  padding-left: 20px;
  margin-bottom: 0;
}

.generate-btn {
  width: 53%;
}

.searchBar .searchItinerary {
  width: 257px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/core/hht/reports/reports.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;;AACA;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;AAEJ;AADI;EACI,cAAA;EACA,YAAA;AAGR;AAFQ;EACI,uCAAA;EACA,cAAA;AAIZ;AADY;EACI,sBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,qBAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;EACA,mBAAA;AAGhB;AAAQ;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAEZ;AAAQ;EACI,cAAA;EACA,kBAAA;EACA,gBAAA;AAEZ;;AAIA;EACI,UAAA;AADJ;;AAIA;EACI,uBAAA;AADJ","sourcesContent":["table tr.row-highlight td {\r\n    background-color: #EEEFFA;\r\n}\r\n.code-form-wrapper{\r\n    padding: 20px 0;\r\n    background: #fff;\r\n    border-radius: 10px;\r\n    margin-top: 5%;\r\n    .code-form-container{\r\n        max-width: 85%;\r\n        margin: auto;\r\n        .code-form-header{\r\n            font-family: 'Trade Gothic Bold', serif;\r\n            color: #171C8F;\r\n        }\r\n        .code-form-action{\r\n            .button {\r\n                background-color: #ddd;\r\n                border: none;\r\n                color: black;\r\n                padding: 10px 50px;\r\n                text-align: center;\r\n                text-decoration: none;\r\n                display: inline-block;\r\n                margin: 4px 2px;\r\n                margin-top: 3%;\r\n                cursor: pointer;\r\n                border-radius: 16px;\r\n              }\r\n        }\r\n        .send-msts-desc {\r\n            font-weight: 400;\r\n            font-size: 16px;\r\n            line-height: 20px;\r\n            color: #3B3B3B;\r\n        }        \r\n        .meterLbl-status{\r\n            color: #919191;\r\n            padding-left: 20px;\r\n            margin-bottom: 0;\r\n        }\r\n    }\r\n    \r\n}\r\n\r\n.generate-btn {\r\n    width: 53%;\r\n}\r\n\r\n.searchBar .searchItinerary { //bypass\r\n    width: 257px !important\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
