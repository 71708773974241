import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Hub, Auth } from 'aws-amplify';
import { environment } from '@environments/environment';
import { AuthService } from '@shared/services/guards/auth.service';
import { RouteService } from '@shared/services/guards/route.service';
import { HhtApiService } from '@app/shared/services/hht-api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loading = false;
  prevURL = localStorage.getItem('prev_url')
  constructor(
    private router: Router,
    private routeService: RouteService,
    private authService: AuthService,
    private ngZone: NgZone,
    private hhtApiService: HhtApiService
  ){}

  ngOnInit() {
    console.log('Onload '+this.routeService.getPreviousUrl(), 'this.routeService.getPreviousUrl()')
    if(this.routeService.getPreviousUrl() == '/login'){
      
      this.routing()
    }else{
      this.loading = true;
      /* istanbul ignore next: I can't spy on Hub.listen for unit tests. */
      Hub.listen('auth', (data) => {
        console.log(data)
        switch (data.payload.event) {          
            case 'signIn':

              this.authService.isVerified().subscribe(data => {
                Auth.currentUserInfo().then(data => {
                  console.log('username:', data["username"])
                  let body = {
                    "username": data['username']
                  }
                  this.hhtApiService.update_websession_token(body).subscribe((res: any[])=>{    
                    console.log("res",res)
                    sessionStorage.setItem('token', res["body"]["token"])
                    console.log("sessionStorage",sessionStorage)
                  })
                })
              })




                console.log('now the user is signed in');
                this.routing()
                break;
            case 'signIn_failure':
                console.log('now the user is fail in');
                this.routing()
                break;
            default:
                break;
        }
      });
    }
  }

  // data = user PROFILE
  routing() {
      this.authService.isVerified().subscribe({
        next: (data) => {
          this.ngZone.run(() =>{
            this.authService.setUserIsFromAd(!!String(data['username']).includes(environment.adPrefix)); //sets the global service variable isAD to check if user logged is from AD.
            this.authService.assignCurrentUser(String(data['username']).replace(environment.adPrefix, '')).then(data =>{
              if(!!data){ // if assignCurrentUser return is true, then user is existing and allow to navigate
                this.ngZone.run(() => this.router.navigate([environment.homepage]))
              }
            });
          })
        },
        error:/* istanbul ignore next: difficult to replicate API errors on karma */ () => {
          this.update(false, '')
        }
      }
    );
  }

  /* istanbul ignore next: I can't spy on Hub.listen for unit tests. */
  update(isAuthenticated, emailAddress){

    Auth.currentSession().then( data => {
        this.authService.setUserIsFromAd(!!String(data['username']).includes(environment.adPrefix)); //sets the global service variable isAD to check if user logged is from AD.
       let userEmail = String(data.getIdToken().payload.email).replace(environment.adPrefix, '');
      // localStorage.setItem('userType',userType)
      
      localStorage.setItem('userEmail',emailAddress)

      if (isAuthenticated){
        if (this.prevURL != undefined) {
          localStorage.removeItem('prev_url')
          this.ngZone.run(() => this.router.navigateByUrl(this.prevURL))
        } else {
          localStorage.removeItem('prev_url')
          this.ngZone.run(() => this.router.navigate([environment.homepage]))
        }
        this.authService.assignCurrentUser(userEmail);
      }
      else {
        // CLEAR STORAGE AND REDIRECT
        Auth.signOut()
        localStorage.clear();
        window.location.replace(environment.signOut);
      }


    }) //then
  }

  /* istanbul ignore next: Unused. */
  validateUserInPool(data){
    let emailAddress = data.attributes.email

    this.update(true, emailAddress)
    // this.adminService.getUser(loginEmail).subscribe(profile =>{
    //   let loginUserType = profile['body']['userType']
    //   this.update(true, loginUserType)
    // },
    // error => {
    //     this.update(false, '')
    //   }
    // )
  }
}
