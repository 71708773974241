
import { Routes } from '@angular/router';
import { HhtMainComponent } from './hht-main/hht-main.component';
import { MasterMeterDataComponent } from './master-meter-data/master-meter-data.component';
import { ItineraryComponent } from './itinerary/itinerary.component';
import { ItineraryListComponent } from './itinerary-list/itinerary-list.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { OnlineMeterReadingComponent } from './online-meter-reading/online-meter-reading.component';
import { MaintenanceComponent } from './online-meter-reading/maintenance/maintenance.component';
import { MeterLocationHistoryComponent } from './meter-location-history/meter-location-history.component';
import { ImageCaptureHistoryComponent } from './image-capture-history/image-capture-history.component';
import { InquiryMapPortalComponent } from './inquiry-map-portal/inquiry-map-portal.component';
import { SplitItineraryComponent } from './split-itinerary/split-itinerary.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportsGuard } from '@shared/services/guards/reports.guard';
import { MasterMeterGuard } from '@shared/services/guards/master-meter.guard';
import { ItinerariesGuard } from '@app/shared/services/guards/itineraries.guard';
import { MaintenanceGuard } from '@app/shared/services/guards/maintenance.guard';
import { UserMaintenanceComponent } from './user-maintenance/user-maintenance.component';
import { UserManagementGuard } from '@app/shared/services/guards/user-management.guard';
import { MaintenanceCandeactivateGuard } from '@app/shared/services/guards/maintenance-candeactivate.guard';
import { InquiryMapGuard } from '@app/shared/services/guards/inquiry-map.guard';


export const hhtRoutes: Routes = [

  {
    path: 'hhtmain',
    component: HhtMainComponent
  },
  {
    path: 'itinerary-list',
    component: ItineraryListComponent,
    canActivate: [ItinerariesGuard]
  },
  {
    path: 'itinerary-list/selected-itinerary',
    component: ItineraryComponent,
    canActivate: [ItinerariesGuard]
  },
  //NOTE: This task is on hold temporarily because of Medium Priority
  {
    path: 'itinerary-list/split-itinerary',
    component: SplitItineraryComponent
  },
  {
    path: 'meter-list',
    component: MasterMeterDataComponent,
    canActivate: [MasterMeterGuard]
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent
  },
  // {
  //   path: 'online-meter-reading',
  //   component: OnlineMeterReadingComponent
  // },
  {
    path: 'online-meter-reading/maintenance',
    component: MaintenanceComponent,
    canActivate: [MaintenanceGuard],
    canDeactivate: [MaintenanceCandeactivateGuard]
  },
  {
    path: 'meter-list/meter-location-history',
    component: MeterLocationHistoryComponent,
    canActivate: [MasterMeterGuard]
  },
  {
    path: 'meter-list/image-capture-history',
    component: ImageCaptureHistoryComponent,
    canActivate: [MasterMeterGuard]
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [ReportsGuard]
  },
  {
    path: 'user-management',
    component: UserMaintenanceComponent,
    canActivate: [UserManagementGuard]
  },
  {
    path: 'inquiry-map-portal',
    component: InquiryMapPortalComponent,
    canActivate: [InquiryMapGuard]
  },

]
